.data-browser-drag-wrapper {
  position: fixed;
  z-index: 10000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.data-browser-drag-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.data-browser-drag-position {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #a82058;
  margin-left: -1px;
}
