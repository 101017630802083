.admin-dashboard-page {
  padding: 20px;

  .stats-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;

    div {
      flex: 1;
      padding: 15px;
      text-align: center;
      background-color: #f9fafb;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      p {
        margin: 0;
        font-size: 16px;

        &:first-child {
          color: #6b7280;
          margin-bottom: 5px;
        }

        &:last-child {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

  .charts-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 10px;

    .chart-container {
      flex: 1;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      h2 {
        font-size: 18px;
        margin-bottom: 5px;
      }

      p {
        font-size: 14px;
        color: #6b7280;
        margin-bottom: 20px;
      }
    }
  }
}
.admin-dashboard-page {
  padding: 20px;

  .stats-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;

    div {
      flex: 1;
      padding: 15px;
      text-align: center;
      background-color: #f9fafb;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      p {
        margin: 0;
        font-size: 16px;

        &:first-child {
          color: #6b7280;
          margin-bottom: 5px;
        }

        &:last-child {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

  .charts-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .chart-container {
      flex: 1;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      h2 {
        font-size: 18px;
        margin-bottom: 5px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        color: #6b7280;
        margin-bottom: 20px;
      }
    }
  }
}
