.progress-bar {
  height: 4px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.progress-bar-indicator {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 0;
  max-width: 100%;
  background: #00a898;
  transition: width 1s;
}
