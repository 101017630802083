@import 'styles/_colors.scss';

$transition: all 0.2s;

.app-data-panel {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .left-panel-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -16px 0 0;
    color: $data;

    .icon {
      background-color: $data;
    }

    .title {
      flex: 1;
    }
  }

  .left-panel-section-title {
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 12px;
  }

  > button.btn {
    background-color: transparent;
    border-radius: 0;
    color: $data;
    font-size: 14px;
    margin: 0 -32px;
    padding: 16px 32px;
    justify-content: flex-start;

    height: auto;
    width: calc(100% + 64px);

    &:hover {
      background-color: $lightData;
    }

    .icon {
      background-color: $data;
      margin-right: 8px;
    }
  }

  .data-panel-accordion {
    margin: 0 -32px;

    &.accordion-expanded {
      box-shadow: inset 0 1px 00 $grey, inset 0 -1px 0 0 $grey;

      > .accordion-title {
        color: $data;

        > .icon {
          background-color: $data;
        }

        > button.btn {
          border: 1px solid $data;
          color: $data;

          &:hover {
            background-color: $data;
            color: white;
          }
        }
      }
    }

    .accordion-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      transition: $transition;

      padding: 12px 16px;

      > * {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }

      &:hover {
        background-color: $lightGrey;
      }

      .icon {
        &.icon-expand,
        &.icon-expand-vertical {
          mask-size: contain;
          height: 8px;
          width: 8px;
        }
      }

      span {
        &.title {
          font-size: 14px;
          font-weight: 500;
          flex: 1 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      button.btn {
        padding: 8px 16px;
        height: auto;
        background-color: transparent;
        color: $body2;
        text-transform: capitalize;
        letter-spacing: 0;

        &:hover {
          background-color: $darkGrey;
        }
      }
    }

    .accordion-children {
      padding: 16px;

      > * {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .library-inspect-menu-control {
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  .data-add-collection,
  .data-edit-collection {
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    padding: 16px;

    .wrapped-input {
      margin: 0 0 16px;

      .wrapped-input-inner-wrapper {
        background-color: $lightGrey;
        border: none;
        padding: 0 16px;

        input {
          font-size: 14px;
        }
      }
    }
  }

  .data-add-collection,
  .data-edit-collection,
  .data-edit-collection-property {
    &.form-inspect-field-wrapper {
      padding: 0;

      .disabled-alert {
        color: $filterLabel;
        font-weight: 500;

        span {
          color: $orange;
          cursor: pointer;
          text-transform: uppercase;
          padding: 0 2px;

          &:hover {
            background: rgba(222, 88, 61, 0.15);
            border-radius: 2px;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;

      button {
        border-radius: 6px;
        margin-right: 8px;
        width: auto;
        height: 32px;
        font-size: 12px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .library-inspect-menu-control {
      .multi-select-menu-selection {
        box-shadow: none;
      }
    }
  }

  .data-add-collection {
    margin: 0 -32px;
  }

  .data-edit-collection {
    margin: -10px -16px 0;
    padding: 16px;
  }

  .magic-start-call-to-action {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px 22px;
    border: 1px solid $black;
    border-radius: 6px;
    justify-self: flex-end;
    align-self: flex-end;
    margin: 2rem -1rem 0;

    h3 {
      margin: 0;
      font-size: 17px;
      font-weight: 600;
      color: $labelDark;
    }

    p {
      margin-bottom: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      color: #8c8c8c;
    }

    > span {
      display: flex;
      align-self: flex-end;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .add-to-database__accordion {
    position: relative;

    .accordion-children {
      position: absolute;
      padding: 0;
      margin-top: 10px;
      width: 100%;
      z-index: 1;
    }

    &__children {
      display: flex;
      flex: 1;
      gap: 14px;
      background-color: $white;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      padding: 14px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);

      > div {
        cursor: pointer;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: #f8f8f8;
        padding: 36px 12px;
        gap: 16px;
        font-size: 14px;
        color: #757575;
        font-weight: 500;
        position: relative;

        > .beta-label {
          position: absolute;
          top: 6px;
          right: 6px;
          background-color: #e7e7e7;
          color: #757575;
          font-size: 10px;
          line-height: 11px;
          font-weight: 700;
          padding: 4px 7px;
          margin: 0;
        }

        &:hover {
          background-color: $lightRed;
          color: $red;

          .icon {
            background-color: $red;
          }

          &.magic-add {
            background: linear-gradient(180deg, #ef4c30 0%, #a82058 100%);
            color: $white;

            .icon {
              background-color: $white;
            }

            > .beta-label {
              background: #ebebeb4d;
              color: $white;
            }
          }
        }
      }
    }
  }
}

.collections-menu-option,
.versions-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .icon {
    margin-right: 8px;
  }
}

.multi-menu-icon-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  .icon {
    margin-right: 8px;
  }
}

.data-accordion-item {
  display: block;
  z-index: 99999999999;
  margin-bottom: 8px;
  border-radius: 6px;
  overflow: hidden;

  .accordion {
    background-color: $lightGrey;
    transition: $transition;

    .accordion-title {
      display: flex;
      flex-direction: row;
      padding: 10px 16px;
      font-size: 14px;

      * {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }

      .title {
        flex: 1 1;
      }

      .icon-button-wrapper {
        margin: -8px 0;
      }
    }

    .data-accordion-subtitle {
      font-size: 11px;
      opacity: 0.6;
    }

    .data-accordion-title-text {
      flex: 1;
    }

    .accordion-children {
      padding: 16px;

      form {
        > * {
          margin: 0 0 16px;

          &:last-child {
            margin: 0;
          }
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        flex-flow: row;

        button {
          border-radius: 6px;
          font-size: 12px;
          height: 32px;
          margin-bottom: 0;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &.accordion-expanded {
      background: transparent;

      .accordion-title {
        background-color: $data;
        color: white;

        .icon {
          background-color: white;
        }
      }
      .accordion-children {
        background-color: $lightData;
      }
    }
  }
}

body > .data-accordion-item {
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.2);
}

.add-collection-wrapper {
  margin: 0 -32px 40px;

  .btn-list-add {
    height: 50px;
    border-radius: 0;
    padding: 0 32px;
  }
}

.help-outline-icon {
  position: relative;
  color: grey;

  &:hover {
    color: black;
    cursor: pointer;
  }
}

.data-collections-section-title {
  margin: 20px 0 12px;
  color: #777;

  .help-outline-icon {
    position: relative;
    top: 7px;
    left: 160px;
  }
}

.empty-state.data-api-collection-empty-state {
  margin: 0 -16px 8px;

  h2 {
    background: url('./external-api-icon-banner.svg') no-repeat center top;
    padding-top: 38px;
    margin-top: 5px;
  }

  a.upsell-link {
    margin-left: 5px;
    color: $orange;
    font-weight: 600;
    text-decoration: none;
    box-shadow: 0 0;
  }
}

.empty-state.data-collection-empty-state {
  margin: 0 -16px 8px;

  h2 {
    margin: 0;
    color: $body2;
  }
}

.edit-collection-relationship-field {
  user-select: none;
  border-radius: 6px;
  padding: 8px 16px 16px;
  background: $white;
  box-shadow: none;
  opacity: 0.5;

  .relationship-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #8a8a8a;
  }

  .relationship-description {
    display: flex;
    flex-direction: column;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    * {
      margin-bottom: 4px;
    }
  }
}

.relationship-graphic-background {
  background: $white;
  border-radius: 6px;
  margin: 24px;
  padding: 8px;
}
