.new-table-form .multi-select-menu {
  min-width: 145px;
  margin-left: 10px;
  font-size: 14px;
}

.new-table-form .multi-select-menu-selection {
  height: 45px;
  line-height: 2.1;
  margin-top: -5px;
}

.new-table-form input {
  line-height: 3;
  font-size: 14px;
}

.new-table-form .column-name input {
  min-width: 200px;
}

.new-table-form .icon-trash-small {
  mask-position: center 3px;
  background-color: #999;
  cursor: pointer;
}

.new-table-form .add-column {
  margin-top: 15px;
}

.new-table-form .add-column .icon {
  background-color: #555;
}

.new-table-form .add-column span {
  margin-left: 5px;
  text-transform: uppercase;
  font-size: 14px;
  color: #555;
}

.new-table-form .add-column:hover span {
  color: #111;
}

.new-table-form .add-column:hover .icon {
  background-color: #111;
}

.new-table-form .wrapped-input {
  margin: 0 0 16px;
  width: 100%;
}

.new-table-form .form-row {
  margin-top: 5px;
}

.new-table-form label {
  color: #8a8a8a;
  display: block;
}

.new-table-form .wrapped-input label {
  margin-bottom: 10px;
}

.new-table-form .wrapped-input .wrapped-input-prefix {
  display: flex;
  margin-right: 16px;
  padding: 0;
}

.new-table-form .multi-menu-trigger button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 0 20px;
}

.new-table-form .multi-menu-trigger button:hover,
.new-table-form .multi-menu-trigger.expanded button {
  background-color: rgb(245, 245, 245);
}

.new-table-form .form-row .icon-button-wrapper {
  margin: 0 0 0 8px;
}

.add-property-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.add-property-list-item span {
  margin: 0 16px 0 0;
}

.new-table-form .modal-content > label {
  margin: 0 0 10px 16px;
}
