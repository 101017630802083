@import '../common.scss';
@import '../constants.scss';
@import './cardStyles';

.NewPlanCard {
  @include br-3;
  @include border;
  @include border-2;
  @include p-4;
  width: $cardWidth;

  // Necessary for Recommended banner and Star decorations to align correctly
  @include relative;

  &-stars {
    @include absolute;
    @include right-3;
    @include top-3;
    width: 30px;
  }

  &-featureBanner {
    @include absolute;
    @include top-0;
    @include center;
    transform: translateY(-50%);
    width: calc(100% - 32px);

    &-banner {
      @include inline-block;
      @include br-1;
      @include px-2;
      @include py-1;
      @include f-10;
      @include center;
      @include medium;
      @include uppercase;
      letter-spacing: 0.05em;
    }
  }

  &-info {
    @include flex;
    @include flex-column;
    @include align-center;
    @include mb-8;
    @include relative;
  }

  &-name {
    @include mt-4;
    @include f-24;
    @include semibold;
  }

  &-tagline {
    @include mt-2;
    @include mb-4;
    @include w-100pc;
    @include center;
    @include medium;
    @include color-gray-9e;
  }

  .Price {
    @include w-100pc;
    @include flex;

    &-amount {
      @include f-40;
      @include semibold;
      @include center;
      flex-basis: 0;
    }

    // Initialize the buffer on each side with the same value so that flex grow expands each side equally.
    // The value is unimportant as long as its consistent.
    $bufferBasis: 20px;

    &-currency {
      @include f-20;
      @include semibold;
      @include valign-top;
      @include pt-2;
      @include px-1;
      @include fg-1;
      flex-basis: $bufferBasis;
      @include right;
    }

    &-buffer {
      @include fg-1;
      flex-basis: $bufferBasis;
    }
  }

  &-interval {
    @include mt-1;
    @include mb-8;
    @include medium;
    @include color-gray-9e;
  }

  &-buttonTooltip {
    @include w-100pc;
  }

  &-perks {
    @include mb-8;

    &-divider {
      @include my-4;
      @include border;
      @include dashed;
      @include bc-gray-9e;
      @include bb-none;
    }
  }

  .PlanButton {
    @include w-100pc;
    @include px-2;
    @include pointer;

    &-current {
      @include cursor-default;
    }

    &:disabled {
      @include not-allowed;
    }
  }

  .Perk {
    @include no-list-style;
    @include flex;
    @include align-center;
    @include medium;
    @include h-6;
    margin-left: 6px;

    &-icon {
      @include icon-3;
    }

    // Perk--plus
    &--plus {
      .Perk {
        &-icon {
          height: 10px;
        }
      }
    }

    &-text {
      @include mx-1;
      @include f-12;
    }

    &-subscript {
      @include f-8;
    }
  }
}

// Apply color schemes for each card style
@each $cardStyle in $cardStyles {
  .NewPlanCard--#{$cardStyle} {
    background-color: backgroundColor($cardStyle);
    border-color: borderColor($cardStyle);

    .NewPlanCard {
      &-featureBanner {
        &-banner {
          background-color: buttonColor($cardStyle);
          color: buttonTextColor($cardStyle);
        }
      }

      &-name {
        color: titleColor($cardStyle);
      }
    }

    .PlanButton {
      background-color: buttonColor($cardStyle);
      border-color: buttonColor($cardStyle);
      color: buttonTextColor($cardStyle);

      &:not(.PlanButton-current):not(:disabled) {
        &:hover,
        &:focus-visible {
          background-color: buttonHoverColor($cardStyle);
          border-color: buttonHoverColor($cardStyle);
        }
      }

      &-current,
      &-current:hover,
      &-current:focus-visible {
        background-color: transparent;
        border-color: depressedButtonBorderColor($cardStyle);
        color: depressedButtonBorderColor($cardStyle);
      }
    }

    .Perk {
      &-icon {
        background-color: titleColor($cardStyle);
      }
    }
  }
}
