@import 'styles/_colors.scss';

@mixin color($value) {
  background-color: $value;
  border: 1px solid darken($value, 10);
  color: $body;
}

.adalo-alert {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  padding: 8px 16px;

  // colors
  &-orange {
    @include color($orange);
    color: white;
  }

  &-purple {
    @include color($purple);
    color: white;
  }

  &-dark-pink {
    @include color($darkPink);
    color: white;
  }

  &-green {
    @include color($green);
  }

  &-yellow {
    @include color($yellow);
  }

  &-grey {
    @include color($grey);
  }

  &-teal {
    @include color($teal);
  }
}
