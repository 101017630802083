@import 'styles/_colors.scss';

.stat-container {
  display: grid;

  .stat-title {
    color: #8a8a8a;
    margin-bottom: 6px;
  }

  .stat {
    display: flex;

    > .stat-extra {
      margin-top: 2px;
      margin-left: 10px;
      font-weight: 400;
      font-size: inherit;
      color: $darkPink;
    }
  }

  .legacy-warning,
  .limit-indicator {
    display: flex;
    align-items: center;
    color: $placeholder;

    span {
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
  }
}
