@import 'styles/_colors.scss';

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $body2;
  text-align: center;
  margin: 16px;

  &.greedy {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  &.bordered {
    border: 1px dashed #e0e0e0;
    margin: 0 0 8px;
    padding: 12px 15px 15px;
    border-radius: 8px;
    min-height: 60px;

    h2,
    h3 {
      color: #000;
      margin-bottom: 0.5em;
    }

    p {
      color: #777;
    }
  }
}

.empty-state h5 {
  margin-bottom: 0.7em;
}

.empty-state h1 {
  font-weight: 400;
}

.empty-state a:not(.btn) {
  color: inherit;
  box-shadow: 0 1px;
}

.empty-state a:not(.btn):hover {
  color: inherit;
  box-shadow: none;
}
