.password-field {
  &__label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label {
    color: #666;
  }

  &__input-container {
    display: flex;
    margin: 3px -14px;
    align-items: center;
    padding: 12px 16px;
    border-radius: 9px;
    border-color: #eaeaea;
    border-style: solid;
    border-width: 1px;

    &--error {
      border-color: #f44;
    }
  }

  &__input {
    font-size: 14px;
    border: none;
    outline: none;
    flex-grow: 1;
    margin-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:disabled {
      opacity: 0.3;
    }
  }
}
