.filter-options {
  background: #fff;
  padding: 4px 10px 10px;
  border-radius: 6px;
  margin-bottom: 8px;
  border: 1px solid #e0e0e0;
}

.filter-options-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 6px;
}

.filter-options.conditional .filter-options-header {
  margin-bottom: 6px;
}
