@import 'styles/_colors.scss';

.StripeElement {
  padding: 0 !important;
  background-color: transparent !important;
}

.payment-form {
  width: auto;
  padding: 0 16px;

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: #424242;
    margin-bottom: 12px;
  }

  &-methods {
    display: grid;
    gap: 16px;
    margin-bottom: 16px;
  }

  &-card {
    position: relative;
    display: grid;
    grid-template-areas: 'icon card check';
    grid-template-columns: auto 1fr auto;
    column-gap: 16px;
    align-items: center;
    border: 1px solid $darkGrey;
    border-radius: 4px;
    padding: 16px;
    min-height: 70px;
    cursor: pointer;

    transition: border-color 0.1s ease-in-out;

    &:hover {
      border-color: $teal;
    }

    &.skeleton {
      cursor: default;

      &:hover {
        border-color: $darkGrey;
      }

      .payment-form-card-row {
        align-self: center;
        background-image: linear-gradient(
          90deg,
          $grey 0px,
          $lightGrey 40px,
          $grey 80px
        );
        background-size: 600px;
        border-radius: 16px;
        animation: shine-lines 1.3s infinite linear;

        &:first-of-type {
          height: 16px;
          max-width: 200px;
          margin-bottom: 4px;
        }

        &:last-of-type {
          height: 12px;
          max-width: 140px;
        }
      }
    }

    .icon {
      grid-area: icon;

      &:first-of-type {
        width: 36px;
        height: 36px;
        mask-size: contain;
      }

      &.payment-form-card-check {
        grid-area: check;
        background-color: $grey;
        width: 24px;
        height: 24px;
        mask-size: contain;
        transition: background-color 0.1s ease-in-out;
      }
    }

    > div {
      color: $body2;
      font-size: 12px;

      transition: color 0.1s ease-in-out;

      strong {
        font-size: 16px;
        font-weight: 500;
      }
    }

    &-selected {
      border-color: $teal;

      > div {
        color: $labelDark;
      }

      .icon {
        &.payment-form-card-check {
          background-color: $teal;
        }
      }
    }
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 200px;
  }
}
