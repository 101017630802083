@import 'styles/_colors.scss';

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 100%;

  // colors
  &-teal {
    background-color: $teal;
  }
  &-orange {
    background-color: $orange;
  }
  &-purple {
    background-color: $purple;
  }
  &-yellow {
    background-color: $yellow;
    color: #333;
  }
  &-green {
    background-color: $green;
  }
  &-darkPink {
    background-color: $darkPink;
  }

  &-white {
    background-color: $white;
  }

  &-darkerGray {
    background-color: $darkerGray;
  }

  &-filterLabel {
    background-color: $filterLabel;
  }

  // sizing
  &-medium {
    min-width: 38px;
    height: 38px;
    font-size: 16px;
  }

  &-big {
    min-width: 42px;
    height: 42px;
    font-size: 16px;
  }
}
