@import 'styles/_colors.scss';

.editor-add-component-item {
  margin-bottom: 24px;
  position: relative;
  cursor: pointer;

  &--show-view {
    cursor: default;
    pointer-events: none;
  }
}

.editor-add-component-item-teaser {
  pointer-events: none;
}

.editor-add-component-icon {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow 0.2s;
  padding-top: 84%;
  border-radius: 6px;
  margin: 1px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.editor-add-component-screen .editor-add-component-icon {
  padding-top: 157%;
}

.editor-add-component-item-checkmark {
  background: #e0e0e0;
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  opacity: 0;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.editor-add-component-name {
  padding: 8px 4px;
  color: rgb(33, 33, 33);
  font-size: 12px;
  text-align: center;

  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 16ch;
  }
}

.editor-add-component-item:hover .editor-add-component-item-checkmark {
  opacity: 1;
}

.editor-add-component-item-selected .editor-add-component-icon {
  border-color: #ffc00e;
}

.editor-add-component-item-selected .editor-add-component-item-checkmark {
  opacity: 1;
  background: #ffc00e;
}

.editor-view-components {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  .icon-expand {
    margin-left: auto;
  }
}

.editor-view-component-thumbnail {
  width: 50px;
  border-radius: 6px;
  margin-right: 10px;
}

.editor-view-component-title {
  font-size: 14px;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor-add-component-price {
  display: flex;
  height: 16px;
  justify-content: center;
  flex-shrink: 0;
  position: absolute;
  left: 6px;
  bottom: 6px;
  width: fit-content;
  padding: 4px 8px 4px 4px;
  align-items: center;

  color: $teal;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;

  border-radius: 300px;
  border: 1px solid rgba(0, 168, 152, 0.5);
  background: #dff1f0;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.editor-add-component-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  position: absolute;
  top: 20%;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background: rgba(255, 255, 255, 0.9);
  transition: opacity 0.2s;
  z-index: 1;
  pointer-events: all;
  border-radius: 6px;
  border: 1px solid #333;

  &:hover {
    opacity: 1;
  }
}
