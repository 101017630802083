@import 'styles/_colors.scss';

.feature-templates-cta {
  &__toast {
    display: flex;
    background-color: $black;
    color: $white;
    width: 540px;
    text-wrap: nowrap;
    margin-bottom: 32px;
    font-family: 'Work Sans', sans-serif;
    right: 14rem;

    .btn {
      text-wrap: nowrap;
    }

    &-body > div {
      display: flex;
      align-items: center;
      gap: 2rem;

      h2 {
        font-size: 16px;
        font-weight: 600px;
        margin-bottom: 4px;
        max-width: 100%;
        text-wrap: wrap;
      }

      p {
        font-size: 13px;
      }
    }

    .Toastify__close-button {
      display: none;
    }
  }

  &__wrapper,
  &__collapsed {
    position: sticky;
    bottom: 0;
    width: 100%;
    margin: 0 -32px;
    border-top: 1px solid rgba(0, 168, 152, 0.5);
    background: $white;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 32px 12px 12px;
    padding-right: 16px;
    background: linear-gradient(
      180deg,
      rgba(0, 169, 150, 0) 0%,
      rgba(0, 169, 150, 0.03) 100%,
      rgba(0, 169, 150, 0.015) 100%
    );
    gap: 12px;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      background: linear-gradient(
        180deg,
        rgba(0, 169, 150, 0) 0%,
        rgba(0, 169, 150, 0.1) 100%,
        rgba(0, 169, 150, 0.05) 100%
      );
    }

    img {
      align-self: center;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h2 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: $body2;
      }
    }

    span {
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      text-transform: uppercase;
      align-self: flex-end;
      color: $teal;
    }
  }

  &__new-feature-label {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  &__close_arrows {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &__collapsed {
    background-image: linear-gradient(180deg, #ffffff 0%, #00a99630 100%);
    transition: background-image 0.5s ease;

    &:hover {
      background-image: linear-gradient(180deg, #009e8f 0%, #006051 100%);

      .btn.btn-list-add {
        color: $white;

        .icon {
          background-color: $white;
        }
      }
    }

    .btn.btn-list-add {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      height: 49px;

      span {
        margin-right: 8px;
      }

      &:hover:not([disabled]) {
        background-color: transparent;
      }
    }
  }
}
