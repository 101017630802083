@import 'styles/_colors.scss';

.settings-form-row {
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.settings-form-row-help-text-wrapper {
  margin-bottom: 20px;
}

.settings-form-row:first-of-type {
  margin-top: 0;
}

.settings-form-row:last-of-type {
  margin-bottom: 0;
}
.settings-form-row-wrapper-help-text {
  margin-bottom: 20px;
}

.settings-form-label-wrapper {
  display: flex;
  align-items: flex-end;
}

.settings-form-row label {
  display: block;
  margin-bottom: 5px;
  flex: 1;
  color: #666;
}

.settings-form-label-wrapper .icon {
  position: relative;
  mask-size: 20px;
  height: 20px;
  width: 20px;
}

.settings-form-input {
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-form-input .wrapped-select {
  flex: 1;
}

.settings-form-image .image-input-wrapper {
  height: 328px;
  width: 100%;
}

.settings-form-image .image-input-wrapper .image-input {
  height: 100%;
  width: 100%;
}

.settings-form-image .image-input-preview {
  background-size: cover;
}

.settings-form-file .file-input-wrapper,
.settings-form-file .file-input-remove {
  width: 100%;
}

.settings-form-file .file-input-filename,
.settings-form-file .file-input-select {
  font-size: 16px;
  font-weight: inherit;
}

.settings-form-file .file-input-filename,
.settings-form-file .file-input-select,
.settings-form-file .file-input-links {
  text-align: center;
}

.settings-form-file .file-input-value,
.settings-form-file .file-input-select {
  padding: 15px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 9px;
  background: #fff;
  line-height: inherit;
}

.settings-form-file .file-input-select {
  height: initial;
  display: block;
  color: #000;
}

.settings-form-input {
  margin-left: -16px;
  margin-right: -16px;
}

.settings-form-row input {
  border: 1px solid #e0e0e0;
  color: #000;
  background: #fff;
  font: inherit;
  font-size: 16px;
  padding: 15px 12px;
  display: block;
  outline: none;
  line-height: 20px;
  border-radius: 9px;
  flex: 1;
  padding-left: 15px;
}

.settings-form-row-small input {
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 10px;
}

.settings-form-row input::placeholder {
  color: #bbb;
}

.settings-form-row input[disabled] {
  opacity: 0.5;
}

.settings-form-row-error input {
  border-color: #f44;
}

.settings-form-static-value {
  font-size: 14px;
  font-weight: 500;
}

.settings-form-submit {
  margin: 20px -16px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.settings-form-submit > * {
  margin-left: 10px;
}

.settings-alert {
  margin: 20px -16px;
  text-align: center;
  padding: 10px;
  color: $orange;
  background-color: $lightOrange;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 9px;
  text-align: left;
  padding: 12px 15px;
}

.pay-settings-alert {
  margin: 0px -16px 40px;
  text-align: center;
  padding: 10px;
  color: $orange;
  background-color: $lightOrange;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  border-radius: 9px;
}

.settings-save-success {
  background-color: $lightTeal;
  color: $teal;
}

.settings-form-error {
  color: $orange;
  margin-top: 4px;
}

.settings-form-help-message {
  margin-left: 20px;
  color: #8a8a8a;
}

.settings-form-button-wrapper {
  margin: 0 -16px;
}

.settings-form-inline-submit {
  margin: 20px -8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-form-inline-submit.tight {
  margin-top: -20px;
}

.settings-form-inline-submit .btn + .btn {
  margin-left: 8px;
}

.settings-form-section-title {
  color: #666;
}

.settings-form-row-inline .settings-form-input input {
  margin-right: 16px;
}

.settings-form-row-inline .settings-form-error {
  margin-right: 80px;
}

.settings-form-split-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 30px -16px;
}

.settings-form-split-row .settings-form-row {
  flex: 1;
  margin: 0;
  padding: 0 16px;
}

.settings-form-split-row .settings-form-row + .settings-form-row {
  margin-left: 16px;
}

.settings-form-input-select {
  flex: 1;
}

.settings-form-row > .color-dropdown {
  margin-top: 0;
}

.settings-form-image-square {
  display: flex;

  .image-input-wrapper {
    height: 256px;
    width: 256px;
  }
}
