@import 'styles/_colors.scss';

.app-settings-section:first-of-type {
  margin-top: 0;
}

.app-settings-section {
  margin-top: 32px;
}

.app-settings-section h2 {
  font-size: 20px;
  margin: 0 0 10px;
}
.app-settings-section p {
  margin: 10px 0 20px;
}
.app-settings-section > .btn {
  width: 100%;
}

.tooltip-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.invite-modal-page-content {
  height: 275px;
}
.invite-modal-form h1 {
  font-size: 16px;
  margin-top: 20px;
  color: #333;
  font-weight: 500;
}
.invite-modal-form h2 {
  font-size: 12px;
  margin-top: -10px;
  color: #8a8a8a;
  font-weight: normal;
}
.invite-modal-form .btn {
  float: right;
  margin-top: -10px;
}
.invite-modal-form .wrapped-input label {
  font-size: 12px;
  color: #333;
  font-weight: 500;
}
.invite-modal-form {
  height: 50px;
  gap: 34px;
}
.data-object-form-footer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.data-object-form-cancel {
  flex: 1;
}

.link-button-text {
  font-weight: 600;
  margin-left: 8px;
}

.accounts-page form .modal-header h1 {
  line-height: 24px;
}

.accounts-page form .modal-content {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.user-invite-form h1 div {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.user-invite-form .modal-content .wrapped-select label {
  color: #8a8a8a;
  margin: 0 16px 5px;
}

.user-invite-form .modal-content .wrapped-select .multi-select-menu-selection {
  font-size: 14px;
  line-height: 36px;
  padding: 0 35px 0 16px;
}

.mobile-layout-help-text-container {
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 16px;
  margin: 0 -16px;
}

.mobile-layout-help-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobile-layout-help-header > h2 {
  margin-bottom: 0;
  margin-left: 9px;
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.mobile-layout-help-header > .icon {
  background-color: #333333;
}

.mobile-layout-help-text-container > h3 {
  font-weight: 500px;
  font-size: 12px;
  color: #333333;
  margin-top: 14px;
  margin-bottom: 5px;
}

.mobile-layout-help-text-container > p {
  font-weight: 400;
  font-size: 10px;
  color: #666666;
  line-height: 11.73px;
}

.app-layout-settings > button {
  margin: 16px -16px;
  float: right;
}

.app-layout-settings
  > .library-inspect-menu-control
  > .multi-menu-trigger
  > .multi-menu-trigger-element
  > .multi-select-menu-selection {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: none;
}

.app-layout-settings
  > .library-inspect-menu-control
  > .multi-menu-trigger.expanded
  > .multi-menu-trigger-element
  > .multi-select-menu-selection {
  border: 1px solid #ffffff;
}

.help-doc {
  font-weight: 700;
  font-size: 10px;
  color: #666666;
}

.settings-cta {
  &__toast {
    display: flex;
    background-color: $black;
    color: $white;
    width: 590px;
    text-wrap: nowrap;
    margin-bottom: 32px;
    font-family: 'Work Sans', sans-serif;
    right: 17rem;

    .btn {
      text-wrap: nowrap;
    }

    &-body > div {
      display: flex;
      align-items: center;
      gap: 2rem;

      h2 {
        font-size: 16px;
        font-weight: 600px;
        margin-bottom: 4px;
        max-width: 100%;
        text-wrap: wrap;
      }

      p {
        font-size: 13px;
      }
    }

    .Toastify__close-button {
      display: none;
    }
  }

  &__wrapper,
  &__collapsed {
    position: sticky;
    bottom: 0;
    width: 100%;
    margin: 0 -32px;
    border-top: 1px solid #d8d8d8;
    background: $white;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 32px 12px 12px;
    padding-right: 16px;
    background: linear-gradient(
      180deg,
      rgba(0, 169, 150, 0) 0%,
      rgba(0, 169, 150, 0.03) 100%,
      rgba(0, 169, 150, 0.015) 100%
    );
    gap: 12px;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      background: linear-gradient(
        180deg,
        rgba(0, 169, 150, 0) 0%,
        rgba(0, 169, 150, 0.1) 100%,
        rgba(0, 169, 150, 0.05) 100%
      );
    }

    img {
      align-self: center;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h2 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: $body2;
      }
    }

    span {
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      text-transform: uppercase;
      align-self: flex-end;
      color: $teal;
    }
  }

  &__new-feature-label {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  &__close_arrows {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &__collapsed {
    background-image: linear-gradient(180deg, #ffffff 0%, #00a99630 100%);
    transition: background-image 0.5s linear;

    &:hover {
      background-image: linear-gradient(180deg, #00a99605 0%, #00a99650 100%);
    }

    .btn.btn-list-add {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 64px;

      span {
        margin-right: 8px;
      }

      &:hover:not([disabled]) {
        background-color: transparent;
      }
    }
  }
}
