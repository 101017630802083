.library-inspect-settings-panel {
  margin-bottom: 15px;
}

.library-inspect-settings-panel-inner {
  margin: 4px -16px 0;
  border-radius: 4px;
  overflow: hidden;
}

.library-inspect-settings-panel-frame {
  border: none;
  width: 100%;
  display: block;
}
