.prerequisite-alert {
  &__indicator {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding: 6px 12px;
    margin-bottom: 10px;
  }

  &__icon {
    color: #aaa;
    font-size: 18px;
  }

  &--data {
    .prerequisite-alert__indicator {
      background-color: white;

      &--valid {
        color: #aaa;
      }

      &--invalid {
        color: lightgray;
      }
    }
  }

  // the wonky margins are to make everything line up
  // with the left panel component inputs
  &--component {
    margin-bottom: 30px;

    .prerequisite-alert__indicator {
      background-color: #f1f1f1;
      margin-left: -16px;
      margin-right: -16px;

      &--valid {
        color: #333;
      }

      &--invalid {
        color: #aaa;
      }
    }

    .help-link {
      margin: 0 -16px;
    }
  }
}
