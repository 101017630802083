.action-item {
  background-color: #fafafa;
  padding: 16px 32px;
  margin: 10px 0 5px;
  border: 1px solid #e5e5e5;
  border-width: 1px 0;
}

.action-item .empty-state {
  color: rgba(0, 0, 0, 0.5);
}

.action-item-form-row {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.action-item-form-row > label {
  flex: 1;
}

.action-item-form-row > div {
  flex: 3;
}

.action-item-header .icon-button-wrapper {
  margin-left: 16px;
  cursor: pointer;
}

.action-item-header .icon-button-wrapper .icon {
  background: #999;
}

.action-item-split-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px -10px;
}

.action-item-split-row + hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.action-item-split-row > * {
  flex: 1;
  margin: 0 10px;
}

.data-binding-panel .action-item-split-row label {
  display: block;
  font-size: 10px;
  margin-bottom: 10px;
}

.action-item-form-options hr {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.link-menu .multi-select-menu + .multi-select-menu {
  margin-top: 10px;
}

.action-actions-list {
  margin: 10px -16px;
}

.action-item-form-item {
  margin-top: 10px;
}

.action-item-form-item .bindable-text-control .wrapped-entity-textarea {
  background-color: #fff;
  margin: 0;
}

.action-item-form-item .accordion-children .accordion-boxed-done.is-editable {
  justify-content: space-between;
}

.action-item-form-item
  .accordion-children
  .accordion-boxed-done.is-editable
  .btn-text {
  margin-right: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

body > .action-item-form-item {
  z-index: 100000;
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.action-item-form-item-header {
  min-height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.action-item-type {
  margin: 0;
}

.action-item-type-subtitle {
  font-size: 11px;
  opacity: 0.6;
}

.action-after-trial-warning {
  display: flex;
  align-items: center;
}

.action-item-remove {
  margin: -6px -12px;
  padding: 6px;
  background: transparent;
  transition: background 0.2s;
  border-radius: 18px;
}

.action-item-remove .icon {
  background: rgba(0, 0, 0, 0.5);
  transition: background 0.2s;
  display: block;
}

.action-item-remove:hover {
  background: rgba(0, 0, 0, 0.06);
}

.action-item-remove:hover .icon {
  background: rgba(0, 0, 0, 0.8);
}

.action-item-fields .action-item-split-row.filled {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.action-item-fields-header {
  font-size: 10px;
}

.action-item-fields-header label {
  margin-bottom: 0;
}

.action-form-signup .action-item-fields {
  margin-top: -7px;
}

.action-form-signup .action-form-advanced-accordion {
  margin-top: 0;
}

.actions-list-add-button {
  margin: 10px auto 0;
  background: transparent;
  cursor: pointer;
  color: #9e9e9e;
  font: inherit;
  border: none;
  outline: none;
  transition: color 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.actions-list-add-button .icon {
  background: #9e9e9e;
  transition: background 0.2s;
  margin: -4px 0 -4px -6px;
}

.actions-list-add-button:hover {
  color: #888;
}

.actions-list-add-button:hover .icon {
  background: #888;
}

.action-form-navigate .accordion-title .icon {
  background: #9e9e9e;
}

.notifications-action-form {
  margin-top: 8px;
}

.action-item-form .filter-options-add a {
  color: #39a898;
  padding-left: 16px;
}

.action-item-form .filter-options {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: transparent;
}

.action-item-form .filter-options-items {
  margin-top: 8px;
}

.action-item-form-item-children .empty-state {
  font-size: 14px;
  margin: 15px 0 25px;
}

.dummy-action-item-form-item-children {
  text-align: center;
  color: #757575;
}

.action-form-authenticate {
  margin-top: -4px;
}
