@import 'styles/_colors.scss';

// generic button style
@mixin button($background, $color: false) {
  background: $background;
  @if ($color) {
    color: $color;
  } @else {
    color: $white;
  }

  &:hover,
  &:focus-visible,
  &.btn-focussed {
    background-color: darken($background, 5%);
    text-decoration: none;
  }

  &:active {
    background-color: darken($background, 10%);
    transition: 0s none;
  }

  .icon {
    @if ($color) {
      background-color: $color;
    } @else {
      background-color: $white;
    }
  }
}

@mixin secondary($color, $text) {
  background-color: $color;
  color: $text;
  border: none;

  .icon {
    background-color: $text;
  }
}

// text button style
@mixin text($primary, $color: false) {
  background-color: transparent;

  @if ($color) {
    color: $color;
  } @else {
    color: $primary;
  }

  &:hover,
  &:focus-visible,
  &.btn-focussed {
    background-color: mix($primary, $white, 20%);
  }

  .icon {
    @if ($color) {
      background-color: $color;
    } @else {
      background-color: $primary;
    }
  }
}

// outlined button style
@mixin outlined($primary, $color: false, $persistColor: false) {
  background-color: transparent;
  border-color: $primary;

  @if ($persistColor) {
    color: $color;
  } @else {
    color: $primary;
  }

  &:hover:not([disabled]),
  &:focus-visible:not([disabled]),
  &.btn-focussed {
    background-color: $primary;

    @if ($color) {
      color: $color;
    } @else {
      color: $white;
    }

    .icon {
      @if ($color) {
        background-color: $color;
      } @else {
        background-color: $white;
      }
    }
  }

  &:active:not([disabled]) {
    background-color: darken($primary, 5%);
    border-color: darken($primary, 5%);
  }

  .icon {
    @if ($persistColor) {
      background-color: $color;
    } @else {
      background-color: $primary;
    }
  }
}

@mixin listAddButtonHover($color) {
  &:hover:not([disabled]),
  &:focus-visible:not([disabled]),
  &.btn-focussed {
    color: darken($color, 10%);

    .icon {
      background: darken($color, 10%);
    }
  }

  &:active:not([disabled]) {
    color: darken($color, 20%);
  }
}

@mixin addFilterButtonHover($color, $backgroundColor) {
  &:focus-visible:not([disabled]) {
    color: $filterLabel;
    background-color: unset;

    .icon {
      background: $filterLabel;
    }
  }

  &:hover:not([disabled]) {
    background-color: $backgroundColor;
    color: $color;

    .icon {
      background: $color;
    }
  }

  &:active:not([disabled]) {
    color: $color;
  }
}

@mixin listAddButton($color) {
  background: transparent;
  color: $color;

  .icon {
    background-color: $color;
  }

  &:hover:not([disabled]),
  &:focus-visible:not([disabled]),
  &.btn-focussed {
    background-color: rgba($color, 0.1);
  }

  &:active:not([disabled]) {
    background-color: rgba($color, 0.15);
  }
}

@mixin hoverColor($color, $hoverColor) {
  background: transparent;
  color: $color;

  .icon {
    background-color: $color;
  }

  &:hover:not([disabled]),
  &:focus-visible:not([disabled]),
  &.btn-focussed {
    color: $hoverColor;
    background-color: rgba($hoverColor, 0.1);

    .icon {
      background: darken($hoverColor, 10%);
    }
  }

  &:active:not([disabled]) {
    color: $hoverColor;
    background-color: rgba($hoverColor, 0.1);

    .icon {
      background: darken($hoverColor, 10%);
    }
  }
}

.btn {
  appearance: none;
  position: relative;
  font: inherit;
  outline: none;
  padding: 0 25px;
  font-size: inherit;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.2s;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  height: 40px;
  border: 1px solid transparent;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @include button($teal);

  &.btn-text {
    @include text($label);
  }

  &.btn-outlined {
    @include outlined(rgba($black, 0.1), $label, true);
  }

  &.btn-flat {
    background-color: unset;
    padding: 0 12px;
    border-radius: 6px;
    padding: 0 16px;
    border-radius: 50px;

    @include listAddButton($label);
  }

  &.btn-list-add {
    border-radius: 6px;
    justify-content: flex-start;
    width: 100%;
    padding: 0 16px;

    @include listAddButton($teal);
  }

  &.btn-fit-content {
    width: fit-content;
  }

  &.btn-square {
    border-radius: 6px;
  }

  &.btn-block,
  &.btn-fluid {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &.btn-padded {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.btn-large {
    font-size: 14px;
    height: 50px;
    padding: 0 30px;

    &.btn-square {
      border-radius: 9px;
    }
  }

  &.btn-medium {
    height: 40px;
    padding: 0 25px;

    &.btn-square {
      border-radius: 4px;
    }

    &.btn-list-add {
      background-color: unset;
      padding: 0 16px;
      @include listAddButtonHover($teal);
    }
  }

  &.btn-small {
    height: 32px;
    padding: 0 25px;

    &.btn-square {
      border-radius: 4px;
    }

    &.btn-list-add {
      background-color: unset;
      padding: 0 12px;
      @include listAddButtonHover($teal);
    }
  }

  &.btn-link {
    justify-content: flex-start;
    margin-top: 5px;
    margin-left: -16px;
    margin-right: -16px;
    padding: 12px;
    background-color: unset;
    color: unset;
    @include hoverColor($filterLabel, $teal);
  }

  // * BUTTON COLORS
  &.btn-dark-pink {
    @include button($darkPink);

    &.btn-text {
      @include text($darkPink);
    }

    &.btn-outlined {
      @include outlined($darkPink);
    }

    &.btn-secondary {
      @include secondary($lightPink, $darkPink);
    }

    &.btn-list-add {
      @include listAddButton($darkPink);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($darkPink);
      }
    }
  }

  &.btn-orange {
    @include button($orange);

    &.btn-text {
      @include text($orange);
    }

    &.btn-outlined {
      @include outlined($orange);
    }

    &.btn-secondary {
      @include secondary($lightOrange, $orange);
    }

    &.btn-list-add {
      @include listAddButton($orange);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($orange);
      }
    }

    &.btn-gradient {
      background: linear-gradient(180deg, #ef4c30 0%, #a82058 100%);
      border: none;
      outline: none;
      position: relative;

      &[disabled] {
        background: none;
        color: #ccc;
        border: 1px solid #ccc;
      }
    }
  }

  &.btn-yellow {
    @include button($yellow, $black);

    &.btn-text {
      @include text($yellow, $black);
    }

    &.btn-outlined {
      @include outlined($yellow, $black);
    }

    &.btn-secondary {
      @include secondary($lightYellow, $yellow);
    }

    &.btn-list-add {
      @include listAddButton($yellow);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($yellow);
      }
    }
  }

  &.btn-green {
    @include button($green);

    &.btn-text {
      @include text($green);
    }

    &.btn-outlined {
      @include outlined($green);
    }

    &.btn-secondary {
      @include secondary($lightGreen, $green);
    }

    &.btn-list-add {
      @include listAddButton($green);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($green);
      }
    }
  }

  &.btn-purple {
    @include button($purple);

    &.btn-text {
      @include text($purple);
    }

    &.btn-outlined {
      @include outlined($purple);
    }

    &.btn-secondary {
      @include secondary($lightPurple, $purple);
    }

    &.btn-list-add {
      @include listAddButton($purple);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($purple);
      }
    }
  }

  &.btn-teal {
    @include button($teal);

    &.btn-secondary {
      @include secondary($lightTeal, $teal);
    }

    &.btn-text {
      @include text($teal);
    }

    &.btn-outlined {
      @include outlined($teal);
    }

    &.btn-list-add {
      @include listAddButton($teal);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($teal);
      }
    }

    &.btn-gradient {
      background: linear-gradient(180deg, #00a898 0%, #006b5a 100%);
      border: none;
      outline: none;

      &[disabled] {
        background: none;
        color: #ccc;
        border: 1px solid #ccc;
      }
    }
  }

  &.btn-gray {
    @include button($gray, $black);

    &.btn-text {
      @include text($label);
    }

    &.btn-outlined {
      @include outlined(rgba($black, 0.1), $label, true);
    }

    &.btn-list-add {
      @include listAddButton($label);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($label);
      }
    }
  }

  &.btn-white {
    background: $white;

    &.btn-text {
      @include text($black);

      &:hover {
        background-color: $white;
      }
    }

    &.btn-outlined {
      border-color: #cccccc;

      &:hover,
      &.btn-focussed {
        background-color: $white;
        border-color: $teal;
        border-width: 2px;

        .icon {
          background-color: $teal;
        }
      }

      .icon-teal {
        background-color: $teal;
      }

      .icon-midGray {
        background-color: $midGray;
      }
    }
  }

  &.btn-dark-gray {
    @include button($filterLabel, $filterLabel);

    transition: all 0.2s;

    &.btn-text {
      @include text($filterLabel);
    }

    &.btn-outlined {
      @include outlined(rgba($filterLabel, 0.1), $filterLabel, true);
    }

    &.btn-list-add {
      background-color: unset;
      @include addFilterButtonHover($black, transparent);

      &.btn-small {
        background-color: unset;
        @include addFilterButtonHover($black, transparent);

        &.btn-background {
          @include addFilterButtonHover($black, $midGray);
        }
      }

      &.btn-background {
        @include addFilterButtonHover($black, $gray);
      }
    }
  }

  &.btn-red {
    @include button($red);

    &.btn-secondary {
      @include secondary($lightRed, $red);
    }

    &.btn-text {
      @include text($red);
    }

    &.btn-outlined {
      @include outlined($red);
    }

    &.btn-list-add {
      @include listAddButton($red);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($red);
      }
    }
  }

  &.btn-pink {
    @include button($pink);

    &.btn-secondary {
      @include secondary($lightPink, $pink);
    }

    &.btn-text {
      @include text($pink);
    }

    &.btn-outlined {
      @include outlined($pink);
    }

    &.btn-list-add {
      @include listAddButton($pink);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($pink);
      }
    }
  }

  &.btn-transcosmos-red {
    @include button($transcosmosRed);
  }

  &.btn-black {
    @include button($black);

    &.btn-secondary {
      @include secondary($darkGray, $black);
    }

    &.btn-text {
      @include text($black);
    }

    &.btn-outlined {
      @include outlined($black);
    }

    &.btn-list-add {
      @include listAddButton($black);

      &.btn-small {
        background-color: unset;
        @include listAddButtonHover($black);
      }
    }
  }

  // * ATTRIBUTE STYLES
  &:disabled {
    background: transparent;
    border: 1px solid #ccc;
    color: #ccc;
    opacity: 0.5;
    cursor: not-allowed;

    .icon {
      background-color: #ccc;
    }
  }

  // * CHILDREN STYLES
  .icon {
    transition: all 0.2s;
  }
}

// * LEGACY STYLES
// TODO: REMOVE IF UNUSED OR MOVE INTO .btn
.btn:focus-visible {
  background: #f00;
}

.btn-danger {
  background: #d30;
}

.btn-danger:hover:not([disabled]),
.btn-danger:focus:not([disabled]) {
  background: #e30;
}

.btn-secondary {
  background: transparent;
  border: 1px solid #ccc;
  color: #000;
}

.btn-secondary:hover:not([disabled]),
.btn-secondary:focus-visible:not([disabled]) {
  background: transparent;
  border-color: #aaa;
}

.btn-secondary.btn-danger {
  color: #d30;
}

.btn-loading-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn > .icon + * {
  margin-left: 5px;
}

.btn > .icon:first-child {
  margin-left: -4px;
}

.btn > .icon:last-child {
  margin-right: -4px;
}

.btn > .icon.icon-expand-vertical:last-child {
  margin-right: -12px;
}

.btn.icon-right > .icon {
  margin-left: 6px;
}

.form-row .btn-fluid {
  justify-content: flex-start;
  border-radius: 6px;
  padding: 0 16px;
}

.btn > .icon:first-child {
  margin-right: 4px;
}

.multi-menu-trigger.expanded {
  .btn.btn-list-add {
    background: rgba($teal, 0.1);

    &.btn-orange {
      background: rgba($data, 0.1);
    }

    &.btn-gray {
      background: rgba($label, 0.1);
    }

    &.btn-yellow {
      background: rgba($yellow, 0.1);
    }
  }
}
