@import 'styles/_colors.scss';

#data-menu-options {
  .advanced-options {
    margin-top: 15px;
  }
}

.advanced-options {
  margin-left: -32px;
  margin-right: -32px;

  .accordion-title {
    color: $body2;
    font-weight: 500;
    text-transform: uppercase;
    justify-content: center;
    padding: 8px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $grey;
    }
  }

  .accordion-children {
    background-color: $lightGrey;
    padding: 16px;

    > * {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .inspect-generic-row {
      padding: 8px 12px;
      background-color: #fff;
      border-radius: 4px;

      .single-number-control input {
        border: none;
      }

      &-title {
        font-size: 12px;
        color: $label;
        margin-left: 6px;
      }
    }
  }

  &.accordion-expanded {
    .accordion-title {
      background-color: $grey;

      &:hover {
        background-color: $darkGrey;
      }
    }
  }
}
