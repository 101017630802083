@import 'styles/_colors.scss';

.tab-bar {
  .tab-bar__tabs {
    box-shadow: 0 0.5px rgba(0, 0, 0, 0.2);
    padding: 0 50px;
    font-size: 13px;

    display: flex;
    flex-direction: row;
    gap: 20px;

    align-items: center;
    justify-content: center;
  }

  .tab-bar__tabs a {
    color: $black;
  }
}

.tab-bar-tab {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;

  opacity: 0.5;
  cursor: pointer;
  height: 40px;
  text-align: center;

  .tab-bar-tab__label {
    padding: 0 15px;
  }

  &:after {
    content: ' ';
    opacity: 0;
    width: 100%;

    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;

    border-bottom: 3px solid $black;
    border-radius: 0;
    transition: opacity 0.4s;
  }

  &.tab-bar-tab--active {
    opacity: 1;
    cursor: auto;

    &:after {
      opacity: 1;
    }
  }
}
