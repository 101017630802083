@import 'styles/_colors.scss';
@import 'styles/_form.scss';

.app-questions-modal {
  .modal-header {
    padding: 32px 40px 16px;

    h1 {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .modal-content {
    padding-top: 24px;
  }

  &__form {
    @include form-style;

    p {
      font-size: 14px;
    }
  }
}
