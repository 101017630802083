@import 'styles/_colors.scss';

$transition: all 0.2s;

.magic-start {
  .magic-database-setup {
    &-title {
      margin-top: 20px;
      color: $labelDark;
      display: flex;
      flex-direction: column;
      gap: 18px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .schema-error-message {
        padding: 0 16px;
        color: $data;
        margin-top: -8px;
        font-weight: bolder;
      }

      .tips-for-prompting {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 6px;
        overflow: hidden;

        &.accordion-expanded {
          background-color: #f4f4f4;
          border: 1px solid rgba($subtitle, 0.5);

          .accordion-children {
            border-top: 1px solid rgba($subtitle, 0.5);
          }
        }

        .accordion-title {
          font-size: 12px;
          font-weight: 500;
          color: $labelDark;
          text-transform: uppercase;
          padding: 15px 0;
          margin-left: -7px;
        }

        .accordion-children {
          background-color: $white;
          padding: 24px 18px;
        }

        &-content {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        &-tip {
          display: flex;
          flex-direction: column;
          gap: 5px;

          &-title {
            font-size: 12px;
            font-weight: 500;
            color: $black;
          }

          &-prompt {
            font-size: 12px;
            font-weight: normal;
            font-style: italic;
            color: #5f5f5f;
            padding: 12px 18px;
            user-select: text;
          }
        }
      }
    }
  }

  > button.btn {
    background-color: transparent;
    border-radius: 0;
    color: $data;
    font-size: 14px;
    margin: 0 -32px;
    padding: 16px 32px;
    justify-content: flex-start;

    height: auto;
    width: calc(100% + 64px);

    &:hover {
      background-color: $lightData;
    }

    .icon {
      background-color: $data;
      margin-right: 8px;
    }
  }
}

.magic-start-setup-complete {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: $labelDark;
  }
}
