.library-component-inner-wrapper {
  -webkit-font-smoothing: antialiased;
}

.library-component-error {
  display: block;
  background-color: rgb(255, 204, 204);
  border: 1px solid rgb(221, 0, 0);
  padding: 4px;

  height: 100%;
  width: 100%;
}

.library-component-missing-license {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 100;

  background-color: #e5222266;
  border: 1px solid #e52222;
  margin: 20px;
}
