.wrapped-input {
  margin: 25px 0;
  position: relative;
  padding-bottom: 1px;
}

.wrapped-input label, .wrapped-input input {
  display: block;
  width: 100%;
  text-align: left;
  color: #8a8a8a;
}

.wrapped-input label {
  font-size: 12px;
  margin: 0 16px 5px;
}

.wrapped-input input {
  border: none;
  background: transparent;
  font: inherit;
  color: inherit;
  padding: 0;
  outline: none;
  transition: all 0.2s;
  line-height: 36px;
  height: 50px;
  font-size: 14px;
}

.wrapped-input.wrapped-input-lg input {
  line-height: 54px;
  font-size: 16px;
}

.wrapped-input input::placeholder {
  color: #b1b8cb;
}

.wrapped-input-error-message {
  color: #f44;
  font-weight: 500;
  margin: 5px 16px 0;
}

.wrapped-input-inner-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 0 16px;
  border-radius: 9px;
}

.wrapped-input-focussed .wrapped-input-inner-wrapper, .wrapped-input-error .wrapped-input-inner-wrapper {
  border-color: #ccc;
}

.wrapped-input.wrapped-input-error .wrapped-input-inner-wrapper {
  border-color: #f44 !important;
}

.wrapped-input-input {
  flex: 1;
}

.wrapped-input-prefix, .wrapped-input-suffix {
  color: #8a8a8a;
  line-height: 44px;
}

.wrapped-input-prefix {
  padding-right: 2px;
}

.wrapped-input-suffix {
  padding-left: 2px;
}

.wrapped-input-disabled > .wrapped-input-inner-wrapper {
  background-color: #eee;
}