.notifications-icon-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 -16px;

  > * {
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }
  }

  .settings-form-row {
    width: 100%;
    flex: 1;
    margin-top: 0;
    margin-bottom: 0;

    &:last-of-type {
      flex: 2;
    }

    .settings-form-input {
      margin-left: 0;
      margin-right: 0;
    }

    .settings-form-label-wrapper {
      margin-left: 16px;
    }

    .settings-form-image {
      .image-input-wrapper {
        width: 100%;
        height: auto;
        .image-input {
          width: 100%;
          padding-top: 100%;
        }
      }
    }

    .settings-form-error {
      margin-left: 16px;
    }
  }
}

.notifications-icon-preview {
  position: relative;
  margin-bottom: 8px;

  span {
    position: absolute;
    top: 9%;
    left: 20%;

    display: inline-block;
    height: 14px;
    width: 14px;
    mask-type: alpha;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: cover;
    background-color: white;
    z-index: 1;
  }
  img {
    z-index: 0;
  }
}
