@import 'styles/_colors.scss';

.entity-text-control {
  border: 1px solid $darkGrey;
  border-radius: 6px;
  overflow: hidden;

  margin: 6px -16px 0;
  padding: 0 16px;

  &-wrapper {
    margin-bottom: 12px;
  }

  &-options {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    margin: 0 -16px;
    border-top: 1px solid $darkGrey;

    > * {
      margin-right: 4px;

      &:nth-child(2) {
        margin-right: 0;
        flex: 1;
      }
    }

    .entity-text-control-trigger {
      border-radius: 6px;
      &.expanded {
        background-color: rgba($orange, 0.1);
      }
    }
  }

  &-edit-styles {
    flex: 1;

    display: inline-grid;
    grid-auto-flow: column;
    grid-template-columns: 24px 1fr;
    gap: 8px;
    width: 100%;

    &-open {
      .icon-button-wrapper {
        background-color: $lightDarkPink;
      }
    }

    &-divider {
      border-left: 1px solid $darkGrey;
      max-height: 16px;
      width: 1px;
    }

    &-items {
      display: inline-grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(29px, max-content);
      gap: 4px;
      align-items: center;

      // hide the first divider in the edit styles toolbar
      :first-child {
        .entity-text-control-edit-styles-divider {
          opacity: 0;
        }
      }
    }

    &-item {
      max-height: 24px;
      height: 100%;

      display: grid;
      align-items: center;
      gap: 4px;
      grid-auto-flow: column;
      grid-template-columns: 1px auto;
      font-weight: 500;

      .tooltip-trigger {
        > * {
          border-radius: 6px;
          overflow: hidden;

          &:hover {
            background-color: $grey;
          }

          .icon {
            background-color: $black;
          }
        }
      }

      > * {
        :not(.entity-text-control-edit-styles-divider) {
          width: 100%;
        }

        height: 100%;
        font-size: 10px;
        overflow: hidden;

        .multi-menu-trigger {
          height: 100%;
        }

        .multi-menu-trigger-element {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          width: 100%;
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            height: 12px;
            width: 12px;
            mask-size: contain;
          }

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .font-picker-wrapper,
    .text-weight-picker-wrapper {
      .multi-menu-trigger-element {
        padding: 6px 8px;
      }
    }

    .font-picker-wrapper {
      max-width: fit-content;
    }

    .text-weight-picker-wrapper {
      max-width: 68px;
    }

    .color-picker-wrapper {
      background: unset;
      border: unset;
      padding: 0;
      width: 100%;
      min-width: unset;

      display: flex;
      align-items: center;
      justify-content: center;

      .color-picker-swatch {
        display: inline;
        height: 16px;
        max-width: 16px;

        &-sub {
          height: 16px;
          width: 16px;
          border: 1px solid $darkGrey;
        }
      }
    }

    .text-control {
      height: 24px;
      max-width: 24px;

      &-sub {
        height: inherit;
        width: inherit;
        display: flex;
        flex-wrap: wrap;

        input {
          height: inherit;
          width: 100%;
          padding: 0;
          text-align: center;
          border: unset;
          font-size: 10px;
          border-radius: unset;
          background: transparent;
          line-height: 100%;
        }
      }
    }
  }

  // disable the bindable text header
  .bindable-text-control {
    &-header {
      display: none;
    }

    > .entity-textarea {
      border-radius: unset;
      background-color: $lightGrey;
      padding: 16px 14px !important;
    }
  }
}

.entity-text-control-tooltip {
  margin-top: 8px;
}
