.table-columns {
  .accordion-children-advanced-options + .accordion-boxed-done .btn.btn-text {
    color: #333333;
    &:hover {
      background-color: transparent;
    }
  }

  .accordion-children-advanced-options {
    .accordion-children {
      background-color: rgba(0, 168, 152, 0.1);
      margin: 16px -16px 0;
      padding: 16px;
      width: unset;
    }
  }
}
