.test-connection-initial {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  text-align: center;
  flex-direction: column;
  max-width: 280px;
  margin: 0 auto;

  h2 {
    font-size: 20px;
    margin-bottom: 0.5em;
  }

  p {
    color: #777;
    margin-bottom: 20px;
  }
}

.test-connection-results {
  user-select: text;

  h2 {
    font-size: 20px;
    margin-bottom: 0.5em;
  }

  .test-connection-button {
    float: right;
  }

  pre {
    overflow-x: auto;
  }
}
