.select-icon-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-icon-option-label {
  flex: 1;
}

.select-icon-option .icon {
  margin-right: 10px;
}
