.library-image-control .wrapped-select {
  margin: 10px -16px 0;
  height: 44px;
  background: #f1f1f1;
  box-shadow: none;
}

.image-control-dynamic-image-option .icon {
  background: #ef4c30;
}
.file-control-dynamic-file-option .icon {
  background: #ef4c30;
}

.library-file-control .wrapped-select {
  margin: 10px -16px 0;
  height: 44px;
  background: #f1f1f1;
  box-shadow: none;
}

.image-file-dynamic-file-option .icon {
  background: #ef4c30;
}

.library-video-control .wrapped-select {
  margin: 10px -16px 0;
  height: 44px;
  background: #f1f1f1;
  box-shadow: none;
}

.library-video-control p.hint-text {
  margin-top: 10px;
}