@import 'styles/_colors.scss';

@mixin icon($color) {
  .icon {
    background-color: $color;

    &:hover,
    &:focus,
    &:active {
      background-color: $color;
    }
  }

  &.icon-button-wrapper-muted {
    .icon {
      background-color: $label;
    }
  }

  &:hover {
    background-color: rgba($color, 0.15);

    .icon {
      background-color: $color;
    }
  }

  &:active,
  &:focus {
    background-color: rgba($color, 0.3);

    .icon {
      background-color: $color;
    }
  }

  &.icon-button-wrapper-active {
    background-color: rgba($color, 0.1);
  }

  &.icon-button-wrapper-solid {
    background-color: $color;

    .icon {
      background-color: white;
    }
  }

  &.icon-button-wrapper-disabled {
    opacity: 0.5;
  }

  // reset styles for tooltip icon
}

.icon-button-wrapper {
  display: block;
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  padding: 4px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.2s;
  cursor: pointer;

  .icon {
    background-color: $black;

    &:hover,
    &:focus,
    &:active {
      background-color: $black;
    }
  }

  &:hover {
    background-color: rgba($black, 0.15);
  }

  &:active,
  &:focus {
    background-color: rgba($black, 0.3);
  }

  // status
  &-loading {
    pointer-events: none;
  }

  // style
  &-rounded {
    border-radius: 6px;
  }

  // sizing
  &-large {
    width: 48px;
    height: 48px;
    max-width: 48px;
    max-height: 48px;
    padding: 12px;
    margin-left: -12px;
    margin-right: -12px;
  }

  &-small {
    width: 24px;
    height: 24px;

    .icon {
      height: 16px;
      width: 16px;
      mask-size: contain;
    }
  }

  &-active {
    background-color: rgba($black, 0.15);
  }

  &-disabled {
    pointer-events: none;
  }

  // colors
  &-orange {
    @include icon($orange);
  }

  &-yellow {
    @include icon($yellow);
  }

  &-darkPink {
    @include icon($darkPink);
  }

  &-light {
    @include icon($label);
  }

  &-white {
    @include icon(#ffffff);
  }
}
