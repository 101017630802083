@import 'styles/_colors.scss';

.editor-nav {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  font-weight: 600;
}

.editor-nav .editor-nav-link {
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  margin: 0 5px;
  text-decoration: none;
  transition: all 0.1s;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #b1b8cb;
}

.editor-nav .editor-nav-link:before {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -10px;
  top: 1px;
  width: 20px;
  border-top: 2px solid transparent;
  transition: border 0.2s;
}

.editor-nav .editor-nav-link:hover {
  color: #c4c9d8;
}

.editor-nav .editor-nav-link:hover:before {
  border-color: #c4c9d8;
}

.editor-nav .editor-nav-link.editor-nav-link-active {
  color: #fff;
}

.editor-nav .editor-nav-link.editor-nav-link-active:before {
  border-color: #fff;
}

.editor-nav .editor-nav-link.editor-nav-link-has-notification:after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background: #5883ff;
  position: absolute;
  top: 23px;
  right: 5px;
  padding: 0;
  margin: 0;
  border-radius: 3px;
}

.app-switcher-container {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -131px;
  width: 262px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
}

.app-switcher {
  &-link {
    &-center {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .icon-style-circle {
        margin: 0;
        margin-left: auto;
        height: 16px;
        width: 16px;

        .icon {
          mask-size: contain;
          margin: 0;
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}

.app-switcher .navbar-dropdown-title {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 40px;
  color: #333;
  font-size: 16px;
  font-weight: normal;
  padding: 0 8px 0 16px;
  overflow: hidden;
  max-width: 360px;
}

.app-switcher.expanded .navbar-dropdown-title,
.app-switcher .navbar-dropdown-title:hover {
  background-color: #f5f5f5;
}

.app-switcher .navbar-dropdown-children {
  margin-top: 0;
  border-radius: 6px;
  padding: 0;
}

.app-switcher .app-switcher-link {
  color: #616161;
  font-weight: normal;
  display: flex;
  align-items: center;
  padding: 12px;
}

.app-switcher .app-switcher-link-center {
  margin-left: 10px;
  flex: 1;
  overflow: hidden;
}

.app-switcher-active-link {
  background-color: rgba(0, 168, 152, 0.12);
}

.app-switcher .app-switcher-dropdown-inner {
  overflow-y: auto;
  /*
   * remove 60px from the header height
   * remove 47px from the app-switcher-new-app-link height
   * remove  1px from the app-switcher-new-app-link border
   */
  max-height: calc(100vh - 125px);
}

.app-switcher-dropdown-inner > * {
  border-bottom: 1px solid $backgroundGray;

  &:last-child {
    border-bottom: none;
  }
}

.app-switcher .app-switcher-new-app-link {
  font-size: 14px;
  height: 60px;
  border-top: 1px solid #f1f1f1;
}

.app-switcher .app-switcher-new-app-link .app-switcher-link-text {
  font-size: 14px;
  letter-spacing: 0.5px;
}

.app-switcher .app-switcher-link-icon {
  width: 40px;
  height: 30px;
}

.app-switcher .app-switcher-link:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.app-switcher .app-switcher-link:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.app-switcher .app-switcher-link-text {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-switcher .app-switcher-link-organization {
  margin-top: 2px;
  font-size: 12px;
  color: #8a8a8a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-switcher-current-app {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  gap: 8px;

  > .icon-style-circle {
    height: 16px;
    width: 16px;
    margin: 0;

    .icon {
      margin: 0;
      height: 12px;
      width: 12px;
      mask-size: contain;
    }
  }

  &-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.app-switcher-organization-name {
  padding: 15px 15px 10px 15px;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid #f1f1f1;
}

.app-switcher-shared-database {
  flex-direction: column;
  border-bottom: 1px solid $backgroundGray;
  position: relative;

  > * {
    position: relative;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    &:before,
    &:after {
      content: '';
      background-color: #d9d9d9;
      z-index: 1;
    }

    &:before {
      display: inline-block;
      border-radius: 50%;
      height: 8px;
      width: 8px;
    }

    &:after {
      width: 2px;
      height: 48px;
      position: absolute;
      top: 30px;
      left: 15px;
    }

    &:first-child {
      &:after {
        top: 20px;
        height: 50px;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &-title {
    display: flex;
    padding: 15px 12px;
    text-transform: uppercase;
    font-size: 10px;

    > span,
    .icon {
      margin: 0;
    }

    > span {
      margin-left: auto;
      height: 16px;
      width: 16px;
    }

    .icon {
      mask-size: contain;
      height: 12px;
      width: 12px;
    }
  }

  &-link {
    padding: 12px !important;

    .app-icon-medium {
      height: 30px;
      width: 30px;
    }

    .app-switcher-link-center {
      margin: 0;
    }
  }
}

.published-icon-tooltip-trigger {
  margin: 0 0 0 auto !important;
}

.published-tooltip {
  margin-left: 8px;
}

.shared-database-published-icon {
  margin: 0 !important;
  height: 16px;
  width: 16px;
}
