.results-key-wrapper {
  position: relative;

  .icon-button-wrapper {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    right: 16px;

    .icon {
      background: #8a8a8a;
    }
  }
}
