@import 'styles/_colors.scss';

$panel-width: 290px;
$grip-height: 36px;
$dim-black: #222327;
$inactive-color: rgb(246, 246, 246);
$active-color: rgb(227, 230, 232);
$border-radius: 3px;
$session-row-height: 32px;

.app-rooms-container {
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 400px;
  width: $panel-width;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  background-color: $inactive-color;
  transition: all 500ms ease-out;

  .grip {
    position: absolute;
    left: 10%;
    width: 80%;
    top: -$grip-height;
    height: $grip-height;
    text-align: center;
    line-height: $grip-height;
    background-color: $inactive-color;
    cursor: pointer;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .content {
    width: 100%;
    overflow: hidden;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .session-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: $session-row-height;
      line-height: $session-row-height;

      font-family: 'Work Sans', sans-serif;
      font-size: 16px;
      font-weight: 500;

      .avatar {
        border-radius: 8px;
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border: 1.5px solid $dim-black;
      }

      .name {
        text-wrap: nowrap;
        color: $dim-black;

        .user-id {
          display: inline-block;
          font-size: 0.95em;
          opacity: 0.65;
        }

        .current-session-indicator {
          display: inline-block;
          margin-left: 8px;
          font-size: 0.8em;
          opacity: 0.6;
        }
      }
    }
  }

  &.expanded {
    background-color: $active-color;

    .grip {
      background-color: $active-color;
    }
  }
}
