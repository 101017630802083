.table-component-data-styles-control {
  &.single-column .inspect-generic-row-body {
    grid-template-columns: 1fr;
  }

  .inspect-generic-row-body {
    margin: 5px -16px 0 -16px;
    gap: 8px;
    height: fit-content;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    .color-dropdown__wrapper {
      flex: 1;
    }

    .color-dropdown {
      margin: 0;
      height: 44px;

      .color-dropdown-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80px;
      }
    }
  }
}
