* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
div[data-reactroot] {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  color: #333;
  background: #fff;
}

svg,
text {
  font-family: 'Work Sans', sans-serif;
}

button,
input {
  font: inherit;
}

h1 {
  font-size: 32px;
  margin-bottom: 1.8em;
  font-weight: 600;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin-bottom: 1.5em;
}

h2,
h3,
h4 {
  font-size: 14px;
}

h5,
h6 {
  font-size: 12px;
}

small {
  font-size: 0.866666667em;
  color: #9a9a9a;
  font-weight: 400;
}

a {
  color: #00a898;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

a.deemphasize {
  color: #9a9a9a;
  text-decoration: none;
}

a.deemphasize:hover {
  text-decoration: underline;
}

a.link-button {
  color: #00a898;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 600;
  text-decoration: none;
  padding: 0.2em 0;
  margin: 0 0.3em;
}

a.link-button:hover {
  box-shadow: 0 1px;
}

a.camo {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

a.camo:hover {
  text-decoration: underline;
}

p.secondary {
  color: #666;
}

p.large {
  font-size: 14px;
  line-height: 1.3em;
}

.app {
  padding-top: 64px;
  transition: padding 0.2s;
  color: #333;
}

.offline-banner-visible .app {
  padding-top: 96px;
}

.app-body {
  padding-bottom: 50px;
}

pre {
  background: rgba(0, 0, 0, 0.04);
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 2px;
}

pre.pre-transparent {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}

code {
  color: #4b7bff;
  background: #4b7bff22;
  padding: 2px 4px;
  border-radius: 2px;
}

#schedule-4 h3 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

#schedule-4 ol {
  list-style: lower-latin;
  margin-left: 2em;
}

#schedule-4 li {
  margin-bottom: 0.5em;
}

#schedule-4 li ol {
  list-style: lower-roman;
  margin-top: 0.5em;
  margin-left: 1em;
}

#schedule-4 h4 {
  font-size: 14px;
  text-decoration: underline;
  margin-top: 2em;
  margin-bottom: 2em;
}

#schedule-4 h5 {
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  margin-top: 2em;
  margin-bottom: 1em;
}
