.snackbar {
  font-size: 14px;
  background: #333;
  color: #fff;
  border-radius: 6px;
  overflow: hidden;
  min-width: 200px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
              0px 4px 5px 0px rgba(0, 0, 0, 0.14),
              0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.snackbar-stripe {
  height: 4px;
}

.snackbar-content {
  padding: 20px 50px 20px 28px;
  line-height: 1.3em;
}

.snackbar .icon-button-wrapper {
  position: absolute;
  top: 30px;
  right: 14px;
  opacity: 0;
  transition: opacity 0.2;
}

.snackbar:hover .icon-button-wrapper {
  opacity: 1;
}
