@import 'styles/_colors.scss';

.action-item-fields {
  margin-top: 20px;
}

.action-item-field-item {
  position: relative;
  padding-right: 25px;
  display: flex;
  flex-direction: row;
  margin: 7px 0 7px -5px;
}

.action-item-field-item:last-child {
  margin-bottom: 0;
}

.action-item-field-item > div {
  flex: 1;
  margin: 0 5px;
}

.action-item-field-item > label {
  text-align: center;
  display: block;
  flex: 1;
}

.action-item-field-item .delete-icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -12px;
}

.multi-menu-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon {
    margin-right: 4px;
  }
}

.conditional-actions-wrapper {
  > * {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > p {
    margin-left: 12px;
  }

  > div {
    &:not(.filter-options-items) {
      height: 44px;
    }

    &.multi-select-menu {
      .multi-menu-trigger-element {
        height: inherit;

        .multi-select-menu-selection {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: inherit;
          box-shadow: unset;

          .multi-select-menu-expand-icon {
            &:before {
              right: 26px;
            }
          }
        }
      }
    }

    &.selectable-input {
      box-shadow: unset;

      .selectable-input-value {
        padding: 10px 12px;

        .selectable-input-input {
          padding-left: 0;
        }

        .selectable-input-selected-value {
          margin-left: 0;
          background-color: $orange;
        }
      }

      .selectable-input-menu-trigger {
        right: 26px;

        .icon {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}

.multi-menu-link-from-source {
  margin-top: 30px !important;
}
