@keyframes notification-slide-in {
  from {
    transform: translateY(160px);
    margin-top: -64px;
  }

  to {
    transform: translateY(0);
    margin-top: 20px;
  }
}

@keyframes notification-slide-out {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
    margin-top: 20px;
  }

  50% {
    opacity: 0;
    margin-top: -64px;
  }

  100% {
    opacity: 0;
    margin-top: -64px;
  }
}

.notifications {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 100001;
  pointer-events: none;
  max-width: 300px;
}

.notification-item {
  margin-top: 20px;
  pointer-events: all;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.notification-item-enter {
  animation: notification-slide-in 0.3s 1;
}

.notification-item-exit {
  animation: notification-slide-out 1s 1;
}
