@import 'styles/_colors.scss';
@import './common.scss';
@import './constants.scss';

.PlanOptionsBoard {
  margin: -40px;
  padding: 0 24px;

  &-intervalToggle {
    @include flex;
    @include f-14;

    > * {
      @include mr-3;
    }
  }

  &-annualOffer {
    @include flex;
    @include bold;
    @include color-teal;
    @include align-center;

    letter-spacing: 0.5px;

    &-arrow {
      @include mr-1;
      width: 12px;
      height: 6px;
    }

    &-text {
      @include f-11;
    }
  }

  &-cards {
    @include flex;
    @include align-stretch;
    @include pt-8;
    @include space-around;
    column-gap: $cardGap;
    min-width: (5 * $cardWidth) + (4 * $cardGap);
  }
}
