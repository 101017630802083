@import 'styles/_colors.scss';

.restore-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 16px;
    font-weight: 500;
  }

  p {
    color: $body2;
    font-size: 14px;
    max-width: 75%;
    font-weight: 400;
  }

  .icon {
    width: 72px;
    height: 72px;
    margin-bottom: 20px;
    background-color: $midGrey;
  }
}
