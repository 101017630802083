.relative {
  position: relative;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.absolute {
  position: absolute;
}
.right-4 {
  right: 16px;
}
.top-2 {
  top: 8px;
}
.color-yellow {
  color: #ffad00;
}
.bg-gray {
  background-color: #f5f5f5;
}
.semibold {
  font-weight: 500;
}
.pl-1 {
  padding-left: 4px;
}
.uppercase {
  text-transform: uppercase;
}
.f-10 {
  font-size: 10px;
}
.pointer {
  cursor: pointer;
}
