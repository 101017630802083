.inspect-panel-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: -10px;
  margin-right: -10px;
}

.inspect-panel-row + .inspect-panel-row {
  margin-top: 5px;
}

.inspect-panel-row-title {
  white-space: nowrap;
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inspect-panel-row-labeled {
  padding-bottom: 0;
}

.inspect-panel-row-labeled .inspect-panel-row-title {
  padding-bottom: 14px;
}

.inspect-panel-row-body {
  flex: 1;
  display: flex;
  position: relative;
}

.inspect-panel-row-body > * {
  margin-left: 12px;
  flex: 1;
}

.inspect-panel-row-body > :first-child {
  margin-left: 0;
}

.inspect-panel-row-body > .boolean-control:not(.boolean-control-standalone) {
  position: absolute;
  left: -20px;
  height: 100%;
}

.inspect-panel-row-body
  > .boolean-control:not(.boolean-control-standalone)
  + * {
  margin-left: 0;
}

.inspect-panel-row-body .text-control {
  width: 100%;
}

.inspect-row-columns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inspect-row-column {
  width: 50%;
  padding: 0 8px 0 0;
}

.inspect-row-column + .inspect-row-column {
  padding: 0 0 0 8px;
}


.text-control-sub {
  position: relative;
}

.text-control-sub .input-container {
  width: 100%;
  height: 100%;
  input {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

.text-control-sub .input-container.disabled {
  opacity: 0.5;
  pointer-events: none;
  caret-color: transparent;
}

.text-control-sub .input-container.dirty input {
  border-color: #00a898
}

.text-control-sub .toggle {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  display: flex;
  flex-direction: column;
  width: 32px;
  padding: 7px;
  background-color: white;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.text-control .incrementer {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  display: flex;
  flex-direction: column;
  width: 14px;
  opacity: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  background: #fff;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.text-control .incrementer.hasToggle {
  right: 31px;
}

.text-control-gray .incrementer {
  background: #e5e5e5;
  transition: opacity 0.2s;
}

.text-control:hover .incrementer {
  opacity: 1;
}

.text-control .incrementer-btn {
  flex: 1;
  display: flex;
  opacity: 0.5;
  cursor: pointer;
  padding-right: 2px;
}

.text-control .incrementer-btn:first-child {
  padding-top: 4px;
}

.text-control .incrementer-btn:last-child {
  padding-bottom: 4px;
}

.text-control .incrementer-btn .icon {
  width: auto;
  height: auto;
  flex: 1;
}

.text-control .incrementer-btn:hover {
  opacity: 0.8;
}

.text-control .incrementer-btn:active {
  background: rgba(0, 0, 0, 0.05);
}

.text-control input,
.text-control label,
.inspect-panel-row-body label {
  display: block;
  width: 100%;
}

.text-control input {
  font: inherit;
  padding: 3px 6px;
  height: 26px;
  outline: none;
  border: none;
  background: transparent;
  text-align: left;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 4px;
}

.text-control-gray input {
  background: #f1f1f1;
  border: none;
  transition: background 0.2s;
}

.text-control-gray:hover input,
.text-control-gray input:focus {
  background: #e5e5e5;
}

.text-control input:focus {
  border-color: #bbb;
}

.text-control label,
.inspect-panel-row-body label {
  font-size: 10px;
  margin-top: 2px;
  line-height: 14px;
  text-align: center;
}

.text-control.text-control-variant-slim {
  height: 37px;
  font-size: 12px;
  width: 81px;
}

.text-control.text-control-variant-slim input {
  height: 37px;
  font-size: 12px;
  width: 81px;
}

.text-control.text-control-variant-advanced input {
  border-radius: 4px 0 0 4px;
}

.text-control.text-control-font-size-small input {
  font-size: 12px;
}

.text-control-click-action {
  cursor: pointer;
}

.text-control-click-action .text-control-sub .input-container.disabled {
  pointer-events: auto;
}

.text-control-click-action .text-control-sub .input-container.disabled input {
  cursor: pointer;
}
 
/* TODO: REMOVE THIS */
.inspect-panel-bind-wrapper {
  width: 24px;
  margin-left: 15px;
  display: none;
}

.inspect-panel-row-bind {
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  cursor: pointer;
}

.inspect-panel-row-bind .icon {
  background: #444;
  opacity: 0.5;
}

.inspect-panel-row-bind:hover {
  background: rgba(0, 0, 0, 0.03);
}

.inspect-panel-row-bind.bound {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 0.5px rgba(0, 0, 0, 0.1);
}

.convert-object-type {
  margin-right: -6px;
}

.single-number-control.with-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.single-number-control-label {
  font-size: 10px;
  display: block;
  text-align: center;
}

.single-number-control__title {
  color: #333;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  margin-left: 14px;
  text-wrap: nowrap;
}

.boolean-control {
  flex: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inspect-panel-row-labeled .boolean-control {
  padding-bottom: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.border-control-split {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px -16px 0;
}

.border-control-split--grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.border-control-split--grid .color-dropdown-inner-wrapper {
  height: 44px;
}

.border-control-split--grid .color-dropdown {
  margin-top: 0;
}

/**
 * All border controls, aiming at 50px height
 */
.border-control-split > * {
  flex: 1;
  min-height: 50px;
}

.border-control-split > * + * {
  margin-left: 15px;
}

/**
 * Height composed of 30px of vertical padding and 20px of line-height
 */
.background-control .color-dropdown .color-dropdown-inner-wrapper,
.border-control .color-dropdown .color-dropdown-inner-wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
}

.background-control .color-dropdown-title,
.border-control .color-dropdown-title {
  line-height: 20px;
}

.new-image-control .multi-select-menu,
.default-date-control .multi-select-menu,
.input-type-control .multi-select-menu,
.border-control .multi-select-menu {
  height: 44px;
}

.new-image-control .multi-select-menu .multi-select-menu-selection,
.default-date-control .multi-select-menu .multi-select-menu-selection,
.input-type-control .multi-select-menu .multi-select-menu-selection,
.border-control .multi-select-menu-selection {
  background: #f1f1f1;
  box-shadow: unset !important;
  transition: background 0.2s;
}

.new-image-control .multi-select-menu-selection:hover,
.default-date-control .multi-select-menu-selection:hover,
.input-type-control .multi-select-menu-selection:hover,
.border-control .multi-select-menu-selection:hover {
  background: #e5e5e5 !important;
}

.new-image-control .expanded .multi-select-menu-selection,
.default-select-control .expanded .multi-select-menu-selection,
.shared-layout-settings .multi-select-menu-selection:hover {
  background: #ffffff !important;
}

.new-image-control .multi-select-menu-selection,
.default-date-control .multi-select-menu-selection,
.input-type-control .multi-select-menu-selection,
.border-control .multi-select-menu-selection {
  line-height: 24px;
  padding-left: 16px;
}

.radius-control .inspect-generic-row-body {
  display: flex;
  align-items: center;
  margin: 5px -16px 0 -16px;
}

.layout-section-props .inspect-generic-row-body .color-dropdown {
  margin-top: 0;
}

.default-shadow-control .inspect-generic-row-body {
  display: flex;
  justify-content: space-between;
  margin: 5px -16px 0 -16px;
  gap: 8px;
}

.default-shadow-control .inspect-generic-row-body .text-control label {
  margin-bottom: 5px;
  margin-top: 0;
}

.default-shadow-control .inspect-generic-row-body .text-control input {
  width: 50px;
  text-align: center;
  padding: 0;
}

.default-shadow-control .inspect-generic-row-body .color-dropdown {
  margin: 0;
  height: 44px;
}

.default-shadow-control .inspect-generic-row-body .color-dropdown .color-dropdown-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

.default-font-control .inspect-generic-row-body {
  margin: 5px -16px 0 -16px;
  gap: 8px;
  height: fit-content;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.default-font-control .inspect-generic-row-body .library-inspect-menu-control {
  margin: 0;
  flex: 1; 
}

.default-font-control .inspect-generic-row-body .library-inspect-menu-control p {
  margin: 0 0 5px 16px;
}

.default-font-control .inspect-generic-row-body .library-inspect-menu-control .multi-select-menu {
  margin: 0;
  flex: 1;
}

.border-control-grid .inspect-generic-row-body {
  margin: 5px -16px 0 -16px;
  gap: 8px;
  height: fit-content;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.border-control-grid .inspect-generic-row-body .library-inspect-menu-control {
  margin: 0;
  flex: 1; 
}

.border-control-grid .inspect-generic-row-body .library-inspect-menu-control .multi-select-menu {
  margin: 0;
}

.border-control-grid .inspect-generic-row-body .library-inspect-menu-control .multi-select-menu .multi-select-menu-value {
  text-overflow: unset;
}

.border-control-grid .inspect-generic-row-body .text-control {
  width: 50px;
  text-align: center;
  padding: 0;
  height: 100%;
}

.border-control-grid .inspect-generic-row-body .text-control label {
  font-size: 12px;
}

.default-font-control .inspect-generic-row-body .text-control {
  height: fit-content;
}

.default-font-control .inspect-generic-row-body .text-control label {
  margin: 0 0 5px 16px; 
  font-size: 12px;
  text-align: left;
}

.default-font-control .inspect-generic-row-body .color-dropdown__wrapper {
  flex: 1;
}

.default-font-control .inspect-generic-row-body .color-dropdown {
  margin: 0;
  height: 44px;
}

.default-font-control .inspect-generic-row-body .color-dropdown .color-dropdown-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
}

.default-size-control .inspect-generic-row-body {
  margin: 5px -16px 0 -16px;
  gap: 8px;
  height: fit-content;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.default-size-control .inspect-generic-row-body .text-control {
  height: fit-content;
}

.default-size-control .inspect-generic-row-body .text-control label {
  margin: 0 0 5px 16px; 
  font-size: 12px;
  text-align: left;
}

.default-padding-control .inspect-generic-row-body {
  margin: 6px -16px 0 -16px;
}

.radius-control__background {
  flex: 1;
  background-color: #efefef;
  padding: 10px 4px;
  border-radius: 4px;
}

.radius-control__background .slider .slider-track {
  background-color: #dedede;
} 

.radius-control .slider {
  flex: 1;
  margin: 0 16px;
}
.radius-control .slider-knob {
  background-color: #fff;
  width: 20px;
  height: 20px;
  top: 2px;
}

.radius-control .slider-track {
  top: calc(50% - 3px);
  background-color: rgb(235, 235, 235);
  border-radius: 3px;
  height: 6px;
  border-bottom: none;
}
.radius-control .text-control {
  /* flex: 1; */
  margin-left: 8px;
}

.radius-control .text-control input {
  text-align: center;
  padding: 0 !important;
}

.radius-control:not(.independent) .text-control input {
  width: 50px;
}

.radius-control.independent {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.radius-control.independent .inspect-generic-row-title {
  font-size: 14px;
}

.radius-control-advanced-menu.disabled {
  pointer-events: none;
  opacity: 0.5;
}


.radius-control-advanced-menu .icon-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  background-color: #f1f1f1;
  width: 27px;
  height: 44px;
  max-height: 44px;
  border-radius: 0 6px 6px 0;
}

.radius-control-advanced-menu .icon-button-wrapper:hover {
  background-color: #e7e7e7;
}

.radius-control.independent > .inspect-generic-row-body {
  flex-direction: column;
  justify-content: space-around;
  gap: 15px;
  display: grid;
  grid-template-columns: auto auto;
}

.radius-control.independent > .inspect-generic-row-title .icon-button-wrapper {
  padding: 8px;
  width: 32px;
  height: 32px;
}

.radius-control.independent > .inspect-generic-row-body > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.background-image-control .image-control {
  margin: 0 -16px;
}

.background-image-control .image-control .image-input-wrapper, 
.background-image-control .image-control .image-input {
  width: 100%;
}


.control-item {
  display: flex;
  gap: 10;
  align-items: center;
}

.input-type-control .inspect-generic-row-body {
  margin: 5px -16px 0 -16px;
}

.new-image-control .image-input-wrapper,
.library-image-control .image-input-wrapper,
.library-video-control .image-input-wrapper {
  width: 100%;
  margin: 15px -16px 0 -16px;
}

.new-image-control .image-input,
.library-image-control .image-input,
.library-video-control .image-input {
  width: calc(100% + 32px);
  height: 300px;
}

.new-image-control .multi-select-menu {
  margin: 5px -16px 0 -16px;
}

.multi-selection-control {
  display: flex;
  flex-direction: column;
}

.multi-selection-control > .btn {
  margin: 5px -16px 0 -16px;
}

.multi-selection-control > .btn.btn-outlined {
  border-color: #00a998;
  color: #00a998;
}

.multi-selection-control > .btn.btn-outlined:hover,
.multi-selection-control > .btn.btn-outlined:focus {
  background-color: rgba(0, 169, 152, 0.1);
}

.multi-selection-control > .btn .material-icons {
  width: 24px;
}

.multi-selection-control > .btn + .btn {
  margin-top: 10px;
}

.group-child-component {
  margin: 0 -16px;
  padding: 9px 16px;
  border-radius: 6px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all;
}

.group-child-component:hover {
  background-color: #e5e5e5;
}

.group-child-component > :not(.material-icons) {
  flex: 1;
}

.group-child-component > .material-icons {
  font-size: 18px;
}

.group-child-component + .group-child-component {
  margin-top: 10px;
}

.group-child-component-type {
  font-size: 11px;
  color: rgb(158, 158, 158);
}

.group-child-component-name {
  color: rgb(66, 66, 66);
  font-size: 14px;
}

.group-child-component-unlicensed {
  border: 2px solid #E52222;
}

.list-control-add-wrapper {
  width: calc(100% + 32px);
  margin: 5px -16px 0 -16px;
}

.list-control-add {
  display: flex;
  justify-content: left;
  align-items: center;
  color: rgb(0, 168, 152) !important;
  border: none !important;
  padding-left: 16px;
}

.list-control-add .icon {
  background-color: rgb(0, 168, 152) !important;
}

.list-control-add:hover {
  background-color: #e5f6f5 !important;
}
