@import 'styles/_colors.scss';

@mixin color($color) {
  &.toggle-button-enabled .toggle-button-ball {
    background-color: $color;
  }
}

.toggle {
  display: flex;
  gap: 8px;
  align-items: center;

  label {
    color: $labelDark;
    cursor: pointer;
  }

  &-button {
    width: 30px;
    height: 16px;
    position: relative;
    cursor: pointer;

    &-track {
      height: 8px;
      border-radius: 4px;
      margin-top: -4px;
      position: absolute;
      top: 50%;
      left: 2px;
      right: 2px;
      background: #ddd;
      transition: all 0.2s;
    }

    &-ball {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -6px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
      transition: all 0.2s;
    }

    &-enabled {
      .toggle-button-ball {
        background: $teal;
        left: 18px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }

    &-wrapped {
      width: 35px;
      height: 18px;
      border-radius: 10px;
      background-color: #ddd;
      transition: all 0.2s;

      .toggle-button-ball {
        left: 5px;
      }

      &.toggle-button-enabled {
        background-color: #13b597;

        .toggle-button-track {
          background: #13b597;
        }

        .toggle-button-ball {
          background: $white;
          left: 18px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
      }
    }

    &-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    // colors
    &-yellow {
      @include color($yellow);
    }
  }
}
