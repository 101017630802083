@keyframes left-nav-fade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.editor-left-nav {
  position: fixed;
  left: 0;
  top: 64px;
  transition: top 0.2s;
  bottom: 0;
  z-index: 1000;
  width: 71px;
  border-right: 1px solid #eee;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px 0 4px;
}

.editor-app-unpaid .editor-left-nav {
  padding-bottom: 48px;
} 

.offline-banner-visible .editor-left-nav {
  top: 96px;
}

.editor-left-nav-item {
  width: 70px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.editor-left-nav-item-versions .editor-left-nav-tooltip, 
.editor-left-nav-item-deep-linking .editor-left-nav-tooltip,
.editor-left-nav-item-experts .editor-left-nav-tooltip  { 
  width: max-content;
}



.editor-left-nav-item-sub {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 20px;
  background: transparent;
  transition: background 0.5s;
}

.editor-left-nav-item .icon {
  background: #616161;
  transition: background 0.1s;
}

.editor-left-nav-item:hover .editor-left-nav-item-sub,
.editor-left-nav-item.active .editor-left-nav-item-sub {
  background-color: #f1f1f1;
}

.editor-left-nav-item:hover .icon,
.editor-left-nav-item.active .icon {
  background: #333;
}

.editor-left-nav-add-button {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 6px;
}

.editor-left-nav-add-button-sub {
  width: 53px;
  height: 53px;
  background: url('./add-button.svg') no-repeat center;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  transition: box-shadow 0.1s;
}

.editor-left-nav-add-button:hover .editor-left-nav-add-button-sub,
.editor-left-nav-add-button.active .editor-left-nav-add-button-sub {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -2px,
              rgba(0, 0, 0, 0.14) 0px 7px 10px 1px,
              rgba(0, 0, 0, 0.12) 0px 2px 16px 1px;
}

.editor-left-nav-tooltip {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  left: 100%;
  margin-left: 2px;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  border-radius: 4px;
  background: #333;
  color: #fff;
  font-weight: 500;
  display: none;
}

.editor-left-nav-item:hover .editor-left-nav-tooltip {
  display: block;
  animation: left-nav-fade 0.2s 1;
}

/* Branding */

.editor-left-nav-item-branding:hover .editor-left-nav-item-sub {
  background-color: rgba(168, 32, 88, 0.1);
}

.editor-left-nav-item-branding.active .editor-left-nav-item-sub {
  background-color: rgba(168, 32, 88, 0.15);
}

.editor-left-nav-item-branding:hover .icon,
.editor-left-nav-item-branding.active .icon {
  background-color: #a82058
}

/* Screens */

.editor-left-nav-item-screens:hover .editor-left-nav-item-sub {
  background-color: rgba(0, 168, 152, 0.1);
}

.editor-left-nav-item-screens.active .editor-left-nav-item-sub {
  background-color: rgba(0, 168, 152, 0.15);
}

.editor-left-nav-item-screens:hover .icon,
.editor-left-nav-item-screens.active .icon {
  background-color: #00a898
}

/* Data */

.editor-left-nav-item-data:hover .editor-left-nav-item-sub {
  background-color: rgb(239, 74, 48, 0.1);
}

.editor-left-nav-item-data.active .editor-left-nav-item-sub {
  background-color: rgb(239, 74, 48, 0.15);
}

.editor-left-nav-item-data:hover .icon,
.editor-left-nav-item-data.active .icon {
  background-color: #ef4a30;
}

/* Settings */

.editor-left-nav-item-settings:hover .editor-left-nav-item-sub {
  background-color: rgb(67, 67, 122, 0.1);
}

.editor-left-nav-item-settings.active .editor-left-nav-item-sub {
  background-color: rgb(67, 67, 122, 0.15);
}

.editor-left-nav-item-settings:hover .icon,
.editor-left-nav-item-settings.active .icon {
  background-color: #43437a;
}

/* Deep Link */
.editor-left-nav-item-deep-linking:hover .editor-left-nav-item-sub {
  background-color:rgba(40, 145, 202, 0.1)
}

.editor-left-nav-item-deep-linking.active .editor-left-nav-item-sub {
  background-color: rgb(40,146,202, 0.15);
}

.editor-left-nav-item-deep-linking:hover .icon,
.editor-left-nav-item-deep-linking.active .icon {
  background-color:rgb(40,146,202)
}

/* Publish */

.editor-left-nav-item-publish:hover .editor-left-nav-item-sub {
  background-color: rgb(161, 205, 70, 0.1);
}

.editor-left-nav-item-publish.active .editor-left-nav-item-sub {
  background-color: rgb(161, 205, 70, 0.15);
}

.editor-left-nav-item-publish:hover .icon,
.editor-left-nav-item-publish.active .icon {
  background-color: #a1cd46;
}


/* Analytics */

.editor-left-nav-item-analytics:hover .editor-left-nav-item-sub {
  background-color: rgb(239, 74, 48, 0.1);
}

.editor-left-nav-item-analytics.active .editor-left-nav-item-sub {
  background-color: rgb(239, 74, 48, 0.15);
}

.editor-left-nav-item-analytics:hover .icon,
.editor-left-nav-item-analytics.active .icon {
  background-color: rgb(168, 32, 88);
}

/* Versions */

.editor-left-nav-item-versions:hover .editor-left-nav-item-sub {
  background-color: rgba(0, 168, 152, 0.1);
}

.editor-left-nav-item-versions.active .editor-left-nav-item-sub {
  background-color: rgba(0, 168, 152, 0.15);
}

.editor-left-nav-item-versions:hover .icon,
.editor-left-nav-item-versions.active .icon {
  background-color: #00a898
}

/* Experts */

.editor-left-nav-item-experts {
  margin-top: auto;
}

.editor-left-nav-item-experts:hover .editor-left-nav-item-sub {
  background-color: rgba(0, 168, 152, 0.1);
}

.editor-left-nav-item-experts.active .editor-left-nav-item-sub {
  background-color: rgba(0, 168, 152, 0.15);
}

.editor-left-nav-item-experts:hover .icon,
.editor-left-nav-item-experts.active .icon {
  background-color: #00a898
}
