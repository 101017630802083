@import 'styles/_colors.scss';

.summary {
  position: relative;

  border: 1px solid $darkGrey;
  border-radius: 12px;

  padding: 0;
  width: 330px;
  height: fit-content;

  &-divided {
    > .summary-header {
      border-bottom: 1px solid $darkGrey;
    }
    > .summary-footer {
      border-top: 1px solid $darkGrey;
    }
  }

  &-background-color {
    &-green {
      background-color: #e5f6f4;
    }
  }

  > * {
    padding: 14px 20px;
  }

  &-content {
    display: flex;

    font-size: 14px;

    > * {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 8px;

      :last-child {
        position: relative;
        display: block;
        margin-left: auto;
      }

      span {
        font-weight: 500;
      }

      strong {
        font-weight: 700;
        font-size: 16px;
      }

      img {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        border: 1px solid $darkGrey;
      }
    }
  }

  &-row {
    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > * {
        margin-right: 8px;

        &:last-child {
          margin-left: auto;
          margin-right: 0;
        }
      }

      img {
        border-radius: 50%;
        height: 24px;
        width: 24px;
      }
    }

    &-color {
      &-light-green {
        background-color: #e5f6f4;
      }
    }
  }

  &-header {
    & > * {
      margin: 0;
    }

    h2 {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &-footer {
    font-size: 12px;
    font-weight: 500;
    color: #424242;

    a {
      font-weight: 600;
      color: #424242;
    }
  }

  &-no-border {
    border: none;
  }

  &-error-message {
    position: absolute;
    top: calc(100% + 14px);
    width: 100%;
    padding: 8px 14px;
    background-color: $orange;
    color: white;
    border-radius: 6px;
    text-align: center;
  }
}
