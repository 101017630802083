.library-inspect-boolean-binding {
  padding: 2px 4px 2px 10px;
  color: #fff;
  background: #00a898;
  flex: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
}

.library-inspect-boolean-binding .icon {
  background: #fff;
  cursor: pointer;
}

.library-inspect-boolean-add-binding {
  color: #8a8a8a;
  margin-left: 10px;
}

.boolean-control-boxed {
  background-color: white;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;

  &:hover,
  > * {
    cursor: pointer;
  }
}
