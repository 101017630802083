@import 'styles/_colors.scss';

.settings-modal {
  width: 850px;
  padding: 0;

  max-height: calc(100vh - 80px);
  padding: 0;

  > div,
  > form {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 35px 40px 0px;
  }

  .modal-content {
    flex: 1;
    overflow: auto;
  }
}

.settings-page-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: -35px -40px 0;
  padding: 32px 40px 16px;
  border-bottom: 1px solid #e9e9e9;

  > * {
    margin: 0 8px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  h1 {
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 2px;

    font-size: 19px;
    font-weight: 500;
  }

  p {
    color: #8a8a8a;
    font-weight: 500;
  }

  .icon-button-wrapper {
    height: 40px;
    width: 40px;
    max-height: 40px;
    max-width: 40px;
    padding: 8px;
  }

  &.modal-header-stepper {
    display: block;
  }
}

.settings-page-header-title {
  margin-left: 10px;
}

.settings-page-close-button {
  position: absolute;
  top: 28px;
  right: 22px;
}

.settings-page-sidebar {
  min-width: 160px;
  margin-left: -12px;
  margin-top: 26px;
  margin-bottom: 40px;
}

.settings-page-tab {
  color: #616161;
  font-weight: 400;
  text-decoration: none;
  font-size: 16px;

  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px 0px 10px 24px;
  margin-left: -10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: visible;

  &:hover:not(.active) {
    background-color: $lightTeal;
    color: $teal;
    cursor: pointer;

    .icon {
      background-color: $teal;
    }
  }

  &.active {
    background-color: $lightTeal;
    color: $teal;
    font-weight: 500;
  }
}

.settings-vertical-line {
  width: 1px;
  border-right: 1px solid #e9e9e9;
  margin: -40px 0;
}

.settings-page-content {
  margin-right: -40px;
  padding: 40px 50px;
  width: 100%;
  justify-content: center;
  align-content: flex-start;
  height: 100%;
  background: #fcfcfc;

  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.settings-page-content::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.settings-header {
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1em;
}

.settings-subheader {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.settings-section-separation {
  margin-bottom: 25px;
}

.settings-content-box {
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 30px 25px;
  display: block;
  outline: none;
  border-radius: 5px;
  flex: 1 1;
  margin-bottom: 40px;

  .settings-content-row {
    margin-bottom: 25px;
  }

  .settings-content-row:last-of-type {
    margin-bottom: 0;
  }

  h4 {
    margin-bottom: 4px;
    font-size: 15px;
    font-weight: 500;
  }

  hr {
    margin: 0 -25px;
    margin-bottom: 30px;
    border: #e8e8e8;
    background-color: #e8e8e8;
    height: 1px;
  }
}

.shaded-content-box {
  border: 1px solid #e9e9e9;
  background: rgba(233, 233, 233, 0.2);
  padding: 15px 18px;
  display: block;
  outline: none;
  border-radius: 5px;
  align-items: center;
  margin: 0;

  > *:not(:last-child) {
    margin-bottom: 5px;
  }

  .btn {
    margin-top: 20px;
  }
}

.settings-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e9e9e9;
  border-top: unset;
  background: #fff;
  padding: 20px;
  outline: none;
  flex: 1 1;

  h4 {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
  }

  p {
    margin: 0;
    color: #8a8a8a;
  }
}

.settings-list-item:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #e9e9e9;
}
.settings-list-item:last-of-type {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

a.settings-link-button {
  color: #00a898;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  padding: 0.2em;
  margin: 0 0.3em;
}

a.settings-link-button:hover {
  background: rgba(0, 168, 152, 0.2);
}
