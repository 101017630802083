.modal-header .extend-trial-header {
  display: flex;
  flex-direction: column;
  margin-top: -5px;
  margin-left: 26px;

  .free-trial-extend-title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 5px;
    color: #333333;
  }
}

.free-trial-extend-subtitle {
  font-weight: 500;
  font-size: 12px;
  color: #757575;
}

.free-trial-extend-body {
  font-size: 14;
  font-weight: 500;
  text-align: left;
  width: 678px;
  margin-bottom: 10px;
}

.modal .modal-content .free-trial-extend {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
}

.premium-feature-grid {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.premium-feature-column {
  margin: 0;
}

.premium-feature-option {
  width: 334px;
  height: 54px;
  margin-bottom: 10px;
  margin-right: 0px;
  border-radius: 8px;
  justify-content: space-between;
  padding: 0 20px;
}

.premium-feature-option-text {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-transform: capitalize;
}

.free-trial-extend-success-image {
  width: 25%;
  padding: 20px 20px;
  margin-top: 16px;
  margin-bottom: 11px;
}

.success-trial-pack-container .free-trial-extend-success-title {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  line-height: 21px;
  text-align: center;
  margin-bottom: 24px;
}

.success-trial-pack-container .free-trial-extend-success-text {
  width: 783px;
  font-size: 12px;
  font-weight: 500;
  color: #757575;
  line-height: 14px;
  text-align: center;
}

div [data-adalo-id='extend-trial-most-important-feature'] {
  visibility: hidden;
  height: 0px;
}
