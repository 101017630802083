@import 'styles/_colors.scss';

.bindable-text-control {
}

.bindable-text-control-trigger-wrapper {
  margin-top: -8px;
  margin-bottom: -8px;
}

.bindable-text-control-trigger {
  color: #8a8a8a;
}

.bindable-text-control-trigger:hover {
  color: #666;
}

.bindable-text-control-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bindable-text-control-entity-label {
  background: #ef4c30;
  color: #fff;
  padding: 2px 3px 2px 10px;
  border-radius: 20px;
  margin: 4px 0;
  display: block;
  cursor: pointer;
  display: flex;
  align-items: center;
  word-break: break-all;

  &--icon-disabled {
    padding-right: 10px;
  }

  &--chip-disabled {
    cursor: default;

    .icon.icon-close {
      cursor: pointer;
    }
  }
}

.bindable-text-control-entity-label .icon {
  background: #fff;
  margin-left: 4px;
}

.bindable-text-control-trigger {
  color: #8a8a8a;
  cursor: pointer;
}

.entity-textarea-chip:hover .bindable-text-control-entity-label,
.entity-textarea-chip:focus .bindable-text-control-entity-label {
  background: #d03f26;
}

.entity-textarea-chip:hover .bindable-text-control-entity-label--chip-disabled,
.entity-textarea-chip:focus .bindable-text-control-entity-label--chip-disabled {
  background: #ef4c30;
}

.bindable-text-control .wrapped-entity-textarea {
  padding: 12px 16px;
  margin: 0 -16px;
  background: #f1f1f1;
  border-radius: 6px;
  font-size: 14px;
}

.text-editor-sub .text-editor-input-backdrop {
  background: transparent;
}
.text-editor-sub .bindable-text-control {
  margin-top: -10px;
}

.text-editor-sub .bindable-text-control .wrapped-entity-textarea {
  background: transparent;
  font-size: inherit;
  outline: 1px solid #ffc100;
  border-radius: 0px;
  margin: 0;
  padding: 0 !important;
}

.text-editor-sub .bindable-text-control-entity-label {
  padding-right: 10px;
}

.text-editor-sub .entity-textarea-block {
  min-height: 1.1875em;
  line-height: inherit;
  padding: 0;
  margin: 0;
}

.bindable-text-control .entity-textarea-segment {
  background: transparent;
  padding: 0;
}

.bindable-text-control-menu .multi-menu-item-label {
  padding-left: 20px;
}

.multi-menu-trigger.expanded .bindable-text-control-trigger-icon {
  background: #ef4c30;
}

.multi-menu-trigger.expanded .bindable-text-control-trigger-icon .icon {
  background: #fff;
}

.entity-textarea-block {
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 15px;
}

.bindable-text-control-error-message {
  color: #f44;
  font-weight: 500;
  margin: 5px 16px 0;
}

.bindable-text-control-api {
  margin: 0 16px;

  .entity-textarea-api {
    background: unset;
    border: 1px solid $darkGrey;
  }
}
