@import 'styles/_colors.scss';

.modal .modal-actions {
  .custom-action-warning {
    margin-right: auto;
  }
}

.modal .modal-content {
  .custom-action-warning-text {
    margin: 50px 0px;
    p {
      font-size: 16px;
    }
  }
}

.modal .modal-header {
  .delete-warning-header {
    display: flex;
    padding-bottom: 16px;
    padding-left: 20px;
    align-items: flex-end;
    .icon {
      height: 30px;
      width: 30px;
    }
    p {
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
    }
  }
}

.custom-action-first-step {
  max-width: 600px;
  margin-left: 140px;

  .custom-action-type {
    margin: 25px 0;
    position: relative;
    padding-bottom: 1px;

    label {
      display: inline-block;
      font-size: 12px;
      margin-left: 16px;
      margin-bottom: 5px;
      color: $label;
    }

    .multi-select-menu {
      height: 52px;
      .multi-menu-trigger-element {
        .multi-select-menu-selection {
          border-radius: 9px;
          height: 52px;
          padding: 16px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .multi-select-menu-value {
            .multi-select-menu-placeholder {
              font-size: 14px;
              color: #b1b8cb;
            }
          }

          .multi-select-menu-expand-icon {
            &:before {
              right: 16px;
            }
          }
        }
      }
    }
  }
}

.api-request {
  display: flex;
  flex-direction: row;

  .bindable-text-control-entity-label {
    padding-right: 10px;
  }

  .api-request-headers {
    margin: 25px 0;
    .data-add-api-auth {
      label {
        display: inline-block;
        font-size: 12px;
        margin-left: 16px;
        margin-bottom: 10px;
        color: $label;
      }
    }
  }

  .api-request-values {
    padding-right: 32px;
    flex: 2;
    border-right: 1px solid #f1f1f1;
  }

  .api-request-inputs {
    padding-left: 32px;
    flex: 1;
    .data-add-api-auth {
      margin-top: 0;
      label {
        display: inline-block;
        font-size: 12px;
        margin-left: 16px;
        margin-bottom: 10px;
        color: $label;
      }
    }
  }

  .api-request-url {
    .bindable-text-control {
      .bindable-text-control-error {
        border-color: #f44 !important;
        box-shadow: inset 0 0 0 1px #f00;
      }
    }

    .settings-form-error {
      color: #f44;
      font-weight: 500;
      margin: 5px 16px 0;
    }
    .bindable-text-control {
      margin: 0 16px;
      .bindable-text-control-header {
        p {
          color: $label;
        }
      }
    }
  }

  .api-request-body {
    .bindable-text-control {
      margin: 0 16px;
      .bindable-text-control-header {
        p {
          color: $label;
        }
      }
      .bindable-text-control-error {
        border-color: #f44 !important;
        box-shadow: inset 0 0 0 1px #f00;
      }
    }
  }

  .api-request-method {
    margin: 25px 0;
    position: relative;
    padding-bottom: 1px;

    label {
      display: inline-block;
      font-size: 12px;
      margin-left: 16px;
      margin-bottom: 10px;
      color: $label;
    }

    .multi-select-menu {
      height: 52px;
      .multi-menu-trigger-element {
        .multi-select-menu-selection {
          background: #f1f1f1;
          border-radius: 6px;
          height: 52px;
          padding: 16px;
          box-shadow: none;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .multi-select-menu-value {
            .multi-select-menu-placeholder {
              font-size: 14px;
              color: #b1b8cb;
            }
          }

          .multi-select-menu-expand-icon {
            &:before {
              right: 16px;
            }
          }
        }
      }
      &.wrapped-select.wrapped-select-error .multi-select-menu-selection {
        box-shadow: inset 0 0 0 1px #f00;
      }
      &.wrapped-select-error-message {
        color: #f44;
        font-weight: 500;
        margin: 50px 16px;
      }
    }
  }
}

.custom-action-type-option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1px;
}

.custom-action-type-option span {
  margin: 0 16px 0 0;
}
