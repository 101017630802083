.api-collection-auth {
  margin-bottom: 20px;
}

.api-collection-auth h3 {
  color: #8a8a8a;
}

.api-collection-auth-item {
  margin-bottom: 20px;
}

.api-collection-auth-item label {
  margin-bottom: 3px;
  display: block;
}

.api-collection-auth-item .wrapped-select {
  max-width: 300px;
}
