@import 'styles/_colors.scss';

.feature-item {
  &__details {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .modal-header {
      padding: 16px 24px;
    }

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      padding: 1.5rem 80px 40px;
      flex: 1;
      overflow-y: scroll;
      max-height: calc(100vh - 200px);

      > .loading {
        margin: 32px auto 0;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      gap: 12px;

      background-color: white;
      z-index: 1;

      position: sticky;
      top: 0;

      &-info {
        display: flex;
        gap: 12px;
        align-items: center;

        > img {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          border: 1px solid $lightGrey;
          align-self: flex-start;
        }

        h2 {
          display: flex;
          flex-direction: column;
          gap: 6px;

          font-size: 24px;
          font-weight: 600;
          margin: 0;
        }

        span {
          font-weight: 500;
          font-size: 12px;
          color: $filterLabel;
        }
      }

      &-price {
        display: flex;
        align-items: center;
        gap: 20px;

        button {
          text-wrap: nowrap;
        }
      }
    }

    &-banner {
      position: relative;
      display: flex;
      place-items: center;
      border-radius: 6px;
      padding-bottom: calc(72%);
      height: 100%;
      width: 100%;

      iframe {
        border: 0;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color-scheme: light;
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      gap: 32px;
      font-size: 14px;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      &-details {
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: 100%;
        line-height: 20px;

        ul,
        ol {
          padding-left: 1.5rem;
        }
      }

      &-meta {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &-item,
        &-item > a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          font-size: 14px;
          font-weight: 500;
          color: $black;

          .icon {
            height: 16px;
            width: 16px;
            background: $black;
          }
        }

        &-item > a {
          color: $teal;
          text-decoration: none;
          font-weight: 600;

          .icon {
            background: $teal;
          }
        }
      }

      &-emoji {
        margin-right: 12px;
      }
    }
  }

  &__collections {
    .modal-content {
      max-width: 75%;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      gap: 40px;

      p {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }

      .include-sample-data {
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 5px -16px 0 -16px;
        padding: 12px 16px;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        border: 1px solid rgb(238, 238, 238);
        border-radius: 6px;

        p {
          font-size: 14px;
        }
      }
    }

    .modal-header {
      h1 {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: $label;
      }
    }
  }
}

.selected-collection-properties {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;

  &__tooltip {
    ul {
      padding-left: 1rem;
    }
  }
}
