.color-stripe {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.color-stripe .color-stripe-bar {
  flex: 1;
}
