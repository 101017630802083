.canvas {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  user-select: none;
}

.canvas .empty-state h1 {
  margin-bottom: 0.5em;
}

.canvas-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.canvas-objects {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  transform-origin: left top;
}

.canvas-objects,
.canvas-objects text {
  font-family: -apple-system, 'SF Pro Text', 'Helvetica Neue', sans-serif;
}

.canvas-objects > g {
  pointer-events: all;
}

.bounding-boxes,
.canvas-links {
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  overflow: visible;
}

.canvas-links {
  z-index: 0;
}

.marquee {
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  border: 2px solid #ddd;
  background: rgba(200, 200, 200, 0.3);
}

.text-editor-backdrop,
.text-editor {
  position: fixed;
  z-index: 10;
  transform-origin: left top;
  top: 0;
  left: 0;
}

.text-editor-sub {
  position: absolute;
}

.text-editor-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.text-editor textarea {
  background: transparent;
  color: #000;
  outline: none;
  border: none;
  font: inherit;
  resize: none;
  position: relative;
  z-index: 1;
  line-height: 1.15em;
}

.text-editor-input-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  height: 1.15em;
}

.page-background {
  fill: #fff;
  pointer-events: none;
}

.page-shadow {
  position: fixed;
  pointer-events: none;
  z-index: 1;
  left: 0;
  top: 0;
  transform-origin: 0 0;
}

.page-title-wrapper {
  position: fixed;
  pointer-events: none;
  z-index: 1;
}

.page-title-wrapper-transparent {
  opacity: 0.1;
}

.canvas-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 431px;
  right: 0;
  top: 0;
  bottom: 0;
}

.device-icon {
  display: flex;
  background-color: #9e9e9e;
  cursor: pointer;
  pointer-events: all;
  padding: 2px 7px;
  align-items: center;
}

.responsive-header-container.medium-chip .device-icon {
  width: 27px;
}

.device-icon:hover {
  background-color: #757575;
}

.responsive-screen-selector.active-screen .device-icon {
  background-color: #03a898;
}

.responsive-screen-selector.active-screen .device-icon:hover {
  background-color: #0c9882;
}

.responsive-screen-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 20px;
  margin-top: -20px;
  max-width: 100%;
}

.responsive-screen-selector {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  margin-bottom: 8px;
  overflow: hidden;
  background-color: #f1f1f1;
}

.responsive-screen-selector.active-screen .responsive-screen-selector {
  background-color: #e5f6f5;
}

.responsive-header-container.medium-chip .responsive-screen-selector {
  border-radius: 3px;
  height: 21px;
}

.responsive-screen-selector .page-title {
  width: auto;
  padding: 5px;
  position: relative;
  margin-bottom: 0px;
}

.responsive-header-container.medium-chip .page-title {
  bottom: auto;
  padding: 2px;
}

.responsive-screen-selector .page-title-text {
  background-color: #f1f1f1;
  color: #424242;
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
}

.responsive-header-container.medium-chip .page-title-text {
  font-size: 10px;
}

.responsive-screen-selector.active-screen .page-title-text {
  background-color: #e5f6f5;
}

.responsive-screen-selector.active-screen .page-title {
  background-color: #e5f6f5;
}

.small-responsive-header.active-screen .page-title-text {
  color: #00a898;
  font-weight: 600;
}

.small-responsive-header.active-screen .icon {
  background-color: #00a898;
}

.responsive-header-container.medium-chip .screen-size-option .icon {
  margin-top: 1px;
}

.screen-selector {
  height: 12px;
  cursor: pointer;
  pointer-events: all;
  flex-grow: 1;
}

.responsive-header-container .navbar-dropdown-children {
  position: absolute;
  top: 20px;
  left: 0;
  margin-top: -1px;
  margin-left: -1px;
  width: 185px;
  min-width: 185px;
  padding: 7px 0;
}

.responsive-header-container.medium-chip .navbar-dropdown-children {
  width: 162px;
  min-width: 162px;
}

.screen-size-option {
  display: flex;
  pointer-events: all;
  align-items: center;
  width: auto;
  padding: 4px;
  padding-right: 7px;
  justify-content: space-between;
}

.responsive-header-container.medium-chip .screen-size-option {
  height: 32px;
  padding: 0;
  padding-right: 5px;
  padding-left: 2px;
}

.screen-size-option:hover {
  background-color: #f1f1f1;
}

.screen-size-option.active:hover {
  background-color: #f1f1f1;
}

.screen-size-option.active span {
  color: #03a898;
}

.screen-size-option.active {
  background-color: #00a89710;
}

.navbar-dropdown-children .screen-size-option span {
  margin: 0px;
  margin-left: 7px;
  font-weight: 500;
  font-size: 13px;
}

.responsive-header-container.medium-chip .screen-size-option span {
  font-size: 12px;
  margin-left: 5px;
  align-items: center;
}

.device-information {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.device-name {
  margin-left: 3px;
}

.responsive-header-container.medium-chip .device-name {
  margin: 0;
}

.screen-size-option:hover span {
  font-weight: 600;
}

.page-title {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  margin-bottom: 6px;
  color: #8a8a8a;
  cursor: pointer;
  pointer-events: all;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.page-title-text {
  line-height: 12px;
  font-size: 10px;
  line-height: 12px;
  display: inline;
  letter-spacing: 0.3px;
  background: #fff;
}

.page-title-icons {
  display: block;
  height: 12px;
  margin-left: 4px;
}

.page-title-icons .icon {
  margin: -6px -2px;
  background-color: #8a8a8a;
}

.responsive-screen-selector .icon {
  background-color: #424242;
}

.device-icon .icon {
  background-color: #ffffff;
  align-self: center;
}

.screen-size-option.active .icon {
  background-color: #03a898;
}

.page-title:hover {
  /* color: #f04b25; */
}

.page-title:hover .icon {
  /* background-color: #f04b25; */
}

.page-title.component-page-title {
  color: #8c1fe6;
}

.page-title.component-page-title .icon {
  background-color: #8c1fe6;
}

#panning {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  cursor: grab;
  /* place div above all other divs */
  z-index: 999999;
}

#panning:hover:active {
  cursor: grabbing;
}