.params-section {
  > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h5 {
    margin: 0 0 6px;
    font-weight: normal;
  }
}

.params-section > p {
  margin-bottom: 4px;
}

.empty-params-section {
  background: #f1f1f1;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s;
  margin: 0 -16px;
  border-radius: 6px;
  padding: 0 16px;
  color: #8a8a8a;
  font-size: 14px;
}

.empty-params-section .icon {
  margin-right: 4px;
}

.empty-params-section:hover {
  background: #ddd;
}

.param-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

.param-item + .param-item {
  margin-top: 10px;
}

.param-item > .input-wrapper,
.param-item > .wrapped-select {
  flex: 1;
  margin-right: 10px;
  height: 36px;
}

.param-item > .input-wrapper input {
  height: 36px;
  border: none;
  background: #f1f1f1;
  border-radius: 4px;
  font: inherit;
  padding: 0 8px 0 16px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}

.param-item > .input-wrapper input:focus {
  border-color: #aaa;
}

.param-item .multi-select-menu-selection {
  background: #f1f1f1;
  height: 36px;
  line-height: 24px;
  box-shadow: none;
  padding-left: 16px !important;
  transition: background 0.2s;
}

.param-item .multi-select-menu-selection:hover {
  background: #ddd;
}

.param-item .wrapped-select.expanded .multi-select-menu-selection {
  transition: none;
}

.param-item-delete-icon .icon {
  background: #9a9a9a;
}

.params-add-param {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 0px 10px;
  margin: 4px -16px;
  color: #9e9e9e;
  transition: color 0.2s;
}

.params-add-param .icon {
  background: #9e9e9e;
  transition: background 0.2s;
}

.params-add-param:hover {
  color: #777;
}

.params-add-param:hover .icon {
  background: #777;
}
