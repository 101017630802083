@import 'styles/_colors.scss';

.font-picker-wrapper {
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.font-picker-change-fonts {
  align-items: center;
  white-space: nowrap;
  color: $darkPink;
  font-weight: 600;
  display: flex;
  .icon {
    margin-right: 8px;
  }
}
