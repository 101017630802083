@import 'styles/_colors.scss';

.toggle-field {
  position: relative;

  label,
  span {
    display: block;
  }

  label {
    font-size: 12px;
    margin-bottom: 4px;
    color: $labelDark;
  }

  span {
    font-size: 10px;
    margin-top: 4px;
    color: $label;
  }

  &.toggle-field-large-help-text {
    span {
      font-size: 12px;
    }
  }

  &.toggle-field-boxed {
    label {
      position: absolute;
      top: 10px;
      left: 16px;
      color: $label;
    }

    span {
      padding-left: 16px;
    }

    .toggle-field-content {
      border: none;
      height: 60px;
      padding: 5px 16px;
      margin: 0;

      input {
        margin-top: 14px;
        background-color: unset;
      }
    }
  }

  .toggle-field-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    background-color: white;
    border: 1px solid $grey;
    border-radius: 6px;
    padding: 12px 15px;
    margin: 0 -16px;
    height: 54px;

    input {
      flex: 1;
      border: none;
      outline: none;
      font-size: 14px;
      color: $body;
      background: transparent;
    }

    .toggle-button {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: auto;
    }
  }

  &.toggle-field-disabled {
    input {
      font-style: italic;
      color: $subtitle;

      &:disabled {
        color: $subtitle;
        -webkit-text-fill-color: $subtitle;
      }
    }
  }

  &.toggle-field-boxed.toggle-field-input-hidden {
    label {
      color: $body;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
    }
  }

  &.toggle-field-input-hidden {
  }
}
