@import 'styles/_colors.scss';
$mobile-breakpoint: 719px;

.chip-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 572px;
  gap: 14px;
}

.chip-input__label {
  display: block;
  color: #9c9c9c;
  font-size: 14px;
}

.chip-input__wrapper {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 8px;
  min-height: 67px;
  justify-content: center;

  &--selected {
    padding: 0 21px;
  }
}

.chip-input__field {
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
  padding: 18px 21px;
  color: #333;

  &::placeholder {
    color: #ccc;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 16px;
  }
}

.chip-input__chips {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  border-radius: 0 0 8px 8px;
  padding: 20px 10px;
  border-top: 1px solid #ccc;
  background-color: #f3f3f3;
  gap: 8px;

  &--empty {
    color: #777;
    display: flex;
    align-items: center;
  }
}

.chip-input__chip {
  background-color: #e0e0e0;
  border-radius: 15px;
  padding: 5px 10px;
  text-transform: capitalize;
  border-radius: 8px;
  letter-spacing: normal;

  &__wrapper {
    background-color: $white;
    border-radius: 8px;

    &.generic {
      background-color: transparent;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: $white;
      }

      .btn.chip-input__chip {
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &.btn.chip-input__chip--hover {
    background-color: $lightTeal !important;
    border: 1px solid #00a898;
    color: $teal !important;
    padding: 5px 10px;

    .icon.icon-plus-small {
      background-color: $teal;
    }
  }

  &--selected {
    border-radius: 8px;
    text-transform: capitalize;

    &.btn.btn-teal {
      color: #333;
      border: 1px solid #00a898;
      padding: 5px 10px;

      .icon {
        background-color: #333;
        margin: 0;
        margin-left: 8px;
        mask-size: 10px;
      }
    }
  }

  &.btn {
    color: #333;
  }

  &:hover {
    background-color: #ccc;
  }

  &.btn {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;

    &.btn.btn-text {
      border: none;
    }
  }
}
