@import 'styles/_colors.scss';

.test-connection-initial {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  text-align: center;
  flex-direction: column;
  max-width: 280px;
  margin: 0 auto;

  h2 {
    font-size: 20px;
    margin-bottom: 0.5em;
  }

  p {
    color: #777;
    margin-bottom: 20px;
  }
}

.test-request-results {
  user-select: text;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;

  h2 {
    font-size: 20px;
    margin-bottom: 0.75em;
  }

  .test-connection-button {
    float: right;
  }

  pre {
    margin: 0;
    overflow-x: auto;
  }

  label {
    display: inline-block;
    font-size: 12px;
    margin-left: 16px;
    margin-bottom: 10px;
    color: $label;
  }

  .custom-action-outputs {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .accordion.accordion-expanded {
    .aacordion-children {
      margin-top: 500px;
    }
  }
}
