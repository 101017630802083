.data-object-modal h2 {
  margin-bottom: 45px;
}

.data-object-form-error {
  color: red;
  text-align: right;
}

.data-object-form-footer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.data-object-form-footer a,
.data-object-form-footer .data-object-form-cancel {
  color: #8a8a8a;
  text-decoration: none;
}

.data-object-form-footer a:hover,
.data-object-form-footer .data-object-form-cancel:hover {
  text-decoration: underline;
  cursor: pointer;
}

.data-object-form-cancel {
  flex: 1;
}

.data-object-form-footer .btn {
  margin-left: 20px;
}

.data-object-form-row {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.data-object-form-label {
  flex: 1;

  display: flex;
  align-items: center;
  align-self: flex-start;
  margin: 0 16px 0 0;
  font-size: 15px;

  height: 60px;
}

.data-object-form-label .icon {
  margin-right: 12px;
  min-width: 24px;
}

.data-object-form-input {
  flex: 3;
}

.data-object-form-input .image-input-wrapper,
.data-object-form-input .image-input-wrapper .image-input {
  width: 100%;
}

.data-object-form-input .image-input-wrapper .image-input {
  height: 200px;
}

.data-object-form-input-input {
  font: inherit;
  padding: 16px;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #e0e0e0;
  outline: none;
  font-size: 14px;
  height: 60px;
  resize: none;
}

.data-object-form-input-shorter {
  height: 50px;
}

.data-object-form-input-input:hover,
.data-object-form-input-input:focus {
  border-color: #ccc;
}

.association-data {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 0;
}

.association-data-item {
  display: block;
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.association-data-item:hover {
  background-color: #f3f3f3;
}

.association-data-item a {
  text-decoration: none;
  color: #000;
  margin-right: 20px;
  flex: 1;
}

.association-data-remove {
  font-size: 10px;
  color: #8a8a8a;
}

.association-data-remove:hover {
  cursor: pointer;
  color: #444;
}

/* FORM TOGGLE */
.data-object-form-toggle {
  display: flex;
}

.data-object-form-toggle > .data-object-form-toggle-group {
  flex-grow: 1;
  margin-right: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  opacity: 0.35;
  height: 60px;
  font-size: 14px;
}

.data-object-form-toggle > .data-object-form-toggle-group label {
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.data-object-form-toggle > .data-object-form-toggle-group input {
  display: none;
}

.data-object-form-toggle > .data-object-form-toggle-group:last-of-type {
  margin-right: 0;
}

.data-object-form-toggle > .data-object-form-toggle-group:hover {
  border-color: #ccc;
}

.data-object-form-toggle > .data-object-form-toggle-group:hover,
.data-object-form-toggle > .data-object-form-toggle-group.active {
  opacity: 1;
}

.data-object-form-toggle > .data-object-form-toggle-group .icon {
  margin-right: 8px;
}

.data-object-form-input .wrapped-select {
  height: 60px;
}

.data-object-form-input .wrapped-select .multi-select-menu-selection {
  padding: 16px;
  height: 60px;
  font-size: 14px;

  display: flex;
  align-items: center;
}

.data-object-form-input
  .wrapped-select
  .multi-select-menu-selection
  .multi-select-menu-expand-icon:before {
  right: 24px;
  background-color: #333333;
}

.data-object-form-input-image {
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
