.predictions-container {
  margin-top: -3px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  position: absolute;
  width: 468px;
  z-index: 100000;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &__body {
    overflow-y: scroll;
  }

  &__prediction {
    width: 100%;
    background-color: white;
    border: none;
    text-align: left;
    padding: 0 15px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    color: lightgray;
    margin-right: 10px;
  }

  &__title-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__title {
    font-size: 14px;

    &--primary {
      color: #424242;
      font-weight: 500;
    }

    &--secondary {
      color: gray;
      font-weight: normal;
    }
  }

  &__footer {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
  }
}
