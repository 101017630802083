.template-select {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.template-select-option {
  margin: 0 10px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}

.template-select-option .template-select-option-name {
  text-align: center;
  margin-bottom: 10px;
}

.template-select-option-preview {
  border-radius: 6px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.template-select-mobile .template-select-option-preview {
  width: 119px;
  height: 211px;
}

.template-select-web .template-select-option-preview {
  width: 158px;
  height: 134px;
}

.template-select-responsive .template-select-option-preview {
  width: 158px;
  height: 134px;
}

.template-select-option-preview {
  background-color: #fff;
}

.template-select-option-preview.without-image:not(.blank) {
  background-color: #eee;
}

.template-select-option-selected-badge {
  text-align: center;
  line-height: 27px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 27px;
  background-color: #ffc00e;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.template-select-option-selected-badge .material-icons {
  font-size: 16px;
  margin-right: 8px;
}

.template-select-option-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
