.editor-preview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  overflow-y: auto;
}

.editor-preview-frame-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.editor-preview-frame-border {
  transform-origin: 0 0;
  background: #000;
  padding: 90px 20px;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px #555;
}

.editor-preview-frame-border.iphonex {
  padding: 20px;
  border-radius: 64px;
  position: relative;
}

.editor-preview-frame-border.iphonex:before {
  content: url('./iphonex-notch.svg');
  position: absolute;
  left: 50%;
  margin-left: -110px;
  top: 18px;
  z-index: 10000;
}

.editor-preview-frame-border.iphonex:after {
  content: '';
  display: block;
  height: 5px;
  width: 134px;
  background: #000;
  border-radius: 3px;
  position: absolute;
  bottom: 34px;
  left: 50%;
  margin-left: -67px;
  z-index: 10000;
}

.editor-preview-frame-border.iphonex .editor-preview-frame-clip {
  border-radius: 44px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: none;
}

.editor-preview-frame-clip {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.editor-preview-frame {
  box-sizing: content-box;
  border: none;
}

.editor-preview-close {
  display: block;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
}

.editor-preview-close .icon {
  background: #fff;
  width: 40px;
  height: 40px;
  mask-size: contain;
  display: block;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.editor-preview-close:hover .icon {
  opacity: 1;
}

.editor-preview-size-menu {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 200px;
}

