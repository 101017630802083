.edit-bar-analytics .left-panel-title {
  position: relative;
  padding: 20px;
  color: rgb(168, 32, 88);
}

.edit-bar-analytics .left-panel-title .icon-analytics {
  background-color: rgb(168, 32, 88);
}

.app-analytics-multi-menu .multi-select-menu-selection {
  margin: 10px;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(245, 245, 245);
  padding-left: 16px;
  font-weight: normal;
  line-height: 54px;
  box-shadow: inset 0 0 0 1px rgb(245, 245, 245);
  padding-right: 40px;
}

.analytics-panel {
  width: 800px;
}

.app-analytics-multi-menu .multi-select-menu {
  margin: 10px -16px;
  height: 54px;
}

.app-analytics-multi-menu .multi-select-menu-value {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.app-analytics-multi-menu .multi-select-menu-expand-icon:before {
  visibility: hidden;
}

.app-analytics-multi-menu .multi-menu-item {
  width: 300px;
}

.app-analytics-multi-menu {
  left: 225px;
  width: 200px;
}

.app-analytics-date-picker-container {
  width: 300px;
  height: fit-content;
  position: relative;
  border-color: #eaeaea;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  right: 327px;
  padding: 16px;
  margin-bottom: -236px;
  top: 50px;
  background: white;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 100004;
}

.analytics-date-picker {
  height: 40px;
  font-size: 14px;
  border-color: #eaeaea;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  margin-bottom: 20px;
  padding: 16px;
  width: inherit;
}

.date-picker-close {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100003;
}

.app-analytics-container {
  display: flex;
  flex-direction: column;
}

.app-analytics-user-container {
  display: flex;
  flex-direction: column;
  order: 2;
  padding-left: 30px;
  margin-bottom: 40px;
}

.app-analytics-main-container {
  display: flex;
  order: 1;
  padding: 35px 30px 15px 30px;
}

.app-analytics-module-userchart {
  order: 1;
  width: 50%;
}

.app-analytics-engagement-container {
  display: flex;
  flex-direction: column;
  order: 3;
  padding-left: 30px;
}

.app-analytics-row-container {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}

.app-analytics-module-cumuchart {
  order: 1;
}

.app-analytics-module-visited {
  order: 2;
}

.app-analytics-module-locations {
  order: 1;
}

.app-analytics-module-devices {
  order: 2;
}

.app-analytics-module-container {
  border-color: rgb(224, 224, 224);
  border-width: thin;
  border-radius: 5px;
  border-style: solid;
  width: fit-content;
}

.app-analytics-total-container .tooltip-trigger {
  justify-content: flex-end;
  display: flex;
  position: relative;
  left: 15px;
  margin: -14px -28px -8px 0px;
}

svg.recharts-surface {
  overflow: visible;
}

.app-analytics-main-container .app-analytics-module-container {
  height: 100px;
}

.app-analytics-module-container-line {
  padding-top: 10px;
}

.app-analytics-engagement-container .app-analytics-module-container,
.app-analytics-user-container .app-analytics-module-container {
  height: 240px;
}

.app-analytics-total-container {
  padding: 20px 54px 20px 16px;
  width: calc(34% + 1px);
  margin-right: 15px;
}

.app-analytics-engaged-container {
  padding-left: 5px;
  padding-top: 5px;
  width: 472px;
}

.app-analytics-locations-container {
  margin-right: 20px;
}

.engaged-header-container p {
  color: black;
  margin-top: -15px;
  padding: 0px 15px 0px 0px;
}

.app-analytics-total-container h1 {
  margin-top: -15px;
}

.app-analytics-visited-container {
  position: relative;
  margin-left: 20px;
  top: -24px;
}

.engaged-tooltip {
  background: rgb(51, 51, 51);
  width: fit-content;
  padding: 10px;
  position: absolute;
  top: 65px;
  z-index: 5;
  border-radius: 5px;
}

.chart-tooltip {
  background: rgb(51, 51, 51);
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
}

.engaged-tooltip h2,
.engaged-tooltip p,
.chart-tooltip h2,
.chart-tooltip p {
  color: white;
  white-space: pre-line;
}

.engaged-tooltip h2,
.chart-tooltip h2 {
  margin-bottom: 10px;
}

.user-chart-start-date {
  position: relative;
  left: 16px;
}

.user-chart-end-date {
  right: 45px;
}

.cumu-chart-end-date {
  position: relative;
  right: 15px;
}

.user-chart-date-header {
  margin-right: 5%;
  text-align: right;
}

.user-chart-header {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 15px;
  margin-left: 16px;
}

.user-chart-header-container {
  margin-bottom: -50px;
  position: relative;
  top: -17px;
  z-index: 0;
}
.most-visited-header {
  position: relative;
  bottom: 25px;
  left: 36px;
}

.module-header {
  position: relative;
  bottom: 2px;
  left: 16px;
}
.user-chart-date-container {
  display: flex;
  justify-content: space-between;
}

.range-dropdown-current-app {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}

.range-dropdown-current-app-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.range-dropdown .navbar-dropdown-title .icon {
  margin-left: 10px;
}

.range-dropdown {
  justify-content: flex-end;
  margin-left: 46px;
  cursor: pointer;
  right: 27px;
}

.range-dropdown .navbar-dropdown-title {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 40px;
  color: #333;
  font-size: 14px;
  font-weight: normal;
  padding: 0 8px 0 16px;
  overflow: hidden;
  max-width: 360px;
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}

.range-dropdown-container {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -131px;
  width: 262px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
}

.range-dropdown .navbar-dropdown-children {
  width: 100px;
  left: unset;
  top: 50px;
}

.granularity-button {
  border-radius: 5px;
  height: 40px;
  width: 75px;
  background: white;
  color: rgb(189, 189, 189);
  border-width: thin;
  border-color: rgb(224, 224, 224);
  bottom: -31px;
  display: inline-flex;
  margin-right: -1px;
}

.granularity-button:hover {
  background-color: white;
}
.granularity-button:active,
.granularity-button:focus {
  background-color: white;
}
.granularity-button-selected {
  color: #333;
  border-color: #bdbdbd;
  z-index: 1;
}

.granularity-button-start {
  border-radius: 5px 0px 0px 5px;
}

.granularity-button-middle {
  border-radius: 0;
}

.granularity-button-end {
  border-radius: 0px 5px 5px 0px;
}

.granularity-toggle {
  text-align: center;
  position: relative;
  bottom: 31px;
}

.range-dropdown .navbar-dropdown-children div {
  cursor: pointer;
  height: 40px;
  width: 100%;
}

.range-dropdown .navbar-dropdown-children div:hover {
  background-color: #eeeeee;
}

.navbar-dropdown-children span {
  color: rgb(97, 97, 97);
  font-size: 14px;
  margin: 10px;
  position: relative;
}

.analytics-selector-container {
  display: inline-flex;
  justify-content: flex-end;
  z-index: 2;
  margin-right: 3px;
  margin-top: 10px;
  margin-bottom: -11px;
}

.app-analytics-date-picker-container h3 {
  margin-bottom: 5px;
}

.custom-tick-max,
.custom-tick-half {
  position: relative;
  z-index: 1;
  text-align: center;
  width: 30px;
  height: 20px;
  border-color: rgb(189, 189, 189);
  color: rgb(158, 158, 158);
  border-style: solid;
  border-width: thin;
  border-radius: 5px;
  padding: 2px;
  background: white;
  left: -15px;
}

.custom-tick-max {
  top: 46px;
}
.custom-tick-half {
  top: 137px;
}

.total-users-label {
  display: inline-flex;
}

.user-label {
  font-weight: 300;
  font-size: 26px;
  padding: 5px 0px 0px 0px;
  margin: 0px 0px 0px 7px;
}

.legend-label-container {
  display: inline-flex;
  margin-right: 5px;
}

.legend-label-container span {
  padding-left: 2px;
  margin-right: -2p;
}
.engaged-bar-chart {
  margin-left: -9px;
  margin-top: 4%;
}

.legend-label-value {
  font-weight: 600;
}

.engaged-header-container {
  padding-left: 10px;
  padding-top: 16px;
  margin-bottom: -8%;
}

.tooltip-container h2 {
  margin-bottom: 10px;
}

.recharts-legend-wrapper {
  bottom: -2px;
}

.visited-item {
  display: flex;
  width: inherit;
  justify-content: space-between;
  height: 16px;
  padding: 5px 0px 20px 0px;
}

.loading-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: -webkit-fill-available;
}

.visited-item .tooltip-trigger, .visited-item-name {
  padding: 0px 0px 0px 16px;
}

.visited-item-value {
  display: inline-flex;
  padding: 0px 30px;
}
.visited-item-number {
  padding: 0px 12px;
  color: rgb(168, 32, 88);
}


.visited-item-value svg {
  margin-top: 3px;
}

.visited-header-container {
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  border-color: #eaeaea;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
}

.visited-content {
  overflow-y: scroll;
  height: 201px;
}

.grey-header {
  font-size: 12px;
  color: rgb(158, 158, 158);
  font-weight: 100;
  position: relative;
}

.times-visited-text,
.screen-name-text {
  margin: 0px;
}

.screen-name-text {
  left: 16px;
}

.times-visited-text {
  right: 50px;
}

.date-picker-content {
  height: 0px;
  width: 0px;
}

.location-legend-wrapper {
  padding: 0px 16px 0px 16px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-legend {
  display: inline-block;
  padding: 0;
}

.location-legend li {
  display: inline;
  white-space: nowrap;
}

.location-legend-icon {
  position: relative;
  top: 4px;
}

.module-empty-state {
  display: flex;
  width: inherit;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
}

.module-empty-state-content {
  text-align: center;
  width: unset;
  height: unset;
}

.module-empty-state-content h2 {
  margin-bottom: 0px;
  color: rgb(158, 158, 158);
}

.module-empty-state-content span {
  margin-bottom: 0px;
  color: rgb(189, 189, 189);
}

.stacked-bar-wrapper {
  display: flex;
  justify-content: center;
}

.map-controls {
  display: flex;
  position: relative;
  text-align: center;
  margin-bottom: -18px;
  bottom: 30px;
  left: 290px;
}

.map-controls button {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: #333;
  color: #fff;
  border-radius: 2px;
  border: 0;
  margin-left: 2px;
}

.icon-center {
  background: white;
}
