@import 'styles/_colors.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin accordion(
  $text,
  $background,
  $childBackground,
  $coloredCollapse: false
) {
  > .accordion-title {
    @if ($coloredCollapse) {
      background-color: mix($background, $white, 20%);
      color: $text;

      &:hover,
      &:focus {
        background-color: $background;
      }

      .icon {
        background-color: $text;
      }
    }
  }

  @if ($coloredCollapse) {
    > div > .accordion-children {
      background-color: mix($background, $white, 20%);
    }
  }

  &.accordion-expanded {
    > .accordion-title {
      background-color: $background;
      color: $text;

      .icon {
        background-color: $text;
      }
    }

    .accordion-children {
      background-color: $childBackground;
    }

    .filter-options {
      background-color: transparent;
      border: 1px solid $background;
    }
  }

  .accordion-children-advanced-options {
    > div > .accordion-children {
      background-color: rgba($background, 0.2);
      margin: 16px -16px 0;
      padding: 16px;
      width: unset;
    }
  }

  &.accordion-disabled {
    opacity: 0.6;

    .accordion-title {
      cursor: default;
      pointer-events: none;
      font-style: italic;
    }
  }
}

.accordion {
  > div[speed='3'] {
    max-width: calc(100% + 64px);
  }

  &-disabled {
    opacity: 0.5;

    .accordion-title {
      pointer-events: none;
    }
  }

  &-expand-disabled {
    .accordion-title {
      cursor: not-allowed;

      .expand-disabled-title {
        font-size: 14px;
        font-weight: 500;
        flex: 1 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #9e9e9e;
      }
    }

    .expand-disabled-icon {
      background-color: $placeholder;
    }
  }

  &.accordion-boxed-yellow {
    @include accordion($black, $yellow, mix($yellow, $white, 20%), true);

    &.accordion-light {
      @include accordion($labelDark, $lightYellow, $white, true);
    }
  }
  &.accordion-boxed-orange {
    @include accordion($white, $orange, mix($orange, $white, 20%));

    &.accordion-light {
      @include accordion($labelDark, $lightOrange, $white);
    }
  }
  &.accordion-boxed-teal {
    @include accordion($white, $teal, mix($teal, $white, 20%));

    &.accordion-light {
      @include accordion($labelDark, $lightTeal, $white);
    }
  }
  &.accordion-boxed-green {
    @include accordion($white, $green, mix($green, $white, 20%));

    &.accordion-light {
      @include accordion($labelDark, $lightGreen, $white);
    }
  }
  &.accordion-boxed-dark-pink {
    @include accordion($white, $darkPink, mix($darkPink, $white, 20%));

    &.accordion-light {
      @include accordion($labelDark, $lightDarkPink, $white);
    }
  }
  &.accordion-boxed-purple {
    @include accordion($white, $purple, mix($purple, $white, 20%));

    &.accordion-light {
      @include accordion($labelDark, $lightPurple, $white);
    }
  }
  &.accordion-boxed-gray {
    @include accordion($white, $gray, mix($gray, $white, 20%));

    &.accordion-light {
      @include accordion($labelDark, $lightGray, $white);
    }
  }
}

.accordion-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &.feature-disabled {
    span {
      color: #cecece;

      &.icon {
        background: #cecece;
      }
    }

    .accordion-call-to-action {
      content: attr(hovercontent);
      display: none;
      position: absolute;
      right: 60px;
      top: auto;
      bottom: auto;
      margin: auto;
      color: $orange;
      text-transform: uppercase;
      font-size: 10px;
    }

    &:hover {
      .accordion-call-to-action {
        display: block;
      }
    }
  }
}

.accordion.accordion-expanded > .accordion-title.feature-disabled .title {
  color: $white;
}

.accordion-title > .icon {
  transition: transform 0.2s;
}

.accordion-title > .accordion-icon {
  margin-right: 8px;
}

.accordion > .accordion-children {
  display: none;
}

.accordion.accordion-expanded > .accordion-children {
  display: block;
}

.accordion-children {
  padding: 6px 0 6px 32px;
}

.accordion > div > .accordion-children > * {
  opacity: 0;
}

.accordion-expanded > div > .accordion-children > * {
  opacity: 1;
  animation: fadeIn 0.5s;
}

/* BOXED ACCORDION */

.accordion-boxed {
  border-radius: 4px;
  overflow: hidden;
}

.accordion-boxed > .accordion-title {
  background: #f1f1f1;
  padding: 10px 16px;
  font-size: 14px;
  transition: all 0.2s;
}

.accordion-boxed > .accordion-title:hover {
  background: #e5e5e5;
}

.accordion-boxed > .accordion-title .icon-button-wrapper {
  margin-top: -6px;
  margin-bottom: -6px;
}

.accordion-boxed > .accordion-title .icon {
  background: $labelDark;
  transition: background 0.2s;
}

.accordion-boxed > div > .accordion-children {
  margin: 0;
  padding: 8px 16px;
  background: #f1f1f1;
  transition: background 0.2s;
}

.accordion-boxed.accordion-expanded > .accordion-title {
  background: #00a898;
  color: #fff;
}

.accordion-boxed.accordion-expanded > .accordion-title .icon {
  background: #fff;
}

.accordion-boxed.accordion-expanded .accordion-children {
  background: #d5ecea;
}

.accordion-boxed-done {
  margin: 15px 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.accordion-boxed-done > * {
  margin-right: 8px;
}

.accordion-boxed-done > *:last-child {
  margin-right: 0;
}

.expand-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
