@import 'styles/_colors.scss';

.modal-wrapper {
  position: fixed;
  z-index: 20000;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 40px;

  &-small-padding {
    padding: 10px;
  }
}

.modal-wrapper-error-message {
  color: #f44;
  font-weight: 500;
  margin: 8px 16px;
}

.modal-wrapper-gray-out {
  background: rgba(255, 255, 255, 0.9);
}

.modal-wrapper-hide-dimmer {
  background-color: transparent;
}

.modal-wrapper-text-aligned-left {
  text-align: left;
}

.modal-inner-wrapper {
  flex: 1;
  min-height: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-inner-wrapper--top {
  align-items: flex-start;
}

.modal-wrapper-gray-out.modal-wrapper-white {
  background: rgba(255, 255, 255, 0.6);
}

.modal-wrapper-white .modal {
  border-radius: 25px;
  box-shadow: 0 4px 12px -4px rgba(0, 0, 0, 0.3), 0 8px 30px rgba(0, 0, 0, 0.12);
}

.modal h1 {
  font-size: 20px;
  margin-bottom: 0.8em;
  font-weight: 400;

  > span {
    display: block;
    font-size: 12px;
    color: $label;
    font-weight: normal;
  }
}

.modal-fit-content {
  height: fit-content;
}

.modal-wrapper-constrained .modal-inner-wrapper {
  height: 100%;
}

.modal-wrapper-constrained .modal {
  max-height: 100%;
  overflow: hidden;
}

.modal-wrapper-fixed-height .modal {
  height: 100%;
}

/* MODAL STATICS */

.modal {
  position: relative;
  background: white;
  border-radius: 12px;
  width: 100%;
  padding: 35px 40px 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
  overflow: hidden;
  user-select: text;

  &.modal-scrolling {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 80px);
    padding: 0;

    > div:not(.modal-close),
    > form {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      padding: 35px 40px 30px;
    }

    .modal-content {
      flex: 1;
      overflow: auto;
      max-height: calc(100vh - 230px);
    }
  }

  &.overflow-visible {
    overflow: visible;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    margin: -35px -40px 0;
    padding: 32px 24px 16px;
    border-bottom: 1px solid $grey;

    > * {
      margin: 0 8px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }

    h1 {
      flex: 1 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      font-size: 20px;
      font-weight: 500;
    }

    .icon-button-wrapper {
      height: 40px;
      width: 40px;
      max-height: 40px;
      max-width: 40px;
      padding: 8px;
    }

    &.modal-header-stepper {
      display: block;
    }
  }

  .modal-content {
    margin: 0 -40px;
    padding: 40px;

    &.fluid {
      padding: 0;
      margin: 0 -40px;
    }

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.marketplace {
      padding: 0 40px;
      overflow: hidden;
    }

    .modal-stepper {
      margin-top: -32px;
      margin-bottom: 32px;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;

    margin: 0 -40px -30px;
    padding: 16px 24px;
    border-top: 1px solid $grey;

    .modal-button-group {
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 8px;
    }

    .modal-button-group.modal-left-buttons {
      justify-content: flex-start;
    }

    .modal-button-group.modal-right-buttons {
      justify-content: flex-end;
    }

    > .modal-left-button {
      position: absolute;
      left: 24px;
    }
  }

  .modal-container {
    margin: 0 auto;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .modal-close {
    position: absolute;
    right: 16px;
    top: 16px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    .icon {
      height: 10px;
      width: 10px;
    }
  }

  &-wrapper {
    &-content-position {
      &-center {
        .modal {
          &-header,
          &-content,
          &-actions {
            align-items: center;
            text-align: center;
            justify-content: center;
          }

          &-actions {
            .modal-button-group {
              flex-grow: unset;
            }
          }
        }
      }
    }

    &-content-constrained {
      .modal {
        > *:not(.modal-close) {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }

      .modal {
        &-header,
        &-content,
        &-actions {
          margin: 0;
          padding: 0;
        }
      }
    }

    &-borderless {
      .modal {
        &-header,
        &-content,
        &-actions {
          border: none;
        }
      }
    }
  }
}

/* MODAL MAX-WIDTH SIZING */
.modal-width-xs {
  max-width: 480px;
}
.modal-width-sm {
  max-width: 640px;
}
.modal-width-md {
  max-width: 768px;
}
.modal-width-lg {
  max-width: 960px;
}
.modal-width-xl {
  max-width: 1200px;
}
.modal-width-fluid {
  max-width: unset;
}

/* MODAL HEADER COLORS */
/* ORANGE */
.modal-header-color-orange,
.modal-header-color-data {
  background-color: $lightData;
}

.modal-header-color-orange .icon-button-wrapper:hover,
.modal-header-color-orange .multi-menu-trigger.expanded .icon-button-wrapper {
  background-color: rgba(239, 76, 48, 0.15);
}

.modal-header-color-orange .icon-button-wrapper:active {
  background-color: rgba(239, 76, 48, 0.3) !important;
}

/* TEAL */
.modal-header-color-teal {
  background-color: $mediumTeal;
}

.modal-header-color-teal .icon-button-wrapper:hover,
.modal-header-color-teal .multi-menu-trigger.expanded .icon-button-wrapper {
  background-color: $lightTeal;
}

.modal-header-color-teal .icon-button-wrapper:active {
  background-color: $mediumTeal !important;
}

/* END MODAL HEADER COLORS */
