@import 'styles/_colors.scss';

.layer-expand-icon {
  transform: rotate(-90deg);
  transition: transform 0.2s;
}

.layer-item.selected > .layer-info .layer-expand-icon {
  background: #fff;
}

.layer-item.expanded > .layer-info .layer-expand-icon {
  transform: rotate(0deg);
}

.layer-item.component-layer > .layer-info .layer-expand-button {
  left: -28px;
}

.layers-drag-items {
  position: fixed;
  z-index: 100000;
  opacity: 0.6;
  pointer-events: none;
  background: #f5f5f5;
  width: 260px;
}

.layers-drag-items .icon-button-wrapper {
  display: none;
}

.drop-target {
  position: absolute;
}

.drop-target.before,
.drop-target.after {
  height: 3px;
  background: #00a898;
  left: 24px;
  right: -16px;
  margin-top: -1px;
  border-radius: 2px;
}

.drop-target.before {
  top: -1px;
}

.drop-target.after {
  top: 100%;
}

.drop-target.inside {
  position: absolute;
  top: -2px;
  bottom: -2px;
  right: -16px;
  left: 24px;
  border: 2px solid #00a898;
}

.layer-item {
  position: relative;
  margin: 0 -16px;

  .layer-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    > * {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .layer-no-expand {
      height: 32px;
      max-height: 32px;
      width: 32px;
      max-width: 32px;
      padding: 4px;
    }

    .layer-info-sub {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      width: 100%;
      min-height: 50px;

      background-color: $lightGrey;
      border-radius: 6px;
      padding: 12px 16px;

      &.hidden {
        opacity: 0.5;
      }

      &:active,
      &:hover {
        cursor: pointer;
        background-color: $grey;
        opacity: 1;
        .icon-button-wrapper {
          opacity: 1;
        }
      }

      &.magicHidden {
        opacity: 0.5;
        .icon-button-wrapper:hover {
          background-color: $lightTeal;

          opacity: 1;
          .icon:hover {
            background-color: $teal;
          }
        }
      }

      &.hidden {
        opacity: 0.5;
        .icon-button-wrapper {
          opacity: 1;
        }
      }

      .layer-label {
        flex: 1;

        display: flex;
        flex-direction: column;

        .layer-label-title,
        .layer-label-subtitle {
          display: block;
          flex: 1;
        }

        .layer-label-title {
          font-size: 14px;
          font-weight: 500;
        }
        .layer-label-subtitle {
          font-size: 12px;
          color: $body2;
        }
      }
    }
  }

  &.expanded {
    > .layer-info {
      .layer-info-sub {
        background-color: $grey;
      }
    }

    .layer-item-children {
      margin-left: 20px;
      padding-top: 8px;

      > * {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .layer-item {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &-unlicensed {
    .layer-info {
      .layer-info-sub {
        border: 2px solid #e52222;
      }
    }
  }
}

.panel-item.layer-item {
  width: calc(100% + 32px);
  margin: 0 -16px;
  min-height: 40px;

  .panel-item-header {
    border-radius: 6px;
    padding: 8px 16px;
    margin-left: 4px;
    min-height: 40px;
  }
}

.layer-items {
  padding: 16px 0;
  margin: -16px 0 -8px !important;

  > * {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
