@import 'styles/_colors.scss';

.integration-trial-modal {
  header.modal-header {
    padding-left: 50px;

    h1 {
      font-weight: bold;
    }
  }

  .integration-trial-header {
    font-weight: 200;
    color: rgb(117, 117, 117);
    width: 85%;
    line-height: 20px;
  }

  .integration-trial-day {
    color: #333;
    font-weight: bold;
  }

  .integration-trial-container {
    display: flex;
    justify-content: center;

    h1 {
      text-align: center;
      font-weight: bold;
      margin-bottom: 20px;
    }

    ul {
      font-size: 12px;
      list-style: none;

      li {
        margin-bottom: 10px;
        color: #424242;
      }
    }

    .integration-trial-bullet {
      display: grid;
      grid-template-columns: 20px 1fr;

      .adalo-bullet {
        height: 11px;
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
    }
  }

  .integration-trial-box {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 250px;
    border: 2px solid #eee;
    margin-right: 20px;
    border-radius: 10px;
    padding: 10px 20px;

    .integration-trial-image {
      height: 90px;
      margin-bottom: 10px;
    }

    button.btn {
      width: 110px;
      padding: 0px;
      color: #333;
      height: 25px;
    }

    .integration-trial-bottom-section {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 5px;

      a {
        text-decoration: none;
      }
    }
  }
}

.success-integration-pack-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-weight: bold;
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  p {
    font-size: 15px;
  }
}
