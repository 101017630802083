$base: 4px;
$green: #007b69;
$light-green: #49a5981a;
$gray: #e0e0e0;
$black: #000000;

.no-underline {
  text-decoration: none;
}

.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.pa-4 {
  padding: $base * 4;
}
.pa-3 {
  padding: $base * 3;
}
.pr-3 {
  padding-right: $base * 3;
}
.px-2-important {
  padding-right: $base * 2 !important;
  padding-left: $base * 2 !important;
}
.mrn-2 {
  margin-right: $base * -2;
}
.py-1 {
  padding-top: $base;
  padding-bottom: $base;
}
.ph-3 {
  padding-left: $base * 3;
  padding-right: $base * 3;
}
.mt-4 {
  margin-top: $base * 4;
}
.mt-3 {
  margin-top: $base * 3;
}
.border-gray {
  border: 1px solid $gray;
}
.radius-3 {
  border-radius: $base * 3;
}
.radius-8 {
  border-radius: $base * 8;
}
.radius-full {
  border-radius: 100%;
}
.f12 {
  font-size: $base * 3;
}
.f21 {
  font-size: 21px;
}
.semibold {
  font-weight: 600;
}
.size-50px {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
}
.color-green {
  color: $green;
}
.color-gray {
  color: #757575;
}
.color-black {
  color: $black;
}
.bg-light-green {
  background: $light-green;
}
