@import 'styles/_colors.scss';

.search-bar {
  display: flex;
  width: 40%;
  align-items: center;
  margin: 0 auto 50px;
  border-bottom: 1px solid $darkGrey;

  input {
    border: none;
    outline: none;

    height: 40px;
    width: 100%;
    margin-left: 10px;

    font: inherit;
    font-size: 14px;

    ::placeholder {
      color: #bdbdbd;
    }
  }
}
