@import 'styles/_colors.scss';

.modal.modal-scrolling .modal-content.feature-templates__modal-content {
  padding: 0 40px;
  overflow: hidden;
}

.feature-templates {
  &__no-results {
    > div {
      display: flex;
      gap: 8px;

      span {
        font-size: 20px;
      }
    }
  }

  &__container {
    display: flex;
    width: 100%;
    flex-direction: row;
    overflow: unset;
    height: calc(100vh - 230px);
  }

  &__sidebar {
    min-width: 148px;
    padding-top: 40px;

    h3 {
      margin-bottom: 8px;
    }

    .feature-templates-filter-label {
      margin-left: 8px;
      font-size: 12pt;
    }
  }

  &__vertical-line {
    width: 1px;
    border-right: 1px solid $grey;
  }

  &__filter {
    &__option {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 8px 0px 8px 14px;
      margin-left: -14px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      overflow: visible;

      &:not(.selected) {
        color: $filterLabel;
      }

      &.selected {
        background-color: $lightTeal;
        color: $teal;
        font-weight: 500;
      }

      &:hover:not(.selected) {
        background-color: $lightTeal;
        color: $teal;
        cursor: pointer;

        .icon {
          background-color: $teal;
        }
      }
    }

    &__label {
      margin-left: 8px;
      font-size: 12pt;
    }
  }

  &__feed {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    padding: 40px 0;
    width: 100%;
    justify-content: center;
    align-content: flex-start;
    overflow: scroll;
    height: 100%;
    min-height: 437px;
    margin-right: -40px;

    &-header {
      display: flex;
      width: 100%;
      margin: 0 42px;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
      }

      .btn {
        padding: 0 12px;
      }
    }

    &-items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
}
