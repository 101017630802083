@import 'styles/_colors.scss';

.magic-layout-options .responsive-settings .device-visibility .device-element {
  background-color: $white;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  height: 32px;
  min-height: 32px;
  padding: 0px 4px 0px 0px;
  width: 100px;
  justify-content: center;
  gap: 0px;
  margin-top: 6px;

  &.hidden {
    font-style: italic;
    color: #d9d9d9;
    background-color: $white;
    border: 1px solid #e9e9e9;
    opacity: 1;

    .icon {
      background-color: #cecece;
    }

    .device-label .device-label-title {
      color: #d9d9d9;
    }

    &:hover {
      background-color: #f8f8f8;
      color: #757575;

      .icon {
        background-color: #757575;
      }

      .device-label .device-label-title {
        color: #757575;
      }
    }
  }

  &:first-child {
    flex-grow: 1;
  }

  &:hover {
    background-color: #f8f8f8;

    .device-label .device-label-title {
      color: #424242;
    }

    .icon {
      background-color: #424242;
    }
  }

  .device-label {
    flex: none;

    .device-label-title {
      font-size: 13px;
      font-weight: 500;
      color: #616161;
    }
  }
}

.responsive-settings {
  .device-visibility-label {
    margin-top: 8px;
  }

  .device-visibility-options {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .device-visibility {
    .icon {
      background-color: #616161;
    }

    .icon-button-wrapper:hover {
      background-color: transparent;
    }
  }

  .accordion-expanded {
    .accordion-title {
      cursor: default;
    }
  }
}

.reset-to-shared {
  margin: 0 -16px;
  margin-bottom: 64px;
  width: calc(100% + 32px);
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #00a996;
  transition: all 0.2s;
  &:hover {
    background-color: #f2fbfa;
  }
}

.shared-responsive-layout {
  background-color: #00a8980d;
  margin: 16px -16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #00a89833;
  border-radius: 4px;
}

.layout-settings-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.shared-responsive-layout-with-auto-layout {
  background-color: #00a8980d;
  margin: 0px -16px;
  border: 1px solid #00a89833;
  border-radius: 4px;

  .layout-settings-header {
    width: 100%;
  }
}

.shared-responsive-layout-with-auto-layout .accordion-expanded {
  padding-bottom: 32px;

  .layout-icons-container > .layout-icon-active,
  .layout-icons-container > .layout-icon:not(.layout-icon-active):hover {
    border-color: $white;
  }

  .accordion-title {
    cursor: default;
  }
}

.layout-section-layout-controls-accordion {
  .accordion-children {
    .accordion-boxed-done {
      margin-right: 16px;

      .btn {
        padding: 0 5px;
      }
    }

    .form-inspect-field-wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-top: 6p;
    }
  }

  &.accordion-expanded {
    padding-bottom: 0;
  }
}

.shared-responsive-layout-with-auto-layout .accordion-title {
  padding: 16px;
  border-radius: 4px 4px 0 0;
}

.shared-responsive-layout-with-auto-layout
  .accordion-expanded
  .accordion-title {
  background-color: #00a898;
  border: 1px solid #00a898;
  color: #ffffff;
}

.layout-settings-header-custom-note {
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: auto;
  margin-right: 16px;
  color: #333333;
}

.accordion-expanded .layout-settings-header-custom-note {
  color: #ffffff;
}

.layout-settings-title {
  font-weight: 500;
  font-size: 15px;
  width: 180px;
}

.layout-settings-title-auto-layout {
  width: 128px;
  flex-grow: 1;
  margin-left: 10px;
}

.layout-icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.layout-icon .icon {
  background-color: $darkerGray;
}

.layout-icon:hover .icon,
.layout-icon-active .icon {
  background-color: $white;
}

.layout-icon-active:hover .icon {
  opacity: 0.5;
}

.shared-layout-active-wrapper {
  display: flex;
  flex-direction: row;
  background-color: $teal;
  border-radius: 4px;
  overflow: hidden;
  gap: 20px;
  padding: 0px 10px;

  .layout-icon {
    border-radius: 4px;
    width: auto;
    border: none;
    overflow: visible;
  }

  .layout-icon-mobile {
    margin-left: -8px;
    margin-right: -8px;
    overflow: visible;
  }

  .layout-icon-tablet {
    overflow: visible;
    margin-left: -6px;
    margin-right: -6px;
  }

  .icon-desktop-large,
  .icon-desktop-off {
    margin-right: -2px;
  }
}

.shared-layout-settings {
  padding: 0px 16px;
  margin: -16px 0px 13px;

  .magic-layout-row {
    padding-bottom: 14px;
  }
}

.shared-responsive-layout-with-auto-layout .shared-layout-active-wrapper {
  border: 1px solid $white;
  align-self: flex-end;
  gap: 0;
  padding: 0;

  .layout-icons-container {
    width: 104px;
    gap: 0px;
  }

  .layout-icon {
    margin: 0;
    width: 32px;
  }

  .layout-icon-active:not(:first-of-type) {
    border-left: 2px solid #ffffff1e;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.shared-layout-settings-with-auto-layout {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  margin-right: 32px;
  margin-top: -6px;

  .magic-layout-row {
    padding-bottom: 14px;
  }

  .multi-select-menu.expanded .multi-select-menu-selection {
    border: 1px solid $white !important;
  }

  .multi-select-menu-selection {
    background: $white !important;
    box-shadow: none;
    border: 1px solid $gray;
    border-radius: 4px;

    &:hover {
      background-color: $white;
    }
  }
}

.responsive-layout-settings .magic-layout-row {
  margin-top: 24px;
}

.magic-layout-options
  .responsive-layout-settings
  .library-inspect-menu-control {
  margin-top: 24px;
}

.responsive-layout-settings {
  .shared-responsive-layout,
  .custom-layout-container {
    .multi-select-menu.expanded .multi-select-menu-selection {
      border: 1px solid $white;
    }

    .multi-select-menu-selection {
      background-color: $white;
      box-shadow: none;
      border: 1px solid $gray;
      border-radius: 4px;

      &:hover {
        background-color: $white;
      }
    }
  }
}

.custom-layout-container {
  margin-bottom: 16px;
  border-radius: 4px;

  .layout-icon-active:hover {
    background-color: #f8f8f8;
    border: 1px solid #e9e9e9;

    .icon {
      background-color: #757575;
    }
  }

  .accordion-title,
  .accordion-title:hover {
    height: 54px;
    padding-left: 16px;
    font-size: 14px;
    background-color: #f4f4f4;
    border: 1px solid #e9e9e9;
    color: $labelDark;

    .icon {
      margin-right: 8px;
    }
  }

  .accordion-expanded {
    .accordion-title,
    .accordion-title:hover {
      background-color: $teal;
      color: $white;
      border: 1px solid $teal;

      .icon {
        background: $white;
      }
    }
  }

  .accordion-children {
    border: 1px solid #00a89833;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #00a8980d;
  }

  .custom-device-layout-controls {
    margin-right: 32px;
    margin-top: -6px;
  }

  .inspect-generic-row-body.disabled {
    margin: 8px -16px;
    padding-left: 16px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $darkGray;
    height: 44px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}

.empty-custom-layout-container {
  background-color: $lightGray;
  padding: 16px;
  margin: 16px -16px;

  .layout-settings-title {
    font-style: italic;
    color: $placeholder;
  }
}

.responsive-help-text {
  color: $body2;
}

.layout-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: $white;
  border: 1px solid $gray;
  height: 32px;
  width: 32px;
}

.shared-responsive-layout .layout-icon:hover {
  background-color: #49c1b5;
  border: 1px solid #00a898b3;
}

.shared-responsive-layout .layout-icon-active:hover {
  background-color: $teal;
  border: none;
}

.layout-icon:hover,
.layout-icon-active {
  background-color: $teal;
  border-color: $teal;
  cursor: pointer;
}

.responsive-layout-settings .fixed-control-box {
  background-color: $white;
  font-size: 14px;
  height: 44px;
  align-items: center;
}

.responsive-help-link {
  font-weight: 600;
  color: #757575;
  text-decoration: none;
}

.custom-device-layout-controls {
  margin-right: 16px;
}

.responsive-layout-settings .accordion {
  margin: 16px -16px 0px;
  border-radius: 4px;
  overflow: hidden;
}

.layout-settings-header + .responsive-help-text {
  margin-top: 15px;
  margin-bottom: 7px;
}

.layout-settings-header + .shared-layout-settings {
  margin-top: 0px;
}

.magic-layout-row + .inspect-generic-row {
  margin-top: -15px;
}

.device-help-text {
  margin: 0px -16px 26px;
}

.responsive-settings .magic-layout-separator {
  margin-top: 12px;
  margin-bottom: 24px;
}

.responsive-settings .tooltip-trigger .icon {
  transition: none;
}

.layout-icon-tooltip {
  margin-top: 4px;
  margin-right: -33px;
}

.layout-option-tooltip-wrapper {
  background-color: $labelDark;
  padding: 24px 10px 20px;
  margin-left: 24px;

  .layout-option-tooltip-content p {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $white;
    margin: 0 22px 18px;
  }

  p.layout-option-tooltip-description {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $white;
    margin-top: 15px;
  }
}

.layout-option-tooltip-icon {
  margin-left: -14px;
}

.advanced-styles-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.responsive-settings .accordion-children-advanced-options {
  margin: 16px 0px 16px -32px;
  padding: 0px;
  background-color: #00a89826;

  .accordion-title {
    height: 0px;
    padding: 0px;
    border: none;
  }

  .accordion-children {
    padding: 13px 16px;
  }
}
