@import 'styles/_colors.scss';

.zapier-modal {
  .modal-header {
    h1 {
      color: $body;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn {
      font-size: 12px;
      line-height: 14px;
      gap: 0.5rem;

      span {
        text-decoration: none;
      }
    }
  }
}
