@import 'styles/_colors.scss';

.token-expired {
  &__toast {
    display: flex;
    background-color: $black;
    color: $white;
    width: fit-content;
    text-wrap: nowrap;
    margin-bottom: 32px;
    font-family: 'Work Sans', sans-serif;
    right: 1rem;

    p {
      font-size: 13px;
    }

    .Toastify__close-button {
      display: none;
    }
  }
}
