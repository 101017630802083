@import 'styles/_colors.scss';

$unit: 4px;

// Colors
@mixin color-gray-9e {
  color: #9e9e9e;
}

@mixin color-teal {
  color: $teal;
}

// Backgrounds

@mixin bg-teal {
  background-color: $teal;
}

// Borders
@mixin border {
  border: 1px solid black;
}
@mixin border-2 {
  border-width: 2px;
}

@mixin bb-none {
  border-bottom: none;
}

@mixin br-1 {
  border-radius: $unit;
}

@mixin br-2 {
  border-radius: 2 * $unit;
}

@mixin br-3 {
  border-radius: 3 * $unit;
}

@mixin bc-gray-9e {
  border-color: #9e9e9e;
}

@mixin dashed {
  border-style: dashed;
}

@mixin dotted {
  border-style: dotted;
}

// Scrolling
@mixin of-scroll {
  overflow: auto;
}

@mixin ofx-scroll {
  overflow-x: auto;
}

@mixin ofy-scroll {
  overflow-y: auto;
}

// Width
@mixin w-100pc {
  width: 100%;
}

// Height
@mixin h-auto {
  height: auto;
}

@mixin h-6 {
  height: 6 * $unit;
}

// Flexbox
@mixin flex {
  display: flex;
}

@mixin flex-1 {
  flex: 1;
}

@mixin fg-1 {
  flex-grow: 1;
}

@mixin flex-column {
  flex-direction: column;
}

@mixin gap-5 {
  column-gap: 5 * $unit;
}

@mixin space-between {
  justify-content: space-between;
}

@mixin space-around {
  justify-content: space-around;
}

@mixin flex-start {
  align-items: flex-start;
}

@mixin align-center {
  align-items: center;
}

@mixin align-stretch {
  align-items: stretch;
}

// Alignment
@mixin valign-top {
  vertical-align: top;
}

// Fonts
@mixin f-8 {
  font-size: 8px;
}

@mixin f-10 {
  font-size: 10px;
}

@mixin f-11 {
  font-size: 11px;
}

@mixin f-12 {
  font-size: 12px;
}

@mixin f-14 {
  font-size: 14px;
}

@mixin f-20 {
  font-size: 20px;
}

@mixin f-24 {
  font-size: 24px;
}

@mixin f-40 {
  font-size: 40px;
}

@mixin medium {
  font-weight: 500;
}

@mixin semibold {
  font-weight: 600;
}

@mixin bold {
  font-weight: bold;
}

@mixin center {
  text-align: center;
}

@mixin right {
  text-align: right;
}

@mixin left {
  text-align: left;
}

@mixin uppercase {
  text-transform: uppercase;
}

// Margins
@mixin mt-1 {
  margin-top: $unit;
}

@mixin mt-2 {
  margin-top: 2 * $unit;
}

@mixin mt-4 {
  margin-top: 4 * $unit;
}

@mixin mt-8 {
  margin-top: 8 * $unit;
}

@mixin mr-1 {
  margin-right: $unit;
}

@mixin mr-2 {
  margin-right: 2 * $unit;
}

@mixin mr-3 {
  margin-right: 3 * $unit;
}

@mixin mb-4 {
  margin-bottom: 4 * $unit;
}

@mixin ml-2 {
  margin-left: 2 * $unit;
}

@mixin mx-1 {
  margin-left: $unit;
  margin-right: $unit;
}

@mixin mb-8 {
  margin-bottom: 8 * $unit;
}

@mixin my-4 {
  margin-top: 4 * $unit;
  margin-bottom: 4 * $unit;
}

// Padding
@mixin p-2 {
  padding: 2 * $unit;
}

@mixin p-3 {
  padding: 3 * $unit;
}

@mixin p-4 {
  padding: 4 * $unit;
}

@mixin p-5 {
  padding: 5 * $unit;
}

@mixin px-1 {
  padding-left: 1 * $unit;
  padding-right: 1 * $unit;
}

@mixin px-2 {
  padding-left: 2 * $unit;
  padding-right: 2 * $unit;
}

@mixin px-5 {
  padding-left: 5 * $unit;
  padding-right: 5 * $unit;
}

@mixin py-1 {
  padding-top: $unit;
  padding-bottom: $unit;
}

@mixin pt-2 {
  padding-top: 2 * $unit;
}

@mixin pt-8 {
  padding-top: 8 * $unit;
}

// Lists
@mixin no-list-style {
  list-style: none;
}

// Positioning
@mixin relative {
  position: relative;
}

@mixin absolute {
  position: absolute;
}

@mixin right-0 {
  right: 0;
}

@mixin right-3 {
  right: 3 * $unit;
}

@mixin top-0 {
  top: 0;
}

@mixin top-3 {
  top: 3 * $unit;
}

// Display
@mixin inline-block {
  display: inline-block;
}

// Cursors
@mixin cursor-default {
  cursor: default;
}

@mixin pointer {
  cursor: pointer;
}

@mixin not-allowed {
  cursor: not-allowed;
}

// Icons
@mixin icon-3 {
  height: 3 * $unit;
  width: 3 * $unit;
}
