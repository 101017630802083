.usage-stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-radius: 6px;
  border: thin solid #e0e0e0;
  margin-bottom: 20px;

  .stat-container {
    display: grid;
    padding: 20px 0px 20px 15px;

    &:not(:last-child) {
      border-right: thin solid #e0e0e0;
    }

    > h4 {
      color: black;
      margin-bottom: 8px;
    }

    > h4,
    p {
      font-size: 11px;
      font-weight: 100;
    }
  }
}
