.offline-banner {
  position: fixed;
  top: -36px;
  left: 0;
  right: 0;
  z-index: 100000;
  background: #ffc00d;
  color: black;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  height: 36px;
  align-items: center;
  justify-content: center;
  transition: top 0.2s;
  opacity: 0;
}

.offline-banner a {
  color: black;
}

.offline-banner-visible .offline-banner {
  top: 0;
  opacity: 1;
}
