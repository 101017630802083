@import 'styles/_colors.scss';

$view-app-hover-transition: all 0.1s ease-in;

.view-app-button-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 18px;
  gap: 18px;

  .icon.icon-launch {
    mask-size: contain;
    width: 18px;
    height: 18px;
  }

  .view-app-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;

    cursor: pointer;

    width: 126px;
    height: 38px;

    border-radius: 100px;
    transition: $view-app-hover-transition;

    &:hover {
      background: linear-gradient(180deg, #006b5a 0%, #006b5a 100%);
    }

    p {
      color: $white;
      display: block;
      font-size: 12px;
      font-weight: 600;
    }

    &[disabled] {
      pointer-events: none;
    }

    .view-app-rocket {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
  }
}

.view-app-dropdown-wrapper {
  position: absolute;
  top: 62px;
  right: -32px;
  width: 270px;
  height: 172px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;

  background: $white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  user-select: none;

  & > * {
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }

  .view-app-dropdown-item {
    padding: 20px 18px;
    width: 100%;
    height: 70px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $grey;

    transition: $view-app-hover-transition;
    * {
      transition: $view-app-hover-transition;
    }

    &--disabled {
      pointer-events: none;
    }

    &:hover {
      background: #00a8981a;

      .view-app-dropdown-item-icon {
        background: $teal;
      }

      .icon {
        background-color: $white;
      }
    }

    .view-app-dropdown-item-text {
      flex: 1;
      display: flex;
      flex-direction: column;

      * {
        display: block;
      }

      h3 {
        margin-bottom: 3px;
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }

      p {
        color: #757575;
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .view-app-dropdown-item-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 300px;
      border: 1px solid rgba(0, 168, 152, 0.1);
      background: rgba(0, 168, 152, 0.1);

      .icon.icon-play {
        margin-left: 2px;
      }
    }

    &--disabled {
      .view-app-dropdown-item-icon {
        background: $white !important;
        border: 1px solid $gray !important;
      }

      .icon {
        background-color: $grey !important;
      }

      &:hover {
        background: $white;
      }
    }
  }

  .view-app-dropdown-go-to-publishing {
    text-align: center;
    align-self: flex-end;
    height: 28px;
    width: 100%;
    padding: 6px;
    background: #f8f8f8;

    color: #757575;
    font-size: 10px;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;

    * {
      display: block;
    }

    transition: $view-app-hover-transition;

    .icon {
      width: 15px;
      height: 9px;
      margin-right: 5px;
      mask-size: contain;
      transition: $view-app-hover-transition;
    }

    &:hover {
      background-color: $lightTeal;
      color: $teal;

      .icon {
        background-color: $teal;
      }
    }
  }
}
