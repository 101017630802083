.fixed-control {
  margin-top: 8px;
}
.fixed-control-box {
  display:flex;
  justify-content:space-between;
  padding: 16px;
  margin:0 -16px 24px;
  border-radius: 4px;
  border: 1px solid #E9E9E9;
}
