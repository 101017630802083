.form-page {
  padding: 40px;
  padding-bottom: 0;
  margin: 0 auto;
}

.form-page h1 {
  font-size: 24px;
}

.developers-modal .form-page {
  padding: 12px 16px 16px;
}