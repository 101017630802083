div .api-info pre {
  white-space: pre-line;
  word-break: break-all;
}

div .api-content pre {
  white-space: pre-line;
  word-break: break-all;
}

div .api-info p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

div .api-content p code {
  word-break: break-all;
}

ul label .operation-type.delete {
  background-color: #ee4d2f;
}
button span.http-verb.delete {
  background-color: #ee4d2f;
}

ul label .operation-type.put {
  background-color: #434379;
}
button span.http-verb.put {
  background-color: #434379;
}

ul label .operation-type.post {
  background-color: #00a695;
}

button span.http-verb.post {
  background-color: #00a695;
}

ul label .operation-type.get {
  background-color: #a1cd46;
}
button span.http-verb.get {
  background-color: #a1cd46;
}

div .api-content h1 {
  color: #ee4d2f;
}

div .api-info h3 {
  font-size: 1.17em;
  font-weight: bold;
}

ul li label.-depth1.active span {
  color: #ee4d2f;
}

ul li label.-depth1:hover span {
  color: #ee4d2f;
}

div .api-content pre code {
  word-break: break-all;
}
