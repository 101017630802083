.copy-app-radios {
  margin: 20px 0;
}

.responsive-copy {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.copy-app-modal {
  label {
    font-size: 12px;
    font-weight: 500;
    color: #666666;
  }

  .responsive-copy .wrapped-select {
    margin-top: 27px;
  }

  .wrapped-select {
    margin-top: 35px;
  }

  .wrapped-input {
    margin-bottom: 0px;
  }

  .multi-select-menu {
    height: 50px;
    font-size: 14px;
  }

  .multi-select-menu-selection {
    height: 50px;
    padding: 17px 35px 17px 16px;
    border-radius: 8px;
  }

  .multi-select-menu {
    .multi-menu-item.has-children:after,
    .multi-select-menu-expand-icon:before,
    .multi-menu-overflow-icon {
      right: 16px;
    }
  }

  .modal-content {
    padding-top: 25px;
    padding-bottom: 40px;
  }
}
