@import 'styles/_colors.scss';

.publish-panel {
  user-select: text;
}

.publish-plans {
  margin: 20px -16px;
}

.publish-plan {
  margin: 10px 0;
  padding: 15px;
  border: 1px solid $darkGrey;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.publish-plan:hover {
  background: #fafafa;
}

.publish-plan.publish-plan-selected {
  border-color: $green;
  background-color: rgba(161, 205, 70, 0.1);
}

.publish-plan-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.publish-plan-title > * {
  margin: 0;
}

.publish-plan-price {
  color: $green;
}

.publish-plan-attribute {
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.publish-plan-attribute .icon {
  transform: scale(0.667);
  background: rgba(0, 0, 0, 0.5);
  margin-right: 5px;
  margin-left: -4px;
}

.publish-bottom-message {
  color: #8a8a8a;
  line-height: 1.6em;
  font-size: 14px;
}

.publish-bottom-message-question {
  font-weight: 600;
}

.publish-lead-message {
  font-size: 14px;
  line-height: 1.4em;
  margin-bottom: 1.4em;
}

.publish-submit-sub {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.publish-success-block {
  background: rgba(161, 205, 70, 0.1);
  margin: 16px -16px;
  border-radius: 12px;
  padding: 16px;
  color: #a1cd46;
}

.publish-success-block h2 {
  margin-bottom: 0.5em;
  font-size: 16px;
}

.web-publish-open-url {
  margin-top: -16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -8px;
}

.web-publish form {
  margin: 20px 0 40px;
}

.mobile-publish-settings {
  margin: 0 -16px 16px -16px;
}

.mobile-publish-settings h2 {
  margin: 0 16px 16px;
}

.mobile-publish-settings h2 + .mobile-publish-summary {
  margin-top: 8px;
}

.mobile-publish-summary {
  .mobile-publish-steps {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row wrap;
    width: 100%;

    .mobile-publish-step {
      flex: 1;
      flex-basis: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      p {
        font-size: 14px;
      }

      .icon {
        background-color: $grey;
      }

      &.mobile-publish-step-valid {
        .icon {
          background-color: $green;
        }
      }
    }
  }
}

.mobile-publish-summary label {
  display: block;
  margin-bottom: 5px;
  flex: 1 1;
  color: #939393;
}

.mobile-build-list,
.mobile-publish-summary,
.mobile-apple-developer-summary {
  margin: 16px 0;
  border-radius: 12px;
  border: 1px solid $darkGrey;
  display: flex;
  flex-direction: column;
}

.mobile-publish-summary,
.mobile-apple-developer-summary {
  align-items: center;
  padding: 16px;
}

.mobile-apple-developer-account-connected,
.mobile-apple-developer-no-account {
  margin-bottom: 10px;
}

.mobile-apple-developer-no-account {
  font-size: 14px;
}

.mobile-apple-developer-account-connected {
  font-weight: bold;
}

.mobile-build-list {
  align-items: stretch;
  overflow: hidden;

  .btn.btn-square {
    border-radius: 0;
  }
}

.mobile-build-list-empty {
  color: #939393;
  padding: 20px;
  align-items: center;
  border-style: dashed;
  border-width: 2px;
}

.mobile-publish-summary-row {
  align-self: stretch;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.mobile-publish-summary .btn {
  margin-top: 16px;
  margin-bottom: 8px;
}

.mobile-publish-summary-icons {
  align-self: start;

  display: grid;
  grid-template-columns: 1fr 24px;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  align-items: center;
}

.mobile-publish-summary-icons span {
  color: #939393;
  min-width: 120px;
}

.mobile-publish-summary-icons img {
  height: 24px;
  width: 24px;
}
.mobile-publish-summary-default-icon {
  height: 24px;
  width: 24px;
  border-radius: 6px;
  background-image: url('../../Shared/AppIcon/default-icon.svg');
  background-color: #d1d1d1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  box-shadow: none;
}

// BuildList component was originally for the mobile builds table, but is now used for showing web builds too
// class names are namespaced with "mobile-build", but classes are for all build tables

.mobile-build {
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $darkGrey;
  padding: 8px 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile-build-list
  > div:first-child
  > .mobile-build-error
  > .mobile-build-error-summary-container
  > .mobile-build-error-summary {
  color: rgb(228, 80, 53);
}

.mobile-build-right {
  .material-icons {
    margin: 3px;
    color: #ccc;
    transition: all 0.2s;
  }

  .material-icons:hover {
    color: #bbb;
  }
}

.mobile-build-error {
  div:first-child {
    flex-basis: 100%;
    height: 0;
  }

  .mobile-build-error-summary-container {
    flex-grow: 1;
    border-top: 1px dotted #e0e0e0;
    margin-top: 7px;
    padding-top: 7px;

    .mobile-build-error-summary {
      color: #8a8a8a;
      word-break: break-all;
    }
  }
}

.mobile-build-debug-id {
  margin-left: 5px;
}

.mobile-build-state-building .mobile-build-details {
  font-style: italic;
}
.mobile-build-details {
  margin-top: 6px;
  color: #9b9b9b;
}

.mobile-build-info {
  flex: 1;
}

.mobile-build-version {
  font-weight: bold;

  & a {
    float: right;
    display: flex;
    align-items: center;
    color: $green;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
  }
  a .icon {
    margin-right: 8px;
  }
}

.mobile-build-app-url {
  margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $body2;
}

.mobile-build:last-child {
  border-bottom: none;
  padding-bottom: 8px;
}

.mobile-build:first-child {
  padding-top: 8px;
}

.mobile-publish-settings-modal .modal-content {
  padding-left: 48px;
  padding-right: 48px;
}

.publish-settings-form p {
  font-size: 14px;
  margin-top: 16px;
}

.publish-settings-form > .settings-form-row:first-child {
  margin-top: 0;
}

.web-publish-form .settings-form-input,
.web-publish-form .toggle-field-content {
  height: 44px;
  font-size: 14px;
}

.web-publish-form .multi-select-menu-selection {
  height: 44px;
}

.web-publish-form .multi-select-menu-value {
  margin-top: 4px;
}

.web-publish-form input,
.web-publish-form .multi-menu-trigger-element {
  border-radius: 6px;
  max-height: 44px;
  height: 44px;
  padding-top: 14px;
}

.settings-form-input-select .multi-menu-item.menu-option {
  min-height: 44px;
}

.web-publish-form .toggle-field-content {
  padding-top: 0;
  padding-bottom: 0;
}

.publish-settings-form-cancel {
  background-color: transparent;
  color: #9e9e9e;
}

.publish-settings-form-cancel:hover,
.publish-settings-form-cancel:focus,
.publish-settings-form-cancel:active {
  background-color: rgb(238, 238, 238);
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: $darkGrey;
}

.circle-progress {
  stroke: #85ab3e;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 0.8em;
  font-weight: bold;
  fill: #85ab3e;
}

.publish-form-note {
  font-size: 14px;
  margin: 10px 0;
}

.verify-credentials {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 0;
}

.verify-credentials > .loading + * {
  margin-top: 0.7em;
}

.verify-credentials > * + .btn {
  margin-top: 20px;
}

.mobile-publish-portal .modal-wrapper {
  z-index: 19999;
}

.app-specific-password-description {
  margin-top: 8px;
}

.publish-error-logs .modal-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: auto;
  user-select: text;
}

.inline-item {
  flex: 0 0 auto;
}

.log-instructions {
  padding-bottom: 20px;
}

.error-summary {
  background: rgba(0, 0, 0, 0.04);
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 4px;
  white-space: pre-line;
}

.error-logs-accordion .accordion-children {
  padding-left: 25px;
  padding-top: 20px;
}

.pwa-publish-settings {
  .pwa-publish-settings-info {
    h2,
    p {
      font-size: 12px;
    }

    h2 {
      color: $body;
      margin-bottom: 4px;
    }

    p {
      color: $body2;
      line-height: 18px;
    }
  }

  .wrapped-input {
    margin-left: -16px;
    margin-right: -16px;
  }

  .pwa-publish-error {
    margin: -16px 0 16px;
  }

  .pwa-publish-settings-submit-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -8px 32px;
  }
}

.mobile-layout-help-text-container {
  margin-top: 20px;

  h2 {
    margin: 0;
  }
}
