@import 'styles/_colors.scss';

.versions-wrapper {
  .left-panel-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: row;

    &::after {
      content: '';
      display: block;
      margin: 0 -32px;
      width: calc(100% + 64px);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid $gray;
    }

    .left-panel-title {
      color: $teal;
      padding: 0;

      &::after {
        border: 0;
      }
    }

    .icon {
      background-color: $teal;
    }

    .tooltip-trigger {
      height: fit-content;
    }
  }
}

.app-versions-container {
  display: flex;
  flex-direction: column;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.125rem;
  }

  .versions-current-design {
    padding: 10px 0;
  }

  .versions-accordion {
    position: relative;
    transition: all 0.2s;
    margin: 0 -32px;

    .accordion-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      padding: 12px 16px;
      position: relative;
      transition: background 0.2s;
      font-size: 14px;

      .versions-accordion-title-inner {
        flex: 1;
        padding-left: 8px;
        color: $labelDark;
      }

      .icon {
        background-color: $labelDark;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }

      .versions-count {
        padding: 0px;
        width: 31px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $placeholder;
      }
    }

    &.accordion-expanded::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -32px;
      right: -32px;
    }

    .accordion-children {
      padding-bottom: 0;
      padding-left: 16px;
      padding-right: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .content-disabled {
        opacity: 0.5;
        backdrop-filter: blur(1px);
        pointer-events: none;
      }

      .empty-state.data-upgrade-prompt {
        margin: 0 0 16px;
      }

      .app-versions-button:disabled {
        opacity: 0.3;
      }

      .versions-list {
        margin: 16px 0;
        border-radius: 12px;
        border: 1px solid $darkGrey;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;

        .btn.btn-square {
          border-radius: 0;
        }

        .version-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-bottom: 1px solid $darkGrey;
          padding: 14px;
          gap: 0.25rem;

          &:last-child {
            border-bottom: 0;
          }

          .version-item-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .version-item-left {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              gap: 0.25rem;

              .version-item-title {
                font-size: 13px;
                line-height: 1.5;
                font-weight: 500;
                display: flex;
                align-items: center;

                // Ellipsis overflow
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .version-item-date {
                font-size: 11px;
                color: $darkerGray;
                padding-bottom: 6px;
              }
            }

            .icon-more-vert {
              background: $filterLabel;
            }
          }

          .version-item-description {
            font-weight: 500;
            color: $labelDark;
            font-size: 11px;

            // Ellipsis overflow
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.versions-list-empty {
  margin: 16px 0;
  border-radius: 12px;
  border: 1px dashed $darkGrey;
  display: flex;
  flex-direction: column;

  align-items: stretch;
  overflow: hidden;
  color: $placeholder;
  padding: 20px;
  align-items: center;
  border-width: 1px;

  .versions-list-empty-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;

    .text-title {
      font-weight: 500;
      color: $filterLabel;
      font-size: 14px;
    }
  }

  &.unavailable {
    border-style: solid;

    .versions-list-empty-text {
      align-items: flex-start;
      text-align: left;
    }
  }
}
