.slider {
  height: 24px;
  position: relative;
}

.slider-track {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4)
}

.slider-knob {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: -8px;
  top: 4px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.slider-knob:hover {
  box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15);
}

.slider--disabled {
  opacity: 0.5;
  pointer-events: none;
}
