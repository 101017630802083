@import 'styles/_colors.scss';

.implicit-params h5 {
  margin: 0 0 6px;
  font-weight: normal;
}

.implicit-params-item {
  padding: 12px 16px;
  background-color: #f1f1f1;
  border-radius: 6px;
  margin: 6px -16px 0;
  min-height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.implicit-params-item-name {
  font-size: 14px;
}

.implicit-params-item-name + p {
  margin-top: 4px;
}

.implicit-params-item-partial {
  color: #bbb;
  background: transparent;
  padding: 11px 15px;
  border: 1px dashed #e0e0e0;
}

.implicit-params {
  .empty-state {
    margin: 0 -16px;
    padding: 16px;
    border: 1px dashed $border;
    border-radius: 6px;

    h5 {
      margin: 0 0 4px;
      font-size: 14px;
    }

    p {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }
}

.multi-menu-link-from-source .implicit-params-item {
  padding: 10px 16px;
  background-color: #ffffff;
  margin: 0px 0px 10px 0px;
  flex-direction: row;
}

.multi-menu-link-from-source .implicit-item-type-subtitle {
  margin-left: 10px;
}

.multi-menu-link-from-source .implicit-params-item-name {
  margin-left: 10px;
}

.multi-menu-link-from-source .implicit-params-item-partial {
  opacity: 0.6;
  color: #9a9595;
}

.multi-menu-link-from-source .implicit-item-icon {
  position: absolute;
  margin-left: 265px;
}

.multi-menu-link-from-source .implicit-params-missing-link {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.implicit-item-form-item-header {
  min-height: 24px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.multi-menu-link-from-source .implicit-params-description {
  align-items: center;
  display: flex;
  flex-direction: row;
}
