@import 'styles/_colors.scss';

.form-selector {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > .form-selector-selector {
    position: relative;
    border-radius: 6px;
    border: 1px solid $grey;
    padding: 16px;

    &.selected,
    &:hover {
      border-color: $body2;
      background-color: $lightGrey;
      cursor: pointer;

      &:after {
        content: '';

        display: inline-block;
        height: 24px;
        width: 24px;

        position: absolute;
        right: 8px;
        top: 8px;

        mask-type: alpha;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-image: url('../../Icon/icons/check-circle.svg');
        background-color: $body;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .form-selector-radio {
      display: none;
    }
  }
}
