/* Settings Modal CSS */
.team-settings-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 18px;
}

.team-settings-header h3 {
  margin-bottom: 0;
}

.editor-limit-subheading {
  display: block;
  font-size: 11px;
  font-weight: 500;
}

.team-members-form2 {
  margin-top: unset;
}

.team-members-member {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}

.team-members-member-details {
  flex: 1;
}

.team-members-member h4 {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 500;
}

.team-members-member p {
  margin: 0;
  color: #8a8a8a;
}

.team-select-menu {
  margin: 0 -16px 20px;
}

.team-members-invite h4 {
  color: #8a8a8a;
}

.team-members-invite p {
  font-style: italic;
}

.team-members-add-form .settings-form-row {
  margin-top: 0;
}

.team-settings .banner {
  margin: 10px -16px;
}

.settings-header-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
