@import 'styles/_colors.scss';

.data-browser-header-item-editable {
  color: #000;
}

.data-browser-header-item .data-browser-cell-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.data-browser-cell-resizeable .data-browser-cell-resize {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -6px;
  width: 11px;
  cursor: ew-resize;
  z-index: 2;
}

.data-browser-cell-resizeable .data-browser-cell-resize:before {
  content: '';
  display: block;
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  width: 3px;
  background: #ccc;
  opacity: 0;
  pointer-events: none;
}

.data-browser-cell-resizeable .data-browser-cell-resize:hover:before {
  opacity: 1;
}

.data-browser {
  position: relative;

  .empty-state {
    h1 {
      margin: 0;
    }
  }

  .data-browser-table {
    padding-bottom: 80px;

    .data-browser-header,
    .data-browser-row,
    .data-browser-row-linked {
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }

    .data-browser-header {
      position: sticky;
      z-index: 2;
      top: 0;
      background: #fff;
      border-bottom: 1px solid $darkGrey;

      .data-browser-header-item {
        position: relative;
        display: flex;
        flex-direction: column;

        color: $body;
        height: 54px;
        cursor: default;
        border-right: 1px solid $grey;

        .data-browser-cell-content {
          overflow: hidden;
          white-space: nowrap;

          &.center {
            margin: 0 auto;
          }

          > span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            padding: 0 16px;

            &.data-browser-drag-target {
              cursor: move;
            }

            .icon {
              min-width: 24px;
              min-height: 24px;
              margin-right: 8px;
            }

            .text {
              font-size: 14px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .data-browser-cell-resize {
            &:before {
            }
          }
        }
      }
    }

    .data-browser-data {
      position: relative;

      .data-browser-row,
      .data-browser-row-linked {
        height: 54px;
        position: absolute;
        text-decoration: none;
        color: $body2;
        border-bottom: 1px solid $grey;
      }

      .data-browser-row {
        &:hover {
          background-color: $lightGrey;
        }
      }

      .data-browser-row-linked {
        left: 50px;
      }

      .data-browser-row {
        .data-browser-cell {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;

          border-right: 1px solid $grey;
          position: relative;
          padding: 0 16px;

          .data-browser-cell-content {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.center {
              margin: 0 auto;
            }

            .data-browser-collection-item {
              font-weight: 500;
              font-family: inherit;
              background: $grey;
              border-radius: 100px;
              padding: 8px 16px;
            }
          }
        }
      }
    }
  }
}
