@import 'styles/_colors.scss';

.right-panel-prebuilt-layout-section-collapsed {
  color: $teal;
}

.right-panel-prebuilt-layout-section-collapsed .icon {
  background-color: $teal;
}

.right-panel-prebuilt-layout-section .image-input-wrapper {
  width: 100%;
  margin: 15px -16px 0 -16px;
}

.right-panel-prebuilt-layout-section .image-input {
  width: calc(100% + 32px);
  height: 300px;
}

.right-panel-prebuilt-layout-section .footnote {
  padding-top: 16px;
  padding-bottom: 12px;
  text-wrap: pretty;
  text-align: center;
}

.prebuilt-create {
  .multi-select-menu-selection {
    background-color: greenyellow;
  }
}

.prebuilt-update {
  .multi-select-menu-selection {
    background-color: lightcoral;
  }
}

.prebuilt-footnote {
  font-size: 18px;
  text-align: center;
}
