.option-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 0;
}

.option-control-option {
  padding: 4px 8px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 11px;
  margin-right: 4px;
  color: #8a8a8a;
}

.option-control-option-selected {
  background: #888;
  color: #fff;
}
