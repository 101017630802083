@import 'styles/_colors.scss';

.editor-add-panel-accordion {
  margin: 0 -32px;
}

.editor-add-panel-accordion .components-accordion-children {
  padding: 16px;
  justify-content: space-between;
}

.editor-add-panel-accordion .components-accordion-title {
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  margin-top: -1px;
}

.editor-add-panel-accordion .editor-add-component-item-wrapper {
  width: calc(50% - 4px);
}

.editor-add-panel-accordion .editor-features-tab {
  gap: 31px;
}

.editor-add-panel-accordion
  .editor-add-component-item:hover
  .editor-add-component-name
  span {
  font-weight: 500;
}

.prebuilt-section-item {
  > div {
    cursor: pointer;
  }
}

.prebuilt-section-image:hover,
.editor-add-panel-accordion
  .editor-add-component-item:hover
  .editor-add-component-icon {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.editor-add-panel-accordion .editor-add-component-icon:active {
  /*cursor: grabbing;*/
  transition: box-shadow 0.1s;
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.editor-add-component-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 0;
  text-align: center;
  background: #ffc00e;
  font-style: italic;
  font-size: 11px;
}

.editor-add-panel-search {
  margin: 0 -32px 0;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: stretch;
  min-height: min-content;
}

.editor-add-panel-search.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.editor-add-panel-search .material-icons {
  font-size: 20px;
  margin-left: 20px;
  margin-right: 10px;
}

.editor-add-panel-search input {
  flex: 1;
  border: none;
  margin: 10px 0;
  height: 40px;
  outline: none;
  font: inherit;
  font-size: 14px;
}

.editor-add-panel-search input::placeholder {
  color: #bdbdbd;
}

.editor-add-no-results {
  font-size: 16px;
  margin-top: 12px;
  text-align: center;
}

.editor-add-no-results .callout-card {
  margin-top: 16px;
}

/* Icons */
.editor-add-component-icon-blank-screen {
  background-image: url('./icons/blank-screen.png');
  background-position-x: center;
}

.editor-add-component-icon-blank-web-screen {
  background-image: url('./icons/blank-web-screen.png');
}

.editor-add-component-icon-menu-drawer {
  background-image: url('./icons/menu-drawer.png');
}

.editor-add-component-icon-text-input {
  background-image: url('./icons/text-input.png');
}

.editor-add-component-icon-image-upload {
  background-image: url('./icons/image-upload.png');
}

.editor-add-component-icon-file-upload {
  background-image: url('./icons/file-upload.png');
}

.editor-add-component-icon-date-picker {
  background-image: url('./icons/date-picker.png');
}

.editor-add-component-icon-select {
  background-image: url('./icons/select.png');
}

.editor-add-component-icon-webview {
  background-image: url('./icons/webview.png');
}

.editor-add-component-icon-form {
  background-image: url('./icons/form.png');
}

.editor-add-component-icon-text {
  background-image: url('./icons/text.png');
}

.editor-add-component-icon-ellipse {
  background-image: url('./icons/circle.png');
}

.editor-add-component-icon-section {
  background-image: url('./icons/rectangle.png');
}

.editor-add-component-icon-layout-section {
  background-image: url('./icons/layout-section.png');
}

.editor-add-component-icon-line {
  background-image: url('./icons/line.png');
}

.editor-add-component-icon-image {
  background-image: url('./icons/image.png');
}

.editor-add-component-icon-video {
  background-image: url('./icons/video.png');
}

.editor-add-component-icon-list {
  background-image: url('./icons/custom-list.png');
}

.editor-add-component-icon-table {
  background-image: url('./icons/table.png');
}

.editor-add-component-icon-pen {
  background-image: url('./icons/vector.png');
}

.editor-add-component-icon-location-input {
  background-image: url('./icons/location-input.png');
}

.global-search-results {
  .components-accordion-title {
    color: black;

    .components-count {
      border-color: gray;
    }

    .icon {
      background-color: black;
    }
  }
}

.global-search-empty-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #cccccc;
  padding-top: 24px;
  padding-bottom: 54px;

  .icon {
    background-color: #f3f3f3;
    width: 48px;
    height: 48px;
    mask-size: contain;
  }

  .global-search-empty-header {
    font-size: 14px;
    margin: 6px;
  }
}

.global-search-panel {
  .icon-close-big {
    height: 9px;
    width: 9px;
    margin: auto;
  }

  .icon-button-wrapper-rounded {
    background-color: #333333;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    margin-right: 20px;
  }
}

.prebuilt-section-item {
  width: 100%;
  margin: 12px 0;
}

.prebuilt-section-tags-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 8px 0;
}

.prebuilt-section-tag {
  background-color: #f3f3f3;
  padding: 4px 16px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 400;
  height: 20px;
  text-transform: capitalize;
  letter-spacing: 0;
  outline: none;
}

.prebuilt-section-unlisted-indicator {
  background-color: lightpink;
  padding: 4px 16px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 400;
  height: 20px;
  text-transform: capitalize;
  letter-spacing: 0;
  outline: none;
}

.global-search-results .template-item {
  margin-bottom: 31px;
}
