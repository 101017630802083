.toggle-checkbox {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}
