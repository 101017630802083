@import 'styles/_colors.scss';

.marketplace-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100%;
}

.marketplace-sidebar {
  min-width: 160px;
  margin-left: -12px;
  margin-top: 36px;

  h3 {
    margin-bottom: 8px;
  }
}

.marketplace-vertical-line {
  width: 1px;
  border-right: 1px solid $grey;
  margin: -40px 0;
}

.marketplace-filter-option {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 8px 0px 8px 14px;
  margin-left: -14px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: visible;

  &:not(.selected) {
    color: $filterLabel;
  }

  &.selected {
    background-color: $lightTeal;
    color: $teal;
    font-weight: 500;
  }

  &:hover:not(.selected) {
    background-color: $lightTeal;
    color: $teal;
    cursor: pointer;

    .icon {
      background-color: $teal;
    }
  }
}

.marketplace-filter-label {
  margin-left: 8px;
  font-size: 12pt;
}

.marketplace-feed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-right: -40px;
  padding: 40px 0;
  width: 100%;
  justify-content: center;
  align-content: flex-start;
  overflow: scroll;
  height: 100%;

  &-header {
    display: flex;
    width: 100%;
    margin: 0 42px;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    .btn {
      padding: 0 12px;
    }
  }

  > a {
    color: unset;
    text-decoration: none;
    border-radius: 16px;
    height: fit-content;
    margin-left: 3px;

    transition: box-shadow 150ms ease-in;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
  }

  .marketplace-item {
    position: relative;
    width: 336px;
    display: grid;
    gap: 16px;
    border: 1px solid $darkGrey;
    border-radius: 16px;
    padding: 16px 24px 0px;

    transition: box-shadow 150ms ease-in;

    &:hover {
      //box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    &-header {
      display: inline-flex;
      flex-direction: row;
      gap: 16px;
      margin-bottom: 8px;
      height: auto;
      align-items: center;
    }

    &-info {
      position: relative;
      align-self: flex-start;
      text-align: left;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 4px;
        display: -webkit-box;
        overflow: hidden;
        line-height: 1.1em;
        vertical-align: middle;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      p {
        font-size: 12px;
        color: #757575;
        display: -webkit-box;
        overflow: hidden;
        height: 3.6em;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      img {
        position: relative;

        height: 48px;
        width: 48px;

        border-radius: 50%;
        border: 1px solid $darkGrey;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
      align-items: center;

      > :last-child {
        margin-left: auto;
      }
    }
  }

  &-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-legacy {
    row-gap: 45px;
    padding: 40px 0;
    display: grid;
    justify-content: center;
  }
}

.marketplace-empty {
  justify-content: flex-start;
}
