@import 'styles/_colors.scss';

.db-assistant-panel {
  padding-bottom: 1rem;

  .right-panel-back {
    color: $data;

    &:hover {
      background-color: $mediumData;
    }

    .icon {
      background-color: $data;
    }
  }

  .left-panel-title {
    padding-top: 24px;

    .title {
      flex: 1;
      color: $labelDark;
    }

    .icon {
      background-color: $labelDark;
    }
  }

  .left-panel-section-title {
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 12px;
  }
}

.db-assistant-error {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: -8px;

  span {
    padding: 0 16px;
    color: #ef4c30;
    font-weight: bolder;
  }
}
