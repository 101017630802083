.new-plan-selection-header {
  margin-bottom: 20px;

  &__description {
    color: #333;
    font-weight: 500;
    font-size: 12px;
  }
}

.selection-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    max-height: calc(100vh - 230px);
    padding: 20px 40px;
  }

  &__header {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    margin: 0;

    &--light {
      font-weight: 500;
    }

    &--removed {
      font-weight: 500;
      font-style: italic;
      color: #cbcbcb;
    }

    &--right {
      display: flex;
    }
  }

  &__subheader {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin: 0;

    &--light {
      color: #9e9e9e;
    }

    &--removed {
      font-style: italic;
      color: #cbcbcb;
    }
  }

  .tooltip-trigger {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
}

.new-plan-selection-content {
  border: 1px solid #dedede;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 15px 30px;
  }

  &__count {
    padding: 15px 30px;
    background: rgba(0, 168, 152, 0.1);
    border-bottom: 1px solid #dedede;
  }

  &__total {
    padding: 15px 30px;
    background: rgba(0, 168, 152, 0.1);
  }

  &__count-row,
  &__total {
    display: flex;
    justify-content: space-between;
  }
}

.user-selection,
.app-selection {
  padding: 10px 30px 20px;
  overflow-y: scroll;
}

.app-selection {
  &__single {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__shared-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 45px;
  }

  &__toggle {
    margin-left: auto;
  }

  &__shared-header-title {
    font-size: 10px;
    text-transform: uppercase;
    margin: 0;
  }

  &__single-app {
    display: flex;
    align-items: center;
    padding: 10px 0;

    &--shared {
      position: relative;

      .app-icon {
        height: 30px;
        width: 30px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  .app-icon {
    margin-right: 10px;
  }

  &__connected {
    &:before,
    &:after {
      content: '';
      background-color: #d9d9d9;
      z-index: 1;
      margin-right: 10px;
    }

    &:before {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 50%;
    }

    &:after {
      width: 2px;
      height: 48px;
      position: absolute;
      top: 26px;
      left: 3px;
    }
  }

  &__published-icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;

    .icon {
      margin: 0;
      height: 12px;
      width: 12px;
      mask-size: contain;
    }
  }
}

.user-selection {
  &__user {
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
  }

  &__toggle {
    background: white;
    border: none;
    color: #00a898;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }
}
