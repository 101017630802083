.wrapped-entity-textarea {
  background: transparent;
  border: none;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.wrapped-entity-textarea .entity-textarea-block > span {
  user-select: text;
}
