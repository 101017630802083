@import 'styles/_colors.scss';

.over-limit-purchase-addon-modal {
  .modal-header {
    h1 {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .callout-card {
      border-color: #f2f2f2;
      border-width: 2px;
      background-color: #f6f6f6;

      &-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 26px 24px;

        > * {
          margin: 0;
          color: #424242;
        }

        > :last-child {
          margin-left: auto;
        }

        .addon-pricing,
        .addon-description {
          strong {
            font-size: 18px;
            font-weight: 600;
            color: #000000;
          }
        }

        .addon-description {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;

          span {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .icon-style-circle {
          height: 44px;
          width: 44px;
          .icon {
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }

  .addon-purchase-error {
    text-align: center;
    padding: 10px;
    color: $orange;
    background-color: $lightOrange;
    border: 1px solid transparent;
    font-size: 12px;
    font-weight: 500;
    border-radius: 9px;
    text-align: left;
    padding: 12px 15px;
  }

  .error-support-link {
    color: unset;
  }
}
