@import 'styles/_colors.scss';

$tablet-breakpoint: 1139px;
$mobile-breakpoint: 719px;
$vertical-scroll-breakpoint: 790px;
$first-column-width: 40%;
$first-column-max-width: 690px;

.onboarding-layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;

  .onboarding-column {
    height: 100vh;
    overflow: hidden;
  }

  .onboarding-column:first-of-type {
    width: $first-column-width;
    max-width: $first-column-max-width;
    background-color: $white;

    @media screen and (max-width: $tablet-breakpoint) {
      flex: 1;
      min-width: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      > * {
        max-width: 620px;
      }
    }
  }

  .onboarding-column:last-of-type {
    flex: 1;

    @media screen and (max-width: $tablet-breakpoint) {
      display: none;
    }
  }

  .onboarding-default-graphic-background {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('./assets/onboarding_background.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    * {
      user-select: none;
    }

    .onboarding-million-apps {
      width: 80%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 32px;
      }

      h2 {
        color: $white;
        font-size: 1.2rem;
        text-align: center;
      }
    }

    .onboarding-doodads {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      min-height: 10%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .upperdoodads {
      top: 0;
    }

    .lowerdoodads {
      bottom: 0;
    }
  }

  .onboarding-graphics__audience {
    background-color: #f8f4ef;
    background-image: url('./assets/onboarding-graphics-persona-all.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 100%;
    height: 100vh;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      background-color: rgba(255, 255, 255, 0);
    }

    &--selected {
      &:before {
        background-color: rgba(255, 255, 255, 0.75);
      }
    }

    img {
      position: absolute;
      z-index: 1000;
      width: 100%;
      height: 100vh;
      object-fit: contain;
    }
  }

  .onboarding-graphics-devices {
    width: 100%;
    height: 100%;
    background: #f7f3ee;
  }

  .onboarding-app-category-step {
    h3 {
      max-width: 572px;
    }
  }

  .onboarding-graphics-app-category {
    width: 100%;
    height: 100%;
    background-color: #f7f3ee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem 2rem;

    @media screen and (min-width: 1512px) {
      padding: 2rem 4rem;
    }

    &__app-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 21px;
      max-width: 70%;
      height: 18%;

      &__preview-icon {
        max-width: 76px;
        max-height: 76px;
        width: 100%;

        &--empty {
          border: 1px solid #e9e9e9;
        }

        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        border-radius: 22px;
        overflow: hidden;
      }

      &__preview-title {
        display: flex;
        flex-direction: column;
        gap: 6px;

        h3 {
          font-weight: 600;
          font-size: 24px;
          margin: 0;
          color: #333;
        }

        span {
          font-weight: 400;
          font-size: 16px;
          color: #757575;
          max-width: 100%;
        }
      }

      &--empty {
        color: #9c9c9c;

        > h3 {
          color: #9c9c9c;

          > span {
            color: #9c9c9c;
          }
        }
      }
    }

    &__app-preview-browser {
      width: 100%;
      background-color: $white;
      border-radius: 8px;
      overflow: hidden;
      border: 2px solid $grey;
      max-width: 1320px;

      header {
        background-color: $grey;
        height: 28px;
        width: 100%;

        display: flex;
        align-items: center;
        gap: 6px;
        padding: 0 8px;

        .browser-buttons-graphic {
          display: flex;
          align-items: center;
          flex: 1;
          position: relative;

          &__left-side {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 13px;

            &__circles {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;

              .button-circle {
                width: 10px;
                height: 10px;
                border-radius: 100px;
                background-color: $white;
                border: 1px solid $darkGrey;
              }
            }

            &__arrows {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;

              .button-arrow {
                width: 10px;
                height: 10px;
                border-radius: 100px;
                background-color: $darkerGrey;
              }
            }
          }

          &__address-bar {
            flex: 1;
            background-color: $white;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            height: 14px;
            max-width: 320px;

            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;

            font-size: 8px;
            font-weight: 600;
            line-height: 7px;
            letter-spacing: 0em;
            text-align: center;
            color: #ccc;
          }
        }
      }

      section {
        flex: 1;
        width: 100%;
        height: 100%;
        max-height: 680px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 46px;
        padding: 142px 88px;
        overflow: hidden;

        @media screen and (max-height: 800px) {
          transform: scale(0.9);
        }
        @media screen and (max-height: 700px) {
          transform: scale(0.8);
        }
        @media screen and (max-height: 600px) {
          overflow: hidden;
          justify-content: center;
          align-items: flex-start;
          padding-top: 20px;
        }

        @media screen and (max-width: 1512px) {
          padding: 40px 60px 80px;
        }

        .wrapped-input {
          width: 100%;
          height: 100%;
          margin: 0;

          &-inner-wrapper {
            border-radius: 6px;
            padding: 8px;
          }

          label {
            line-height: normal;
            color: #333;
            margin-left: 0;
          }

          input {
            height: 20px;
            font-size: 0.825rem;
            color: #ccc;
          }
        }

        @media screen and (max-width: 1512px) {
          .wrapped-input {
            label {
              font-size: 0.75rem;
            }

            input {
              height: 10px;
              font-size: 0.75rem;
              color: #ccc;
            }
          }

          .btn {
            font-size: 0.75rem;
            height: 32px;
          }
        }
      }
    }

    &__app-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3rem;

      pointer-events: none;

      padding: 1rem;
      position: relative;
      min-width: 805px;
      min-height: 549px;
      width: 100%;
      height: 100%;

      &__banner {
        border-radius: 8px;
        object-fit: cover;
        width: 338px;
        height: 399px;
      }

      &__form {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 310px;
        min-width: 205px;

        &__inputs {
          display: flex;
          flex-direction: column;
          gap: 4px;
          pointer-events: none;

          .wrapped-input {
            label {
              margin-top: 2px;
            }
          }

          .wrapped-input-inner-wrapper {
            margin-bottom: 4px;
          }
        }

        &__login-link {
          width: 100%;
          text-align: center;
          font-size: 11px;
          p {
            padding: 0 2px;
            display: inline-block;
            background: rgba(0, 168, 152, 0.1);
            color: #006b5a;
            font-weight: 500;
          }
        }

        .btn {
          height: 34px;
        }

        &__title {
          display: flex;
          flex-direction: column;
          gap: 5px;

          p {
            color: $darkerGray;
            font-size: 0.65rem;
          }

          h4 {
            margin: 0;
            margin-bottom: 2px;
            font-size: 1.25rem;
            font-family: 'Libre Baskerville', serif;
            font-weight: 600;
            color: $labelDark;
          }
        }
      }
    }

    &__disclaimer {
      color: #9c9c9c;
      font-size: 12px;
      font-weight: 400;
      transition: opacity 0.2s;
      padding-top: 20px;

      &--hidden {
        opacity: 0;
      }
    }
  }

  .onboarding-graphics__sample-data {
    background-color: #f7f3ee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 931px;
      max-height: 701px;
    }

    &__table {
      width: 85%;
      height: auto;
      background-color: $white;
      border-radius: 12px;
      border: 1px solid #ccc;
      max-width: 1390px;
      max-height: 90%;
      overflow: hidden;

      &__title {
        padding: 2rem 4rem;
        color: $labelDark;
        transition: background-color 0.2s;
        display: flex;
        justify-content: space-between;

        &--selected {
          background-color: $lightRed;
        }

        h1 {
          font-size: 24px;
          margin: 0;
        }
      }

      &__header {
        padding: 1rem 4rem;
        display: flex;
        align-items: center;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        gap: 0.5rem;

        &__item {
          flex: 1;
          font-size: 0.75rem;
          color: $labelDark;
          display: flex;
          gap: 0.75rem;
          align-items: center;
        }
      }

      &__body {
        padding-bottom: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 4px;

        transition: opacity 0.2s;

        &--placeholder {
          opacity: 0.5;

          .onboarding-graphics__sample-data__table__body__row__item--picture {
            background-color: #ccc;
            height: 50px;
            width: 100px;
            border-radius: 12px;
          }
        }

        &__row {
          flex: 1;
          font-size: 0.75rem;
          color: $labelDark;
          gap: 1rem;
          display: flex;
          padding: 0.75rem 4rem;
          border-bottom: 1px solid #cccccc75;

          max-width: 100%;

          &__item {
            flex: 1;
            padding-left: 0;
            display: flex;
            align-items: center;
            overflow: hidden;
            min-height: 50px;

            p {
              flex: 1;
              max-width: 300px;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;

              margin: 0;
              white-space: initial;
              line-height: normal;
              // Ellipsis overflow
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            }

            img {
              object-fit: cover;
              width: 40%;
              border-radius: 12px;
              height: 50px;
              width: 100px;
              filter: blur(0.5px);

              @media screen and (max-width: 1560px) {
                height: 50px;
                width: 100px;
              }
            }

            &--name {
              font-size: 0.875rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .onboarding-text-link {
    color: $teal;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.2s ease;

    &:hover {
      background-color: $lightTeal;
    }
  }
}

.adalo-logotype {
  width: 173px;
  height: 53px;
  content: url('../App/logo.svg');

  @media screen and (max-width: $mobile-breakpoint) {
    width: 65px;
    height: 65px;
    margin-left: auto;
    margin-right: auto;
    content: url('../App/logo_small.svg');
  }
}

.transcosmos-logotype {
  width: 173px;
  height: 53px;
  content: url('./assets/logo_transcosmos.svg');

  @media screen and (max-width: $mobile-breakpoint) {
    width: 85px;
    height: 85px;
  }
}

.onboarding-form-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 1rem;
  max-width: 620px;

  padding: 53px 65px 60px;

  h3 {
    color: $black;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
  }

  label {
    font-size: 0.875rem;
    line-height: normal;
  }

  .onboarding-audience-list,
  .select-list.appPlatform {
    .select-list-option-label {
      font-size: 16px;
      font-weight: 500;
    }

    .select-list-option-description {
      font-size: 14px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: $tablet-breakpoint) {
    padding: 42px 17px;

    .onboarding-audience-list {
      padding-bottom: 100px;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 92px 17px 42px;

    .adalo-logotype {
      display: none;
    }

    .transcosmos-logotype {
      display: block;
    }

    .onboarding-audience-list {
      padding-bottom: 200px;
    }
  }

  @media screen and (max-height: $vertical-scroll-breakpoint) {
    padding-bottom: 180px;
  }

  .progress-bar-container {
    width: 150px;

    @media screen and (max-width: $mobile-breakpoint) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      width: 100%;
      background: #f6f3ee;
      padding: 22px 98px;
    }
  }

  .onboarding-footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-height: $vertical-scroll-breakpoint) {
      position: fixed;
      width: $first-column-width;
      max-width: $first-column-max-width;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      background: $white;
      border-top: 1px solid #e9e9e9;
    }

    @media screen and (max-width: $tablet-breakpoint) {
      background: $white;
      width: 100%;
      max-width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      border-top: 1px solid #e9e9e9;
      height: fit-content;
    }

    .nav-buttons:only-child {
      margin-left: auto;
    }

    .nav-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: $mobile-breakpoint) {
        margin-left: auto;
      }

      *:nth-child(2) {
        margin-left: 16px;
      }
    }
  }
}

.onboarding-graphics-devices {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .onboarding-graphics-devices-layout {
    position: relative;
    width: 80%;
    height: 100%;
  }

  .onboarding-graphics-devices-group-layout {
    position: relative;
    width: 80%;
    height: 100%;
  }

  .onboarding-device-group-graphic {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 100%;
  }

  .onboarding-device-graphic {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 100%;
    max-height: auto;
    z-index: 3;
  }
}

@keyframes graphic-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes graphic-fade-out {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.7;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 0;
  }
}

.onboarding-graphic-transition-enter {
  animation: graphic-fade-in 0.6s 1;
}

.onboarding-graphic-transition-exit {
  animation: graphic-fade-out 1s 1;
  display: none;
}
