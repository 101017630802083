@import 'styles/_colors.scss';

.shortcuts-menu {
  display: flex;
  width: 330px;
  margin: 0 -16px 23px;
  gap: 6px;

  &__shortcut {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;

    border-radius: 4px;
    cursor: pointer;
    transition: all 0.1s;
    flex-grow: 1;
    background-color: $white;

    &:hover {
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
      border-color: $black;
    }

    &__tooltip__trigger {
      cursor: pointer;
      padding: 8px;
      padding-bottom: calc(8px - 2.73px);
    }

    &__tooltip {
      margin-top: 5px;

      &__text {
        display: flex;
        gap: 12px;

        span {
          color: rgba(255, 255, 255, 0.7);
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }
      }
    }
  }
}
