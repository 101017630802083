.shape-editor-point {
  fill: #fff;
  stroke: #999;
  stroke-location: outside;
  stroke-width: 1;
}

.shape-editor-point.selected {
  stroke-width: 2;
}

.shape-editor-point-wrapper {
  cursor: default;
}

.shape-editor-point-target {
  fill: none;
  stroke: none;
}

.shape-editor-active-line, .shape-editor-line {
  fill: none;
  stroke: #999;
  stroke-width: 1;
  pointerEvents: none;
}
