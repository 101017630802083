@import 'styles/_colors.scss';

.components-accordion:last-of-type {
  margin-bottom: 15px;
}

.components-accordion-title {
  height: 50px;
  font-size: 15px;
  color: #424242;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  transition: background 0.2s;
}

.components-accordion-title-inner {
  flex: 1;
  padding-left: 8px;
}

.components-accordion-title .components-count {
  border: 1px solid #e0e0e0;
  padding: 0px;
  width: 31px;
  height: 31px;
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.components-accordion-title:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.components-accordion-expanded .components-accordion-title {
  color: #00a898;
}

.components-accordion-expanded .components-accordion-title .components-count {
  border: 1px solid #00a898;
}

.components-accordion-expanded .components-accordion-title .icon {
  background-color: #00a898;
}

.components-accordion-expanded .components-accordion-children {
  border-bottom: 1px solid $lightGrey;
}

.components-accordion .accordion-children {
  padding: 16px;
}

.components-accordion-children {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > :nth-last-child(-n + 2) {
    .editor-add-component-item {
      margin-bottom: 8px;
    }
  }
}

.components-accordion-children.screen-templates {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
}

.components-accordion {
  .marketplace-cta-wrapper {
    display: grid;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 8px;

    border: 1px dashed $darkGrey;
    text-align: center;

    width: 100%;
    padding: 16px 32px;
    border-radius: 6px;

    .marketplace-cta-content {
      display: grid;
      gap: 16px;

      a {
        justify-self: center;
      }
    }

    h2 {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}
