@import 'styles/_colors.scss';

.hover-selection-box {
  fill: none;
  stroke: #ffc100;
  stroke-width: 1px;
  pointer-events: none;
}

.hover-selection-box.teal {
  stroke: $teal;
}

.hover-selection-box.thick {
  stroke-width: 2px;
  fill: rgba(255, 255, 255, 0.1);
}

.hover-selection-box.child-object-bounding-box {
  stroke: #ffc100;
}

.hover-selection-box.possible-parent-bounding-box {
  fill: none;
  stroke: $teal;
  stroke-width: 1px;
  stroke-dasharray: 5 2;
}

.data-bindings-hover .hover-selection-box {
  stroke: #ffc100;
}

.hover-selection-name {
  fill: #ffc100;
  font-size: 10px;
}
