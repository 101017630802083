@import 'styles/_colors.scss';

.generic-error {
  &__toast {
    display: flex;
    background-color: $black;
    color: $white;
    width: 540px;
    margin-bottom: 32px;
    font-family: 'Work Sans', sans-serif;
    right: 14rem;

    .btn {
      text-wrap: nowrap;
    }

    &-body > div {
      display: flex;
      align-items: center;
      gap: 2rem;

      h2 {
        font-size: 16px;
        font-weight: 600px;
        margin-bottom: 4px;
        max-width: 100%;
        text-wrap: wrap;
      }

      p {
        font-size: 13px;
      }
    }

    .Toastify__close-button {
      display: none;
    }
  }
}
