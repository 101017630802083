@import 'styles/_colors.scss';

.app-details-data-section {
  border: 0px solid transparent;
  border-width: 1px 0;
  position: relative;
  height: 100%;
}

.app-details-data-section-header {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
}

.app-details-data-section-header h3 {
  min-height: 1.3em;
  line-height: 1.3em;
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  color: #000;
  flex: 1;
}

.app-details-data-section-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow: auto;
  padding-left: 20px;
}

.data-modal-search {
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-radius: 20px;

  &.expanded {
    padding: 0 8px;
    background-color: white;

    input {
      display: block;
      border: none;
      background-color: transparent;
      margin-left: 8px;
      font-size: 14px;
      font-family: inherit;

      &:focus {
        outline: none;
      }
    }

    .icon-button-wrapper {
      pointer-events: none;
      cursor: default;
      background-color: transparent;
    }
  }

  input {
    display: none;
  }
}
