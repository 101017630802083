@import 'styles/_colors.scss';

.edit-bar,
.edit-bar-backdrop {
  position: fixed;
  left: 71px;
  top: 64px;
  bottom: 0;
  width: 360px;
  transition: all 0.4s;
}

.edit-bar-analytics,
.edit-bar-backdrop {
  position: fixed;
  left: 71px;
  top: 64px;
  bottom: 0;
  width: 800px;
  z-index: 10;
  transition: all 0.4s;
}

.offline-banner-visible .edit-bar,
.offline-banner-visible .editor-bar-backdrop {
  top: 96px;
}

.edit-bar {
  z-index: 2;
}

.edit-bar-empty {
  transform: translateX(-361px);
  pointer-events: none;
}

.edit-bar-backdrop {
  width: 222px;
  z-index: 0;
}

.edit-bar .panel-body {
  padding: 24px 32px 44px;
  position: relative;

  .geolocation-trial-prompt {
    margin-top: 35px;
  }
}

.edit-bar .tabbar {
  position: absolute;
  left: 0;
  top: 110px;
  right: 0;
  bottom: 0;
}

.edit-bar .tabbar .tabbar-content {
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.editor-app-unpaid .edit-bar {
  bottom: 44px;
}

/* Panel Content */
/* Titles */

.left-panel-title {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #333;
}

.left-panel-title .icon {
  margin-right: 12px;
  background-color: #333;
}

/* Branding */

.app-branding-panel .left-panel-title {
  color: #a82058;
  width: 297px;

  .title {
    flex: 1 1;
  }
}

.app-branding-panel .left-panel-title .icon {
  background-color: #a82058;
}

/* Settings */

.app-settings-panel .left-panel-title {
  color: #43437a;
}

.app-settings-panel .left-panel-title .icon {
  background-color: #43437a;
}

/* Screens */

.layers-wrapper {
  display: flex;
  flex-direction: column;

  .left-panel-title {
    color: #00a898;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -16px 0 0;

    .icon {
      background-color: $data;
    }

    .title {
      flex: 1;
    }
  }

  .left-panel-title .icon {
    background-color: #00a898;
  }
}

/* Publish */
.publish-panel {
  .left-panel-title {
    color: #a1cd46;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon {
      background-color: #a1cd46;
    }

    .toggle {
      margin-left: auto;
    }
  }

  .callout-card {
    margin: 16px -16px;
    width: calc(100% + 32px);
    text-align: left;

    &-content {
      p {
        color: #818181;
      }
    }
  }
}

.left-panel-header {
  display: flex;
  flex-direction: column;
  gap: 8px;

  border-bottom: 1px solid $border;
  margin: 0 -32px;
  padding: 0 32px 24px;

  &-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    color: $green;

    > * {
      margin: 0;
    }

    h2 {
      font-size: 16px;
    }

    .toggle {
      margin-left: auto;
    }
  }

  &-subtext {
    font-size: 14px;
    color: #818181;
  }
}

.left-panel-title {
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 0;

  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid $border;
    margin: 0 -32px;
    width: calc(100% + 64px);

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.edit-bar-add {
  & .panel-body {
    padding-bottom: 0;
  }
}
