.deep-linking-form {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.redirection-and-fallback {
  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.75rem;
  }

  &__title {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
    color: #000;
  }

  &__description {
    margin: 0.25rem 0 0;
    font-size: 0.7rem;
    color: #666;
    line-height: 1.4;
  }

  &__switch-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .switch {
    position: relative;
    width: 44px;
    height: 24px;
    background-color: #ccc;
    border-radius: 12px;
    transition: background-color 0.2s ease;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%;
      transition: transform 0.2s ease;
    }

    &.switch--on {
      background-color: #1fd16a;

      &::before {
        transform: translateX(20px);
      }
    }
  }
}
