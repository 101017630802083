.loading-expanded {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading-icon {
  background: url('./loader-icon@2x.png') no-repeat center;
  background-size: contain;

  &-teal {
    background: url('./loader-icon@2x-teal.png') no-repeat center;
    background-size: contain;
  }

  &-white {
    background: url('./loader-icon@2x-white.png') no-repeat center;
    background-size: contain;
  }

  display: block;
  width: 44px;
  height: 44px;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loading-small .loading-icon {
  width: 22px;
  height: 22px;
}
