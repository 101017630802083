@mixin form-style {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h1 {
    margin-bottom: 24px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: start;

      div,
      label {
        width: 100%;
      }

      label {
        color: $label;
      }

      .wrapped-select-error-message {
        margin: 0;
      }
    }
  }
}
