@import './cardStyles';

.Stars {
  width: 30px;
  height: 33px;
  fill: none;

  // Default
  .Stars-star {
    fill: #000000;
  }
}

@each $cardStyle in $cardStyles {
  .Stars--#{$cardStyle} {
    .Stars {
      &-star {
        fill: titleColor($cardStyle);
      }
    }
  }
}
