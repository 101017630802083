.text-align-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: -8px;
}

.text-align-control .text-align-item {
  padding: 1px 2px 0 2px;
  margin: -4px 2px -4px 0;
  display: block;
  border-radius: 4px;
  cursor: pointer;
}

.text-align-control .text-align-item.selected {
  background: #ddd;
}

.text-align-control .text-align-item .icon {
  background: #9a9a9a;
}

.text-align-control .text-align-item:hover .icon {
  background: #666;
}

.text-align-control .text-align-item.selected .icon {
  background: #444;
}
