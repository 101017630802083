@import 'styles/_colors.scss';

.editor-add-panel-tabs + .library-inspect {
  margin-top: 16px;

  & > .library-non-accordion-items {
    margin-top: 32px;
  }
}

.library-inspect > .library-non-accordion-items {
  margin-top: 16px;
}

.library-inspect-accordion {
  position: relative;
  transition: all 0.2s;
  margin: 0 -32px;

  > .accordion-title {
    padding: 12px 32px;
    position: relative;
    transition: background 0.2s;
    font-size: 14px;
  }

  > div > .accordion-children {
    padding: 10px 32px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  > .accordion-title:hover {
    background: rgba(0, 0, 0, 0.03);
  }

  > .accordion-title:active {
    background: rgba(0, 0, 0, 0.06);
    transition: none;
  }

  & .accordion-title > .icon {
    margin-right: 8px;
  }

  &.accordion-expanded:not(:first-child):before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -32px;
    right: -32px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.library-inspect-accordion-disabled {
  .accordion-title {
    color: #8a8a8a;
    font-style: italic;

    .icon {
      background: #8a8a8a;
    }
  }
}

.right-panel-collapsible
  + div
  > .library-inspect-accordion.accordion-expanded:first-child:before {
  display: none;
}

.library-inspect-panel-row {
  margin-top: 20px;

  & + .inspect-panel-row {
    margin-top: 5px;
  }

  & .inspect-panel-row-title {
    width: auto;
    flex: 1;
  }

  & .inspect-panel-row-body {
    flex: unset;
  }
}

.library-inspect-action {
  .action-item {
    padding: 0;
    background: transparent;
    border: none;
  }

  .action-actions-list {
    margin-left: 0;
    margin-right: 0;
  }

  .action-item-header {
    display: none;
  }
}

.library-inspect-section-title {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.library-inspect-title-text {
  flex: 1;
}

/* legacy generic selector for all panel items */
.library-inspect * + .bindable-text-control,
* + .library-inspect-menu-control,
:not(.right-panel-header) + .library-inspect-text-control,
* + .library-inspect-action,
* + .library-image-control,
* + .library-video-control,
* + .library-table-control,
* + .library-inspect-icon-row,
* + .bindable-text-control,
.library-inspect-menu-control + *,
.library-inspect-text-control + *,
.library-inspect-action + *,
.library-image-control + *,
.library-video-control + *,
.library-table-control + *,
.library-inspect-icon-row + * {
  margin-top: 16px;
}

.library-inspect {
  & > .bindable-text-control,
  & > .library-inspect-menu-control,
  & > .library-inspect-text-control,
  & > .library-inspect-action,
  & > .library-image-control,
  & > .library-video-control,
  & > .library-table-control,
  & > .library-inspect-icon-row {
    margin-top: 16px;
  }
}

.editor-add-panel-tabs + .library-inspect {
  & > .bindable-text-control,
  & > .library-inspect-menu-control,
  & > .library-inspect-text-control,
  & > .library-inspect-action,
  & > .library-image-control,
  & > .library-video-control,
  & > .library-table-control,
  & > .library-inspect-icon-row {
    margin-top: 32px;
  }
}

/* generic selector for all panel items */
.library-inspect .accordion-children > *:not(:first-child) {
  margin-top: 16px;

  &.custom-list-column-tablet,
  &.custom-list-column-desktop {
    margin-top: 8px;
  }
}

.library-inspect-icon-row .material-icon-menu,
.library-inspect-text-control-input-wrapper,
.library-inspect-menu-control .multi-select-menu-selection,
.library-inspect-action .empty-state .multi-menu-trigger-element {
  font-size: 14px;
  background: #f1f1f1;
  margin: 6px -16px 0 -16px;
  padding: 5px 16px;
  border: none;
  text-align: left;
  align-items: stretch;
  border-radius: 6px;
  transition: 0.2s all;
  resize: none;
}

.library-inspect-menu-control .multi-select-menu-selection:hover,
.library-inspect-text-control-input-wrapper:hover,
.library-inspect-icon-row .material-icon-menu:hover,
.library-inspect-action .empty-state .multi-menu-trigger-element:hover,
.library-inspect-action
  .empty-state
  .multi-menu-trigger.expanded
  .multi-menu-trigger-element {
  background: #e5e5e5;
}

.library-inspect-text-control-input-wrapper {
  padding: 0;
}

.library-inspect-text-control-input {
  display: block;
  outline: none;
  width: 100%;
  background: transparent;
  border: none;
  padding: 14px 16px;
  height: 44px;
  font: inherit;
  resize: none;

  &.disabled {
    color: #9e9e9e;
    opacity: 0.5;
  }
}

.library-inspect-action {
  & .empty-state .multi-menu-trigger-element {
    padding: 12px 20px;
    line-height: 20px;
  }

  & .empty-action-items {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 10px;
  }

  & .action-item {
    margin-left: -16px;
    margin-right: -16px;
  }

  & .action-item-form-item {
    border-radius: 6px;
  }

  & .actions-list-add-button {
    margin: -2px 16px 0;
  }
}

.action-row-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .icon {
    height: 18px;
    width: 18px;
    background-color: #555555;
  }
}

.click-actions-accordion {
  margin-top: 30px;
  margin-bottom: 16px;
}

.click-actions-accordion.accordion-expanded {
  background-color: #f3f3f3;
  border-radius: 6px;
  margin-left: -16px;
  margin-right: -16px;
}

.click-action-header {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -8px;
  margin-bottom: -8px;
}

.click-actions-accordion.accordion-expanded .accordion-title {
  background-color: #eeeeee;
  padding: 14px 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-top: -14px;
}

.click-action-subdivider {
  width: 250px;
}

.click-action-subdivider + .click-action-subdivider {
  width: 30px;
  margin: 0px;
}

.click-actions-option {
  background-color: #f5f5f5;
  padding: 8px 4px 10px;
  margin-left: -32px;
  color: #757575;
  text-align: center;
}

.instant-nav-toggle {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 6px;
  margin: 10px;
  padding: 16px;
  color: #424242;
  font-size: 14px;
  text-align: left;
}

.library-inspect-menu-control {
  & .multi-select-menu {
    margin: 5px -16px 0 -16px;
    height: 44px;
  }

  & .multi-select-menu-selection {
    margin: 0;
    line-height: 34px;
    box-shadow: none;
    padding-right: 40px;
  }

  & .multi-select-menu.expanded .multi-select-menu-selection {
    background: #fff;
    border-radius: 6px;
  }

  .library-inspect-menu-control-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tooltip-trigger {
      height: 24px;
    }
  }

  & .multi-select-menu-expand-icon:before {
    right: 26px;
    mask-size: auto;
  }
}

.library-inspect-list-filters {
  margin-top: 0;

  & a {
    color: $placeholder;
  }

  & .filter-label {
    color: $filterLabel;

    &.logic {
      font-weight: 500;
    }
  }

  & > .filter-label.logic {
    margin: 16px 0;
  }
}

.library-inspect-filter-item {
  background: #f1f1f1;
  border-radius: 4px;
  margin: 8px -16px;
  padding: 10px 16px;
}

.library-inspect-filter-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.active-filter-item {
    & .filter-label {
      color: $labelDark;
    }
  }
}

.library-inspect-filter-item .filter-options {
  padding: 0;
  background: transparent;
  border: none;
}

.library-inspect-filter-item-delete {
  cursor: pointer;
  background: #9e9e9e;
  transition: background-color 0.2s;

  &:hover {
    background: #7f7f7f;
  }
}

.library-inspect-list-filters .selectable-input-input {
  padding-left: 8px;
}

.library-inspect-list-filters-add {
  margin: 4px -16px 0;
  display: block;

  &.btn-fits-parent-width {
    margin: 4px 0;
  }

  .btn-big-margin-top {
    margin-top: 8px;
  }
}

.library-inspect-filter-item .library-inspect-list-filters-add {
  margin: 0;
  display: block;
}

.library-inspect-error {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  margin-right: 8px;

  h2 {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    color: $labelDark;
  }

  button {
    margin-top: 8px;
  }
}

.library-contact-box {
  margin-top: 24px;

  .library-link {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;

    color: $black;
    font-size: 14px;
    font-weight: 500;

    cursor: pointer;

    .icon {
      opacity: 0;
    }

    &:hover {
      font-weight: 600;

      .icon {
        opacity: 1;
      }
    }
  }

  p {
    color: $filterLabel;
    margin-bottom: 12px;
  }

  a {
    text-decoration: none;
  }
}
