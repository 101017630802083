.formatted-text {
  text-align: left;
}

* + .formatted-text h1,
* + .formatted-text h2,
* + .formatted-text h3,
* + .formatted-text h4,
* + .formatted-text h5,
* + .formatted-text h6 {
  margin-top: 1em;
}

.formatted-text p {
  margin-bottom: 1em;
}
