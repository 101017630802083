.web-publish-settings {
  margin: 0px -16px 16px -16px;

  .tooltip-header h2 {
    margin-top: 16px;
  }
}

.web-publish-settings h2 {
  margin: 0 16px 16px;
}

.web-publish-settings h2 + .mobile-publish-summary {
  margin-top: 8px;
}

.web-publish-settings h2,
.web-publish-web-settings-showing h2 {
  margin-top: 24px;
  font-weight: 600;
}
