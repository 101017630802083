.field-list {
  .field-list__label {
    display: block;
    margin-left: 16px;
    margin-bottom: 10px;
  }

  .field-list__row {
    display: block;
    margin-bottom: 16px;
  }
}
