.apps-page {
  padding: 40px 50px;
  margin: 0 auto;
  height: calc(100vh - 64px);
}

.apps-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.apps-page-header h1 {
  margin: 0;
}

.app-list {
  margin: 0 -10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-item {
  color: inherit;
  text-decoration: none;
  padding: 20px 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  transition: all 0.2s;
  border: 2px solid transparent;
  margin: 10px;
  width: calc(100% - 20px);
}

@media (min-width: 520px) {
  .app-item {
    width: calc(50% - 20px);
  }
}

@media (min-width: 790px) {
  .app-item {
    width: calc(33.333333% - 20px);
  }
}

@media (min-width: 1000px) {
  .app-item {
    width: calc(25% - 20px)
  }
}

@media (min-width: 1300px) {
  .app-item {
    width: calc(20% - 20px)
  }
}

.app-item-updated {
  color: #8a8a8a;
}

.app-item:hover {
  background: rgba(0, 0, 0, 0.01);
  border-color: #fafafa;
}

.app-item h2 {
  margin: 0;
  flex: 1;
}

.new-app-button {
  background: #00a898;
  color: #fff;
  padding: 10px 20px 10px 15px;
  border-radius: 100px;
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.new-app-button .icon {
  background: #fff;
  margin-right: 10px;
}

.app-item-menu {
  display: block;
  margin-left: 10px;
}

.app-list .empty-state {
  margin: 40px 10px;
  padding: 40px 20px;
  border: 2px dashed #ddd;
  border-radius: 13px;
  flex: 1;
}

.apps-page .empty-state h3 {
  margin: 0;
}

.app-item .app-item-name {
  margin: 10px 0 4px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
