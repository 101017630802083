.collection-permissions-modal {
  .modal-content {
    padding: 13px 0 0 0;
  }

  .collection-permissions-modal__content {
    .collection-permissions-modal__current-tab-content {
      padding: 26px 23px;

      height: calc(100vh - 350px);
      max-height: 450px;
      overflow-y: auto;
    }
  }
}

.permissions-page-tab-label {
  font-size: 16px;
  font-weight: 500;
}
