@import 'styles/_colors.scss';

.legacy-external-users {
  .external-users {
    &-title {
      font-weight: bold !important;
      font-size: 22px !important;
    }

    &-body {
      text-align: center;
      padding-left: 175px;
      padding-right: 175px;
      h1 {
        font-weight: 500 !important;
        margin-bottom: 8px;
      }
    }

    &-subtitle {
      font-size: 14px;
      color: #7a7a7a;
      margin-bottom: 8px;
    }

    &-text-body {
      font-size: 14px;
      color: #bababa;
    }

    &-btn-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button,
      a {
        margin-top: 16px;
        span {
          font-weight: bold !important;
        }
      }
    }
  }
}
