@import 'styles/_colors.scss';

@mixin display-flex() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.external-users-modal {
  max-width: 880px;

  &.colored {
    .modal-content {
      background-color: #fcfcfc;
    }
  }

  .modal {
    &-header {
      @include display-flex();

      > div {
        @include display-flex();
        gap: 20px;
        margin: 0;

        h1 {
          margin: 0;
        }
      }

      a {
        font-size: 12px;
        font-weight: 600;
        color: #00a898;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0.2em;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background: rgba(0, 168, 152, 0.2);
        }
      }
    }

    &-content {
      .api-url-input {
        display: flex;
        align-items: flex-end;
        gap: 22px;

        button {
          margin-bottom: 6px;
        }

        margin-bottom: 46px;
      }

      .wrapped-input {
        flex: 1;
        margin: 0;

        &-suffix {
          @include display-flex();

          border-radius: 4px;
          padding: 0 11px;
        }

        label {
          color: $labelDark;
          font-size: 14px;
        }

        &-inner-wrapper {
          padding: 0;
          border-radius: 4px;
          margin-top: 11px;
        }

        &-input {
          padding: 0 14px;
          border-radius: 4px;

          input {
            height: 44px;
          }
        }
      }

      .external-users-endpoints {
        > span {
          color: $labelDark;
          font-size: 14px;
          margin: 0 16px 6px;

          opacity: 0;
          transition: opacity 0.5s ease;

          &.active {
            opacity: 1;
          }
        }

        display: flex;
        flex-direction: column;
        gap: 10px;

        .accordion {
          border-radius: 4px;
          color: $white;
          font-weight: bold;
          font-size: 16px;
          opacity: 0;
          transition: opacity 0.5s ease;
          pointer-events: none;

          &.active {
            pointer-events: all;
            opacity: 1;
          }

          &.select:not(.selected) {
            .accordion-title {
              color: #1a3448;
              background-color: #e8e8e8;

              .icon {
                background-color: #1a3448;
              }
            }
          }

          &-title {
            display: flex;
            border-radius: 4px;
            background-color: #1a3448;
            padding: 12px 20px;
            transition: border-radius 0.5s ease;

            > div {
              @include display-flex();

              flex: 1;

              h3 {
                margin: 0;
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
              }
            }

            > .icon {
              background-color: $white;
            }
          }

          &.accordion-expanded {
            .accordion-title {
              border-radius: 4px 4px 0 0;
            }
          }

          &-children {
            background-color: rgba(26, 52, 72, 0.05);
            border-radius: 0 0 4px 4px;
            border: 1px solid rgba(26, 52, 72, 0.1);
            padding: 26px;

            display: flex;
            flex-direction: column;
            gap: 26px;

            .endpoints-container {
              display: flex;
              flex-direction: column;
              gap: 26px;
            }

            .endpoint {
              &-content {
                display: flex;
                flex-direction: column;
                gap: 25px;
              }

              &-signature {
                display: flex;
                flex-direction: column;
                gap: 6px;

                p.missing-endpoint {
                  color: $labelDark;
                }

                .endpoint-label {
                  font-size: 14px;
                  line-height: 16px;
                  font-weight: 500;

                  color: $labelDark;
                  background: transparent;

                  margin: 0 16px;
                }

                .signature-content {
                  &.error {
                    border: 1px solid $orange;
                  }

                  > div {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    gap: 14px;
                  }

                  @include display-flex();
                  padding: 12px 14px;
                  border-radius: 4px;
                  background: $white;

                  .endpoint-type {
                    margin: 0;
                    padding: 4px 8px;
                    color: $white;
                    border-radius: 4px;

                    &.post,
                    &.patch,
                    &.put {
                      background-color: #49cc90;
                    }

                    &.get {
                      background-color: #61affe;
                    }

                    &.delete {
                      background-color: #f93e3e;
                    }

                    &.notfound {
                      background-color: #f5f5f5;
                      color: #333;
                    }
                  }

                  .endpoint-path {
                    margin: 0;
                    color: $body;
                    font-weight: 500;
                  }

                  .external-api-url {
                    color: $placeholder;
                    font-weight: 400;
                    font-size: 14px;
                  }
                }
              }

              &-response {
                padding: 24px 26px;
                background: rgba(222, 133, 94, 0.3);
                border: 1px solid rgba(222, 133, 94, 0.3);
                border-radius: 4px;
                color: $body;

                .title {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  h3 {
                    font-size: 16px;
                    margin: 0;
                  }

                  .icon {
                    height: 34px;
                    width: 34px;
                  }
                }

                span.error-message {
                  font-size: 12px;
                  font-weight: 300;
                  display: none;
                  margin: 10px auto;

                  &.active {
                    display: block;
                  }

                  ul li {
                    margin: 10px 0 0 15px;
                  }
                }

                p {
                  margin-top: 13px;
                  word-break: break-all;
                  font-size: 12px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        &-item {
          display: flex;
          width: 100%;
          flex: 1;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;

          .toggle-checkbox {
            margin-top: 12px;

            .icon {
              background-color: #a5a5a5;
            }

            &[aria-checked='true'] {
              .icon {
                background-color: #1a3448;
              }
            }
          }

          .accordion {
            width: 100%;
          }
        }
      }
    }

    &-actions {
      .xano-help-docs-button {
        display: flex;
        align-items: center;
        gap: 8px;

        .icon {
          margin: 0;
        }
      }
    }
  }
}
