.color-dropdown__wrapper {
  display: flex;
  flex-direction: column;
}

.color-dropdown__wrapper label {
  margin-bottom: 5px;
  margin-left: 16px;
}

.color-dropdown {
  border-radius: 6px;
  margin: 0 -16px;
  overflow: hidden;
  background: url('./images/checkerboard.svg') 5px 5px;
}

.color-dropdown-inner-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px 10px 16px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.color-dropdown:not(.disabled) {
  cursor: pointer;
}

.color-dropdown {
  margin-top: 16px;
}

.color-dropdown-texts {
  flex: 1 1;
}

.color-dropdown-dark .color-dropdown-icon {
  background-color: #fff;
}
.color-dropdown-dark .color-dropdown-texts {
  color: #fff;
}

.color-dropdown-light .color-dropdown-icon {
  background-color: rgba(0, 0, 0, 0.87);
}
.color-dropdown-light .color-dropdown-texts {
  color: rgba(0, 0, 0, 0.87);
}

.color-dropdown-title {
  font-size: 12px;
  line-height: 15px;
}

.color-dropdown-hex {
  font-size: 14px;
  line-height: 18px;
}
