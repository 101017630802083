.right-panel-screen-template-collapsed {
  color: #00a898;
}

.right-panel-screen-template-collapsed .icon {
  background-color: #00a898;
}

.right-panel-screen-template .image-input-wrapper {
  width: 100%;
  margin: 15px -16px 0 -16px;
}

.right-panel-screen-template .image-input {
  width: calc(100% + 32px);
  height: 300px;
}
