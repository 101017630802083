.api-collection {
  padding: 20px 40px 80px;
  height: 100%;
  overflow-y: auto;
}

.api-collection-title {
  margin: 0;
}

.api-collection-title .wrapped-input-inner-wrapper {
  border-radius: 0;
  padding: 0;
  border-width: 0 0 2px 0;
}

.api-collection-title input {
  font: inherit;
  font-size: 32px;
  font-weight: 700;
}

.api-collection-form-fields {
  padding: 10px 0;
}

.api-collection-form-fields .accordion-children {
  padding: 20px 30px;
  background: #fafafa;
  border-radius: 10px;
  margin-left: 0;
}

.api-collection-fields .icon-trash-small {
  background: #bbb;
  cursor: pointer;
}

.api-collection-fields .delete-item {
  width: 24px;
}

.api-collection-fields li {
  display: block;
}

.api-collection-field-item-sub, .api-collection-fields-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.api-collection-fields-header {
  padding-right: 24px;
}

.api-collection-field-type {
  width: 220px;
  margin-right: 40px;
}

.api-collection-field-name {
  flex: 1;
  margin-right: 20px;
}

.api-collection-field-name input {
  outline: none !important;
  width: 100%;
  height: 26px;
  line-height: 22px;
  padding: 0 5px;
  border: 2px solid #ddd;
  border-radius: 4px;
}

.api-collection-endpoints {
  margin-top: 20px;
}

.api-collection-endpoint-item {
  background: #fafafa;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
}

.api-collection-endpoint-item-sub {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.api-collection-endpoint-name {
  flex: 1;
}

.api-collection-endpoint-item .wrapped-input {
  margin: 0;
  color: #000;
}

.api-collection-endpoint-description {
  flex: 2;
  color: #8a8a8a;
  margin: 0 0 0 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.api-collection-endpoint-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.api-collection-endpoint-path {
  flex: 1;
}

.api-collection-endpoint-details .wrapped-select {
  width: 180px;
}

.api-collection-endpoint-details .wrapped-input {
  flex: 1;
  margin: 0 40px 0 20px;
}

.api-collection-field-item-children {
  padding: 20px 30px;
  margin: 0 -30px 20px 0;
  background: rgba(0, 0, 0, 0.04);
}

.api-collection-endpoint-item-params {
  background: rgba(0, 0, 0, 0.04);
  margin-left: 20px;
  margin-right: -20px;
  padding: 10px 20px;
  margin-top: 10px;
}

.api-endpoint-param-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.api-endpoint-param-item .wrapped-select {
  width: 180px;
  margin-left: 40px;
}

.api-endpoint-param-item .wrapped-input {
  flex: 1;
  margin-bottom: 10px;
}

.api-primary-key-type {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.api-primary-key-type > * {
  flex: 1;
  margin: 0;
}
