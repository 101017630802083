.launch-component-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.launch-component-item h6 {
  margin: 0;
}

.launch-component-item p {
  font-size: 11px;
  opacity: 0.6;
}

.launch-component-item .icon {
  margin-right: 4px;
  background-color: #9a9a9a;
}
