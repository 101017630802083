.bounding-boxes .image-upload {
  pointer-events: all;
}

.image-upload-backdrop {
  fill: #eee;
}

.image-upload-object image {
  opacity: 0.2;
}
