.app-icon {
  width: 60px;
  height: 60px;
  border-radius: 13px;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.app-icon-small {
  width: 30px;
  height: 30px;
  border-radius: 7px;
}

.app-icon-medium {
  width: 40px;
  height: 40px;
  border-radius: 9px;
}

.app-icon-dark-background {
  box-shadow: none;
}

.app-icon-empty {
  background: #d1d1d1 url('./default-icon.svg') no-repeat center;
  background-size: 60%;
  box-shadow: none;
}
