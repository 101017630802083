.link-wrapper {
  .link-arrow {
    fill: #ddd;
  }

  .link-path {
    fill: none;
    stroke: #ddd;
  }
}
