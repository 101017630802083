.launch-component-indicator {
  position: fixed;
  z-index: 10000;
}

.launch-component-sub {
  position: absolute;
  right: 3px;
  top: -20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  cursor: move;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}

.launch-component-sub .icon {
  margin-left: 5px;
  background: rgba(0, 0, 0, 0.5);
}

.launch-component-sub:hover, .launch-component-wrapper-dragging .launch-component-sub {
  color: #00a898;
}

.launch-component-sub:hover .icon, .launch-component-wrapper-dragging .launch-component-sub .icon {
  background: #00a898;
}

.launch-component-dragging {
  opacity: 0.5;
  pointer-events: none;
}

.launch-component-highlight {
  position: fixed;
  box-shadow: 0 0 0 4px #00a898;
}
