.color-picker-wrapper {
  position: relative;
  height: 24px;
  min-width: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 3px;
  display: flex;
  background: #fafafa;
}

.color-picker-wrapper.single {
  max-width: 80px;
}

.color-picker-swatch {
  border-radius: 2px;
  flex: 1;
  cursor: pointer;
  display: flex;
  background: url('./images/checkerboard.svg') 5px 5px;
}

.color-picker-swatch-sub {
  flex: 1;
  border-radius: 2px;
}

.color-picker {
  position: fixed;
  margin-top: 5px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
  border-radius: 3px;
  z-index: 100004;
}

.color-picker > .sketch-picker {
  box-shadow: none !important;
}

.color-picker-close {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100003;
}

.color-picker-wrapper.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.color-picker input {
  width: 100% !important;
}
