@import 'styles/_colors.scss';

.selectable-input {
  flex-direction: row;
  background: #fff;
  height: 26px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  align-items: center;
  position: relative;
}

.selectable-input-menu-trigger {
  position: absolute !important;
  right: 4px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selectable-input-menu-trigger .icon {
  display: block;
}

.selectable-input-value {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 30px;
}

.selectable-input-selected-value {
  background: $orange;
  color: #fff;
  height: 22px;
  padding: 0 0 0 12px;
  border-radius: 100px;
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px;
  max-width: 100%;
}

.selectable-input-selected-value .icon {
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.selectable-input-selected-value .icon:hover {
  background: rgba(255, 255, 255, 0.7);
}

.selectable-input-menu-trigger .icon {
  background: #8a8a8a;
}

.selectable-input-value-text {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selectable-input-input {
  display: block;
  flex: 1;
  height: 26px;
  outline: none;
  border: none;
  background: transparent;
  padding-left: 12px;
  font: inherit;
  width: 100%;
}

.selectable-input-wrapper-boxed {
  background-color: white;
  border-radius: 6px;
  height: 44px;
  padding: 4px 16px 4px 12px;

  label {
    color: $label;
    margin-bottom: 4px;
  }

  .selectable-input {
    box-shadow: unset;
    background: unset;
    height: 100%;

    .selectable-input-value {
      height: inherit;

      input {
        padding-left: 0;
        height: inherit;
      }

      .selectable-input-selected-value {
        margin-left: 0;
        background-color: $orange;
      }
    }
  }

  &.selectable-input-wrapper-labeled {
    height: 60px;
    padding: 10px 16px 4px;

    .selectable-input {
      height: unset;

      .selectable-input-value {
        height: 34px;
        font-size: 14px;
      }

      .selectable-input-menu-trigger {
        top: 6px;
      }
    }
  }
}
