@import 'styles/_colors.scss';

$borderColor: #9c9c9c;

.template-item {
  position: relative;
  width: 326px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid $borderColor;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;

  transition: box-shadow 150ms ease-in;

  &--selected {
    border: 2px solid $teal;
  }

  &--selected &__checkmark {
    opacity: 1;
    background: $teal;
    border-style: inset;
    border: 2px solid $white;

    .icon {
      background: $white;
    }
  }

  img {
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid $darkGray;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  &:hover &__checkmark {
    opacity: 1;
  }

  &__header {
    display: inline-flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 8px;
    height: auto;
    align-items: center;

    h2 {
      color: #333;
      font-family: 'Work Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      margin: 0 0 4px;
      display: -webkit-box;
      overflow: hidden;
      vertical-align: middle;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }

  &__checkmark {
    background: #e0e0e0;
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 16px;
    width: 32px;
    height: 32px;
    opacity: 0;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 1;
  }

  &__info {
    position: relative;
    align-self: flex-start;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    p {
      color: #757575;
      font-family: 'Work Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      display: -webkit-box;
      overflow: hidden;
      height: 3.6em;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    align-items: center;
    padding: 0 16px;

    > :last-child {
      margin-left: auto;
    }

    &__screenCount {
      padding: 5px 10px;
      border-radius: 4px;
      background-color: $lightTeal;
      color: $teal;
    }
  }
}
