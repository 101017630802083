@import 'styles/_colors.scss';

.launch-screen-preview {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 224px;
  height: 100%;
  border-radius: 12px;
  border: 1px solid $darkGrey;

  .launch-screen-icon {
    width: 50%;
    padding-top: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.settings-form-launch-screen-wrapper {
  margin-left: -16px;
  margin-right: -16px;

  display: flex;
  align-items: stretch;
  justify-content: space-between;

  label {
    color: #666;
    margin-left: 16px;
    margin-bottom: 5px;
    display: block;
  }

  .settings-form-launch-screen-column {
    flex: 2;
    margin-right: 32px;

    display: flex;
    flex-direction: column;

    &:last-of-type {
      flex: 1;
      margin-right: 0;
    }

    .settings-form-row {
      margin-left: 0;
      margin-right: 0;
    }

    .settings-form-input,
    .color-dropdown {
      margin: 0;
    }

    .settings-form-error {
      margin-left: 16px;
    }
  }
}

.app-settings-permissions {
  margin: 0 -16px 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .accordion-title {
    padding: 16px;
  }

  .accordion-children {
    .library-inspect-text-control-input {
      border: unset;
    }

    > p {
      font-size: 14px;
      font-weight: 500;
      margin: 16px 0;
      padding-bottom: 8px;
    }

    .app-settings-permissions-example {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 16px 16px 32px 16px;
      font-size: 14px;
      line-height: 20px;

      span {
        &:last-of-type {
          margin-left: 16px;
          width: 100%;
        }
        p {
          &:first-of-type {
            font-weight: 700;
          }

          &:last-of-type {
            margin-bottom: 0;
            color: $body2;
          }
        }
      }

      &.good {
        .icon {
          background-color: $green;
        }
      }
      &.bad {
        .icon {
          background-color: $orange;
        }
      }
    }
  }
}

.form-copy-header {
  font-size: 14px;
  font-weight: bold;
}
.form-copy-body {
  font-size: 14px;
  font-weight: normal;
  color: $body2;
  margin: 4px 0 16px;
}

#IOSPublishSettings .form-copy-body:last-of-type {
  margin-top: 16px;
  margin-bottom: 0;
}

// Hacky styles for debug option (only seen by admins)
input[name='skipBuildEnqueue'] {
  position: absolute;
  left: 140px;
  top: 5px;
}
