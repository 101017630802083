.file-input-wrapper {
}

.file-input {
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.file-input-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 6px;

  height: 60px;
  padding: 16px;
}

.file-input-select {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  color: #333333;
  height: 60px;
  padding: 0 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.file-input-select:hover {
  border-color: #cccccc;
}

.file-input .loading {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
}

.file-input-filename {
  font-weight: 600;
}

.file-input-filename a.deemphasize {
  color: inherit;
  font-weight: 500;

  display: block;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input-links {
  display: flex;
  flex-direction: row;
}

.file-input-links a + a {
  margin-left: 20px;
  position: relative;
  display: block;
}

.file-input-links a + a:before {
  content: '•';
  position: absolute;
  left: -15px;
  width: 10px;
  text-align: center;
}
