.wrapped-radio {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.wrapped-radio label,
.wrapped-radio input {
  display: block;
}

.wrapped-radio label {
  cursor: pointer;
  flex: 1;
}

.wrapped-radio input {
  width: 20px;
}

.wrapped-radio-styled label {
  padding-left: 4px;
}

.wrapped-radio-styled input[type='radio'] {
  display: none;
}

.wrapped-radio-styled-bullet {
  border: 2px solid #616161;
  height: 18px;
  width: 18px;
  border-radius: 12px;
  cursor: pointer;
}

.wrapped-radio-styled-bullet-inner {
  height: 8px;
  width: 8px;
  border-radius: 6px;
  margin-top: 3px;
  margin-left: 3px;
}

.wrapped-radio-styled
  input[type='radio']:checked
  + .wrapped-radio-styled-bullet {
  border-color: #00a898;
}

.wrapped-radio-styled
  input[type='radio']:checked
  + .wrapped-radio-styled-bullet
  .wrapped-radio-styled-bullet-inner {
  background-color: #00a898;
}

.wrapped-radio.disabled {
  opacity: 0.5;
}

.wrapped-radio.disabled > * {
  cursor: not-allowed;
}
