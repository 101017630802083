.inline-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inline-form .wrapped-select .Select-input,
.inline-form .wrapped-select .Select-control {
  height: 26px;
}

.inline-form .wrapped-select .Select-placeholder {
  line-height: 26px;
}

.inline-form .wrapped-select .Select-control .Select-value {
  line-height: 26px;
}

.inline-form input {
}

.inline-form input[type="text"] {
  width: 100%;
  font: inherit;
  background: #fff;
  border: 2px solid #eee;
  outline: none;
  border-radius: 8px;
  padding: 0 8px;
  height: 32px;
}

.inline-form .multi-select-menu {
  height: 32px;
}

.inline-form .multi-select-menu .multi-select-menu-selection {
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 20px;
}

.inline-form > * {
  flex: 1;
}

.inline-form > * + * {
  margin-left: 15px;
}

.inline-form > .auto-width {
  flex: none;
}

.inline-form a.deemphasize {
  font-size: 11px;
}
