@import 'styles/_colors.scss';

.collections-list-item {
  .accordion-expanded {
    margin-bottom: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .accordion-title {
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
    font-size: 14px;
  }
}

.collections-list-item-title {
  flex: 1;
  font-size: 14px;

  .collections-list-item-title__subtitle {
    margin-top: 5px;
    font-size: 12px;
    opacity: 0.6;
  }
}

.collections-list-item-body {
  display: flex;
  gap: 16px;
}

.collection-action-policy {
  flex: 1;
  width: 0;
}

.collections-list-empty-state {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .collections-list-empty-state__graphic {
    width: 100px;
    margin-top: -5%;
    margin-bottom: 20px;
  }

  .collections-list-empty-state__message {
    margin: 14px;

    color: $subtitle;
    font-size: 20px;
    font-weight: bold;
  }

  .collections-list-empty-state__link {
    color: $teal;
    font-size: 16px;
    font-weight: bold;
  }
}
