.panel.edit-bar-experts .panel-body {
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(248, 244, 238, 1) 100%);
}

.experts-panel {
    margin: 12px -16px -32px;
}

.experts-panel-top-image {
  display: block;
  margin: 0 auto;
  border-radius: 6px;
}

.experts-panel-header-title {
  position: relative;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(180deg, #00A898 34.09%, #025C54 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 30px 0 10px;
}

.experts-panel-header-title:after {
    content: url('./assets/gray-sparkles.svg');
    bottom: -6px;
    right: -4px;
    position: absolute;
    width: 28px;
    height: 45px;
}

.experts-panel-header-text {
  width: 280px;
  margin: 10px auto 20px;
  text-align: center;
}

.browse-experts-button {
  position: relative;
  display: block;
  color: #000;
  background: #ffcd58;
  width: 130px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 12px 4px 0;
  margin: 0 auto;
  text-decoration: none;
  transition: all .2s;
}

.browse-experts-button:hover {
  background: #ffa300;
}

.browse-experts-button .icon-arrow-next {
  position: absolute;
  top: 7px;
  right: 10px;
  height: 8px;
  width: 8px;
}
