$panel-width: 360px;

$title-bar-height: 64px;
$inactive-color: aliceblue;
$active-color: antiquewhite;

.DebugPanel {
  position: absolute;
  z-index: 2;
  top: $title-bar-height;
  right: 0;
  height: calc(100% - #{$title-bar-height});

  // Default to collapsed state
  width: $panel-width;
  transform: translateX(#{$panel-width});
  background-color: $inactive-color;
  transition: transform 500ms ease-out, background-color 500ms;

  // Override app/editor styles that prevent selection of text in the editor.
  user-select: text;

  &:hover {
    // Nudge out on grip hover
    transform: translateX(calc(#{$panel-width} - 4px));
  }

  &.expanded {
    transform: translateX(0);
  }

  &.active {
    background-color: $active-color;

    .Grip {
      background-color: $active-color;
    }
  }

  .content {
    height: 100%;
    width: 100%;
    overflow: auto;

    padding: 8px 4px;

    h2 {
      margin-top: 10px;
      margin-bottom: 6px;
    }
  }

  .Grip {
    position: absolute;
    top: 30px;
    right: 100%;

    background-color: $inactive-color;
    cursor: pointer;

    // Label styling
    padding: 6px 4px;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;

    // Rotate the grip label -90deg
    writing-mode: vertical-lr;
    text-orientation: sideways;
    transform: rotate(180deg);
  }
}
