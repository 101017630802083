@import 'styles/_colors.scss';

.form-inspect-fields-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.form-inspect-more-title {
  margin: 30px 0 20px;
}

.form-inspect-fields-section + .form-inspect-fields-section {
  margin-top: 20px;
}

.form-inspect-field {
  margin: 0px -16px;
}

.form-inspect-field-wrapper .library-inspect-icon-row,
.form-inspect-field-wrapper .library-inspect-text-control,
.form-inspect-field-wrapper .library-inspect-menu-control,
.form-inspect-field-wrapper .bindable-text-control,
.form-inspect-field-wrapper .library-inspect-action .empty-state {
  position: relative;
}

.form-inspect-field-wrapper .library-inspect-icon-row > p,
.form-inspect-field-wrapper .library-inspect-text-control > p,
.form-inspect-field-wrapper .library-inspect-menu-control > p,
.form-inspect-field-wrapper
  .bindable-text-control
  .bindable-text-control-header
  > p,
.form-inspect-field-wrapper .library-inspect-action .empty-state > p {
  position: absolute;
  top: 10px;
  left: 16px;
  font-size: 11px;
  color: #8a8a8a;
  z-index: 1;
  z-index: 100000;
}

.form-inspect-field-wrapper .library-inspect-menu-control > .help-link {
  top: 50px;
}

.form-inspect-field-wrapper .library-inspect-icon-row .material-icon-menu,
.form-inspect-field-wrapper .library-inspect-text-control-input-wrapper,
.form-inspect-field-wrapper
  .library-inspect-menu-control
  .multi-select-menu-selection,
.form-inspect-field-wrapper .bindable-text-control .wrapped-entity-textarea,
.form-inspect-field-wrapper
  .library-inspect-action
  .empty-state
  .multi-menu-trigger-element {
  margin: 5px 0;
  padding-top: 16px;
  background: #fff;
  height: 60px;
}

.form-inspect-field-wrapper .bindable-text-control,
.form-inspect-field-wrapper .library-inspect-menu-control {
  padding: 0;
  margin-top: 15px;
}

.form-inspect-field-wrapper .bindable-text-control .wrapped-entity-textarea {
  height: auto;
  min-height: 60px;
  padding-top: 28px !important;
}

.form-inspect-field-wrapper .library-inspect-text-control-input-wrapper {
  height: auto;
}

.form-inspect-field-wrapper .library-inspect-text-control-input-wrapper input {
  padding-left: 16px;
}

.form-inspect-field-wrapper .bindable-text-control .entity-textarea-block {
  /* Using 15px instead of 16px because entity-textarea already adds 1px */
  padding-left: 15px;
  padding-right: 44px;
}

.form-inspect-field-wrapper
  .bindable-text-control
  .bindable-text-control-header {
  margin: 0;
}

.form-inspect-field-wrapper
  .bindable-text-control
  .bindable-text-control-header
  p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 50px;
}

.form-inspect-field-wrapper
  .bindable-text-control
  .bindable-text-control-trigger-wrapper {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  z-index: 1;
}

.form-inspect-field-wrapper .library-inspect-menu-control {
  padding: 0;
  margin-bottom: 5px;
}

.form-inspect-field-wrapper .autocomplete-input {
  margin: 5px 0;
  padding-top: 16px;
  background: #ffffff;
  border-radius: 6px;

  &__input-container {
    border-width: 0px;
    margin: 0px;

    &--elevated {
      box-shadow: none;
    }

    &--disabled {
      background: #ffffff;

      .autocomplete-input__input {
        background: #ffffff;
      }
    }
  }
}

.form-inspect-field-wrapper #fallback:first-child {
  .autocomplete-input__input-container {
    margin-top: -16px;
  }
}

.form-inspect-field-wrapper #fallback:first-child + .help-link {
  top: 34px;
}

.form-inspect-fields-help-text {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 11px;
  font-weight: 400;
  color: #757575;
}

.form-inspect-field-wrapper .settings-form-error {
  margin-left: 15px;
}

.form-inspect-field-wrapper .data-object-form-input-input {
  border-color: #ffffff;
}

.form-inspect-field-wrapper .library-inspect-menu-control .multi-select-menu {
  margin: 0;
  height: 60px;
}

.form-inspect-field-wrapper
  .library-inspect-menu-control
  .multi-select-menu-selection {
  margin: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-inspect-add-field .multi-menu-trigger-element {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-inspect-add-field {
  color: #00a898;
  transition: background 0.2s;
  margin: 0 -16px;
  border-radius: 4px;
  padding: 4px 16px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-size: 11px;
}

.form-inspect-add-field .icon {
  background: #00a898;
  margin-right: 8px;
}

.form-inspect-add-field:hover,
.form-inspect-add-field.expanded {
  background-color: rgba(0, 168, 152, 0.1);
}

.form-inspect-field:active,
.form-inspect-add-field.expanded:active {
  background-color: rgba(0, 168, 152, 0.2);
  transition: none;
}

.form-inspect-field-wrapper {
  border-radius: 4px;
  box-shadow: none;
  padding-bottom: 10px;
}

body > .form-inspect-field-wrapper {
  z-index: 10000;
}

body > .form-inspect-field-wrapper > * {
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.2);
}

.form-inspect-field-title {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 24px;
}

.form-inspect-hidden-field-title {
  height: auto;
}

.form-inspect-field-subtitle {
  font-size: 11px;
  opacity: 0.5;
}

.form-inspect-hidden-field-value {
  position: relative;
  margin: 5px 0;
}

.form-inspect-hidden-field-label {
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 8px;
  font-size: 11px;
  color: #8a8a8a;
  pointer-events: none;
}

.form-inspect-hidden-field-value .selectable-input {
  height: 60px;
  box-shadow: none;
}

.form-inspect-hidden-field-value .selectable-input input {
  height: 44px;
  padding: 0 6px;
}

.form-inspect-hidden-field-value .selectable-input-value {
  padding: 16px 10px 0;
  align-items: center;
}

.form-inspect-hidden-field-value .selectable-input-menu-trigger {
  height: 24px;
  right: 16px;
  top: 50%;
  margin-top: -12px;
}

.form-inspect-fields-section > .empty-state {
  margin: 10px -16px;
  padding: 20px;
  border: 1px dashed #ddd;
  border-radius: 4px;
}

.child .library-inspect-list-filters .library-inspect-filter-item {
  background-color: inherit;
  overflow: hidden;
}

/* Styles for list filters inside a form. */

.child {
  .library-inspect-list-filters {
    .library-inspect-filter-item {
      margin: 8px -16px 4px;
    }
  }

  .library-inspect-list-filters.is-form {
    .library-inspect-filter-item {
      background-color: #00a89870;
      overflow: hidden;
      margin: 8px 0;

      .library-inspect-filter-item-header .library-inspect-filter-item-delete {
        background-color: #616161;

        &:hover {
          background-color: #424242;
        }

        .filter-label.logic {
          margin: 0;
        }
      }

      .library-inspect-list-filters-add:hover {
        background-color: #d5ecea;
      }
    }

    > .filter-label {
      color: $labelDark;
      margin: 16px;
    }

    .library-inspect-list-filters-add {
      display: flex;
      margin: 0px;
      transition: all 0.2s;
      border-radius: 4px;

      & .btn.btn-small.btn-list-add {
        background-color: inherit;
        overflow: hidden;

        &:hover {
          color: $labelDark;
          background-color: transparent;
        }
      }

      &:hover {
        background-color: #00a89870;
      }
    }
  }
}

/* Integration Pack Trial - Click action styles from when trial is over */

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  .accordion-title {
  background: #e2e2e2;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  .accordion-title
  .action-item-form-item-header
  .action-item-type
  .action-item-type-title {
  opacity: 0.6;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-children {
  background: #f1f1f1;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  .accordion-title
  .icon-action {
  background: #9e9e9e;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  .accordion-title
  .icon-report-problem-small {
  background: #ef4c30;
  position: relative;
  right: 3px;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  .accordion-title
  .icon-trash-small {
  background: #9e9e9e;
  position: relative;
  top: 3px;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-boxed-done
  .icon-button-wrapper
  .icon {
  background: #9e9e9e;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-boxed-done
  .btn:last-child {
  background: #ef4c30;
  color: white;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-boxed-done
  .btn:last-child:hover {
  filter: brightness(95%);
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-boxed-done
  .btn-text {
  color: #9e9e9e;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-boxed-done
  button:hover {
  background: #d8d8d8;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-boxed-done
  button.btn-yellow:focus {
  background: #d8d8d8;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-children
  .bindable-text-control-entity-label {
  background: #ccc;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-children
  .icon-magic-text {
  background: #ccc;
}

.after-trial-action
  .action-item
  .action-item-form
  .action-actions-list
  .action-actions-list
  .custom-action-item-form-item
  > div
  .accordion-children
  .icon-button-wrapper:hover {
  background: #f1f1f1;
}

.secondary-button-inspect-title {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  pointer-events: all;
}
