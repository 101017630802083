@import 'styles/_colors.scss';

.unpublish-modal {
  .modal {
    &-header {
      flex-direction: column;
      gap: 12px;

      h1 {
        font-size: 18px;
        font-weight: bold;
      }

      span {
        font-size: 24px;
      }
    }

    &-content {
      font-size: 14px;
      color: $labelDark;

      max-width: 300px;
      margin: 0 auto;
    }
  }
}
