@import 'styles/_colors.scss';

.editor-add-section {
  .section-header-content {
    margin: 0 -16px 16px;
  }

  h2 {
    margin: 16px 0;
    color: $label;
    font-weight: 400;
  }
}
