.domain-settings-section2 h4 {
  margin-bottom: 10px;
}

.domain-settings-section2 .settings-form-row {
  margin-top: 20px;
  margin-bottom: 20px;
}

.team-settings-domain-details {
  flex: 1;
}

.team-settings-domain-action span {
  padding: 0 10px;
}

.team-settings-subdomain {
  display: flex;
  align-items: center;
  background: #f1f1f1;
  padding: 10px 16px;
  margin: 0 -16px;
  border-radius: 9px;
  justify-content: space-between;
}

.team-settings-subdomain p {
  font-size: 14px;
}

.domain-settings .team-select-menu {
  margin-bottom: 30px;
}

.domain-settings-domain {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f1f1f1;
  margin: 10px -16px;
  padding: 10px 16px;
  border-radius: 9px;
  font-size: 14px;
}

.domain-settings-domain .icon-button-wrapper {
  margin-left: 4px;
}

.domain-settings-domain .icon {
  background: #8a8a8a;
}

.domain-settings-domain-name {
  flex: 1;
}

.domain-settings-confirm {
  text-align: center;
}

.domain-settings-confirm h5 {
  margin: 10px 0;
  font-size: 20px;
}

.domain-settings-error {
  color: red;
  margin-top: 10px;
}

.domain-settings-loading-message {
  margin: 10px 20px 0;
  text-align: center;
}
