.property-row {
  border-radius: 6px;

  .accordion-expanded {
    margin-bottom: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .accordion-title {
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
    font-size: 14px;

    .property-title {
      flex: 1;
      font-size: 14px;

      .property-subtitle {
        margin-top: 5px;
        font-size: 12px;
        opacity: 0.6;
      }
    }

    .property-unsaved {
      margin-right: 8px;
      font-size: 12px;
    }

    .icon-badge {
      &.open {
        opacity: 0.4;
      }
    }
  }

  .accordion-children {
    padding: 16px;

    .property-options {
      display: flex;
      gap: 16px;

      .property-option {
        flex: 1;
      }
    }
  }
}
