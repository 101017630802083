@import 'styles/_colors.scss';

.offline-banner-visible .empty-state.greedy {
  margin-top: 96px;
}

.blocked-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  user-select: none;
}

.blocked-content {
  > * {
    &:last-child {
      margin-bottom: 50px;
    }
  }

  h1 {
    color: $black;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 11px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    max-width: 600px;
    margin-bottom: 32px;
    color: $body2;
  }

  a {
    color: $teal !important;
  }
}
