@import 'styles/_colors.scss';

.magic-form-input {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $black;
    border-radius: 4px;
    overflow: hidden;

    &.error {
      border: 1px solid $data;
    }
  }

  &__field {
    width: 100%;
    outline: none;
    min-height: 80px;
    font-size: 13px;
    padding: 16px;
    border: none;
    resize: none;
    font-family: 'Work Sans';

    &::placeholder {
      color: $placeholder;
    }

    &:disabled {
      min-height: unset;
    }
  }

  &__footer {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e5e5e5;
    position: relative;

    span {
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 500;
      font-size: 11px;
    }

    .btn.btn-small {
      padding: 0 16px;
      font-weight: 700;
      font-size: 12px;
    }
  }
}
