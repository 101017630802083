.material-icon-menu {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.material-icon-menu-value {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.material-icon-menu-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  flex: 1;
}

.material-icon-menu-title i {
  margin-right: 10px;
}

.material-icon-menu-placeholder {
  color: #8a8a8a;
  font-style: italic;
  flex: 1;
  line-height: 34px;
  height: 34px;
}

.material-icon-menu-options {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  z-index: 200000;
}

.material-icon-menu-clear .icon, .material-icon-menu-expand {
  background: #8a8a8a;
}

.material-icon-menu-clear:hover .icon, .material-icon-menu-expand:hover {
  background: #666;
}

.material-icon-menu-clear .icon {
  mask-size: 12px 12px;
}

.material-icon-menu-search {
  padding: 5px 20px;
  margin-top: 15px;
}

.material-icon-menu-search input {
  border: none;
  outline: none;
  font: inherit;
  border-bottom: 2px solid #ddd;
  width: 100%;
  padding: 5px 0;
}

.material-icon-menu-options-sub {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 288px;
  max-height: 400px;
  padding: 16px;
  overflow-y: auto;
}

.material-icon-menu-option {
  padding: 4px;
  width: 32px;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  border-radius: 2px;
}

.material-icon-menu-option:hover {
  background: #ddd;
}

.material-icon-menu-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 200000;
}

.accordion-boxed .material-icon-menu-options-sub {
  max-height: 140px;
}

.accordion-boxed .material-icon-menu-value {
  margin-top: 6px;
}
