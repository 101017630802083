.list-type-control {
  display: flex;
  flex-direction: row;
}

.list-type-option {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid transparent;
  color: #8a8a8a;
  padding: 10px 0 6px;
  font-size: 10px;
}

.list-type-option .icon {
  background: #8a8a8a;
  margin-bottom: 4px;
}

.list-type-option + .list-type-option {
  margin-left: 5px;
}

.list-type-option:hover {
  border-color: #f1f1f1;
}

.list-type-option.list-type-option-selected {
  border-color: #00a898;
  color: #00a898;
}

.list-type-option.list-type-option-selected .icon {
  background: #00a898;
}

