.free-plan-container {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 9px;
  padding: 23px;

  .free-plan-header {
    font-size: 16px;
    font-weight: 600px;
    margin-bottom: 15px;

    .free-plan-rocket {
      margin-right: 8px;
    }
  }

  .free-plan-perks {
    margin-left: 20px;
    margin-bottom: 20px;
    list-style: none;
  }

  .free-plan-perks > li.free-plan-perk {
    font-size: 12px;
    padding: 5px 0px 0px 4px;
    line-height: 16px;
    color: #424242;
    position: relative;
    right: 20px;

    > .free-plan-pink-check {
      margin-right: 7px;
      position: relative;
      top: 2px;
      height: 12px;
      width: 12px;
    }
  }
}
