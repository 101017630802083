@import 'styles/_colors.scss';

.select-list {
  label {
    color: #9c9c9c;
    display: block;
    font-size: 16px;
    margin-bottom: 18px;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

.select-list-error {
}

.select-list-error-message {
}

.select-list-options {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.select-list-circle-check {
  margin-left: auto;

  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  background-image: url('./circle-check.svg');
  opacity: 0;
  transition: all 0.2s ease-in;
}

.select-list-option {
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: $white;
  color: $black;

  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid #00a898;
  }

  cursor: pointer;

  &__title {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 18px 12px 18px 21px;
    gap: 0.5rem;
  }

  p {
    display: block;
    font-size: 0.875rem;
  }

  &.selected {
    border: 1px solid $teal;

    .select-list-circle-check {
      opacity: 1;
    }

    &.dropdown {
      .select-list-option__title {
        background-color: $lightTeal;
      }
    }
  }

  &__dropdown {
    padding: 18px 21px 14px 21px;
    display: flex;

    .dropdown-content {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      .keyword {
        box-shadow: 0px 0px 0px 2px #8c8c8c10;
        background-color: #8c8c8c10;
      }
    }

    .select-list-option-label {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &.disabled {
    cursor: default;
  }

  .select-list-option-description {
    margin-top: 4px;
    color: $body2;
    font-size: 14px;
    font-weight: 400;
  }
}
