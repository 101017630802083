.snap-grid {}

.snap-grid line {
  fill: none;
}

.snap-grid-debug {
  pointer-events: all;
}

.snap-grid-debug line {
  fill: none;
  stroke: lime;
  opacity: 0.8;
}

.snap-grid-debug-line-center {
  fill: aqua;
}