body.editor-body {
  overflow: hidden;
  background: #fff;
  touch-action: none;
  user-select: none;
}

.editor-error {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  font-size: 14px;
}

.editor-error h2 {
  font-size: 36px;
  margin-bottom: 15px;
}

.editor-error p {
  margin-bottom: 0.5em;
}

.editor-body-loader {
  position: fixed;
  top: 60px;
  left: 425px;
  right: 0;
  bottom: 0;
}

.data {
  .data-sidebar {
    &.api {
      position: fixed;
      left: 65px;
      top: 64px;
      transition: top 0.2s;
      bottom: 0;
      width: 255px;
      z-index: 100;
      background: white;
      padding-left: 35px;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }
  }
  .data-body {
    &.api {
      position: absolute;
      left: 320px;
      top: 64px;
      right: 0;
      bottom: 0;
      overflow: hidden;
      transition: top 0.2s;
    }
  }
}

.datasource-link-header {
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
