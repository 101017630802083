.formula-control.formula-control-invalid .entity-textarea {
  background-color: #fdd;
}

.formula-control {
  position: relative;
}

.formula-control.formula-control-contained .bindable-text-control-header {
  margin: 0;
}

.formula-control.formula-control-contained .bindable-text-control-label {
  display: none;
}

.formula-control.formula-control-contained .bindable-text-control-trigger-wrapper {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.formula-control.formula-control-contained .entity-textarea {
  margin: 0;
  padding-right: 40px;
}
