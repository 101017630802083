// Style IDs - must match cardStyle values in ./usePlanCards.js
$free: 'free';
$starter: 'starter';
$professional: 'pro';
$team: 'team';
$business: 'business';

$cardStyles: $free, $starter, $professional, $team, $business;

$titleColors: (
  $free: #9e9e9e,
  $starter: #ff9a00,
  $professional: #00a898,
  $team: #ef4c30,
  $business: #424242,
);

$buttonColors: (
  $free: #9e9e9e,
  $starter: #fecc1d,
  $professional: #00a898,
  $team: #ef4c30,
  $business: #424242,
);

$buttonHoverColors: (
  $free: #757575,
  $starter: #ffad00,
  $professional: #009885,
  $team: #e0432f,
  $business: #000000,
);

$depressedButtonBorderColors: $titleColors;

$buttonTextColors: (
  $free: #ffffff,
  $starter: #000000,
  $professional: #ffffff,
  $team: #ffffff,
  $business: #ffffff,
);

$backgroundColors: (
  // Stick to RGB values without alpha to avoid creating transparent cards.
  // color-mix(in srgb, #F5F5F5 60%, white)
  $free: #f9f9f9,
  // color-mix(in srgb, #FFC00E 10%, white)
  $starter: #fff9e7,
  // color-mix(in srgb, #00A898 5%, white)
  $professional: #f2fbfa,
  // color-mix(in srgb, #EF4C30 5%, white)
  $team: #fef6f5,
  // color-mix(in srgb, #000000 7%, white)
  $business: #ededed
);

$borderColors: (
  $free: #f5f5f5,
  // color-mix(in srgb, #FFC00E 30%, white)
  $starter: #ffecb7,
  // color-mix(in srgb, #00A898 20%, white)
  $professional: #cceeea,
  // color-mix(in srgb, #EF4C30 15%, white)
  $team: #fde4e0,
  // color-mix(in srgb, #000000 20%, white)
  $business: #cccccc,
);

@function titleColor($cardStyle) {
  @return map-get($titleColors, $cardStyle);
}

@function buttonColor($cardStyle) {
  @return map-get($buttonColors, $cardStyle);
}

@function buttonHoverColor($cardStyle) {
  @return map-get($buttonHoverColors, $cardStyle);
}

@function buttonTextColor($cardStyle) {
  @return map-get($buttonTextColors, $cardStyle);
}

@function depressedButtonBorderColor($cardStyle) {
  @return map-get($depressedButtonBorderColors, $cardStyle);
}

@function backgroundColor($cardStyle) {
  @return map-get($backgroundColors, $cardStyle);
}

@function borderColor($cardStyle) {
  @return map-get($borderColors, $cardStyle);
}
