.bounding-box {
  pointer-events: none;
}

.resize-click-target {
  fill: none;
  pointer-events: all;
}

.resize-handle {
  fill: #fff;
  stroke: #ffc100;
  stroke-width: 1.5px;
}

.resize-handles.teal .resize-handle {
  stroke: #00a898;
}

.resize-handles.child-object-bounding-box .resize-handle {
  stroke: #ffc100;
}

.selection-box {
  fill: none;
  stroke: #ffc100;
  stroke-width: 1px;
}

.selection-box.teal {
  stroke: #00a898;
}

.selection-box.child-object-bounding-box {
  stroke: #ffc100;
}

.selection-box.section-element-bounding-box {
  stroke: #00a898;
  stroke-width: 1px;
  stroke-dasharray: 5 2;
}

.container-resize-click-target {
  fill: '#00a898';
  pointer-events: all;
}

.container-resize-click-target.transparent {
  opacity: 0.2;
}

.group-bounding-box {
  fill: none;
  stroke: #ffc100;
  stroke-width: 1px;
  stroke-dasharray: 5 2;
}

.section-drag-button {
  pointer-events: all;
  cursor: grab;
  fill: #00a996;

  &.light {
    fill: #00a9961a;
  }

  &:active {
    cursor: grabbing;
  }

  &:hover {
    fill: #006b5a;
  }
}

.section-switch-button {
  pointer-events: all;
  fill: #00a9961a;

  &:hover {
    fill: #00a996;
  }
}
