@import 'styles/_colors.scss';

.new-developer-libraries {
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin: 0 -16px;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: $labelDark;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    padding: 0 16px;

    .tooltip-trigger {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > * {
      padding: 0 16px;
    }

    .new-developer-libraries-list {
      padding: 0;
    }

    > h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $body;

      margin: 0;
      font-size: 18px;
    }
  }

  &-express-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    font-size: 12px;
  }

  &-list {
    &-items {
      border: 1px solid $border;
      border-radius: 6px;
    }
  }

  > .loading {
    margin: 0 auto;
  }

  .callout-card {
    color: $filterLabel;

    img {
      height: 24px;
      margin-bottom: 16px;
    }

    &-content {
      padding-bottom: 24px;
    }
  }

  .multi-select-menu {
    &-selection {
      padding-left: 16px;
    }

    &-expand-icon {
      &:before {
        right: 16px;
      }
    }
  }
}
