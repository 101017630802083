.floating-accordion {
  position: absolute;
  display: flex;
  width: 100%;
  max-width: calc(100% - 32px);
  border-radius: 4px;
  margin: 8px 0 0 -16px;
  background-color: white;
  z-index: 100;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1),
    0 10px 30px -2px rgba(0, 0, 0, 0.15);

  > div {
    width: 100%;
  }
}

.floating-accordion .accordion-title {
  display: none;
}

.floating-accordion .accordion-children {
  width: 100%;
  padding: 0;
}

.floating-accordion-children-wrapper {
  padding: 0 24px 24px;
  border-radius: 4px;
}
