.mobile-only-select-menu {
  .multi-select-menu-selection {
    background: none !important;
    border: 1px solid #e9e9e9 !important;
  }
}

.mobile-only-shared-layout-settings {
  padding: 0 !important;
  margin-bottom: 22px !important;
}
