@import 'styles/_colors.scss';

@mixin calloutColors($color) {
  border-color: $color;
  border-width: 1px;

  &.callout-card-border-none {
    background-color: $color;
    border-width: 0;
  }
}

.callout-card {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;

  width: 100%;
  border-radius: 6px;

  .callout-card-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 16px;

    & > * {
      margin: 0 0 8px 0;
      white-space: pre-wrap;

      &:last-child {
        margin: 0;
      }
    }
  }

  &-border {
    &-solid {
      border-style: solid;
    }

    &-dashed {
      border-style: dashed;
    }

    &-none {
      border-style: none;
    }
  }

  &-alignment {
    &-left {
      .callout-card-content {
        text-align: left;
        align-items: flex-start;
      }
    }

    &-center {
      .callout-card-content {
        text-align: center;
        align-items: center;
      }
    }

    &-right {
      .callout-card-content {
        text-align: right;
        align-items: flex-end;
      }
    }
  }

  // header image
  & > .callout-card-header-image {
    width: 100%;
  }

  &-type {
    &-panel {
      margin: 0 -16px;
    }
  }

  // text styles
  a,
  p,
  span {
    font-size: 12px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 16px;
  }

  // colors
  &-color {
    &-darkPink {
      @include calloutColors($darkPink);
    }
    &-orange {
      @include calloutColors($orange);
    }

    &-yellow {
      @include calloutColors($yellow);
    }

    &-green {
      @include calloutColors($green);
    }

    &-teal {
      @include calloutColors($teal);
    }

    &-lightTeal {
      @include calloutColors(rgba($teal, 0.2));
    }

    &-purple {
      @include calloutColors($purple);
    }

    &-grey {
      @include calloutColors($grey);
    }

    &-lightGrey {
      @include calloutColors($lightGrey);
    }
  }

  &-custom-fonts {
    border: none !important;
    background-color: $lightDarkPink;
    width: 328px;
    margin-left: -16px;
    margin-right: -16px;

    a {
      color: $darkPink;
      text-decoration: none;
      font-weight: bold;
    }

    span[role='img'] {
      font-size: 16px;
    }
  }
}
