.label {
  position: relative;
  cursor: default;
}

.label-editing .label-text {
  white-space: pre;
  color: transparent;
}

.label input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  font: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  display: block;
  background: transparent;
  border: none;
  outline: none;
  min-width: 2px;
}
