@import 'styles/_colors.scss';

@mixin stepperColor($background, $color: false) {
  .step-done {
    .step-bullet {
      background-color: $background;
      @if ($color) {
        color: $color;
        .icon {
          background-color: $color;
        }
      }
    }
  }
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: center;

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-text {
    margin-left: 10px;
    color: $body2;
    font-size: 12px;
  }

  .step-bullet {
    background-color: rgb(189, 189, 189);
    color: #fff;
    font-size: 16px;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      background-color: #fff;
      width: 16px;
      height: 16px;
      mask-size: 16px;
    }
  }

  .step-current {
    .step-text {
      font-weight: 500;
      color: $black;
    }
  }

  .step-done {
    .step-bullet {
      background-color: $teal;
    }
  }

  hr {
    flex: 1;
    border-color: #9e9e9e;
    border-top-width: 0;
    border-bottom-width: 1px;
    margin: 0 20px;
    max-width: 66px;
  }

  // COLORS
  &.stepper-teal {
    @include stepperColor($teal);
  }
  &.stepper-orange {
    @include stepperColor($orange);
  }
  &.stepper-yellow {
    @include stepperColor($yellow, $black);
  }
  &.stepper-green {
    @include stepperColor($green);
  }
  &.stepper-purple {
    @include stepperColor($purple);
  }
  &.stepper-darkPink {
    @include stepperColor($darkPink);
  }
  &.stepper-red {
    @include stepperColor($red);
  }
}
