@import 'styles/_colors.scss';

.editor-mobile-blocker {
  z-index: 10001;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}

.app-preview-section {
  background-color: #f7f3ee;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 15px 0;
  height: 100%;
  justify-content: space-between;

  .app-information {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    max-width: 500px;

    .app-icon {
      min-width: 66px;
      min-height: 66px;
    }

    > div {
      h2 {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 20px;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .app-preview-frame {
    object-fit: contain;
    max-width: 100%;
  }
}

.check-email-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #00a898 0%, #006b5a 100%);
  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-style: normal;
  line-height: normal;
  width: 100%;
  padding: 32px 27px;
  height: 100%;
  gap: 1.25rem;

  h1 {
    font-size: 1.75rem;
    font-weight: 700;
    width: 339px;
    margin-bottom: 0;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    width: 339px;
  }

  .btn {
    color: $white;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    pointer-events: none;
    text-wrap: nowrap;

    .icon {
      background-color: $white;
      margin-right: 10px;
    }
  }
}
