.cursor-crosshair {
  cursor: crosshair;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-pen-plus {
  cursor: url('./cursors/pen-plus.png') 7 1, default;
  cursor: -webkit-image-set(url('./cursors/pen-plus.png') 1x, url('./cursors/pen-plus@2x.png') 2x) 7 1, default;
}

.cursor-pen-close-path {
  cursor: url('./cursors/pen-close-path.png') 7 1, default;
  cursor: -webkit-image-set(url('./cursors/pen-close-path.png') 1x, url('./cursors/pen-close-path@2x.png') 2x) 7 1, default;
}
