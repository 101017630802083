@import 'styles/_colors.scss';

.edit-styles-accordion {
  margin-top: 30px;
}

.edit-styles-accordion .accordion-title {
  justify-content: center;
}

.edit-styles-accordion .accordion-children {
  padding: 10px 0 0;
  margin: 0 -16px;
}

.edit-styles-accordion-children-wrapper {
  padding: 12px 24px;
  background: #f1f1f1;
  border-radius: 4px;
}

.edit-styles-accordion.edit-styles-accordion-spaced {
  margin-top: 35px;
}

.edit-styles-accordion {
  &--new {
    .accordion-title {
      margin: 0 -16px;

      .btn {
        gap: 11px;
        justify-content: start;

        :nth-last-child(1) {
          margin-left: auto;
        }
      }

      .edit-styles-accordion-title {
        width: 100%;

        &:hover {
          .btn:not(.btn-focussed) {
            border-color: $darkPink;
            background-color: rgba($darkPink, 0.1);
            color: $darkPink;

            .icon {
              background-color: $darkPink;
              fill: $darkPink;
            }
          }
        }
      }
    }

    &.accordion-expanded {
      .accordion-title {
        .btn {
          border-radius: 4px 4px 0 0;
        }
      }
    }

    .accordion-children {
      margin: 0 -16px;
      padding: 0;
    }

    .toggle-button-block {
      margin-top: 16px;
    }

    .edit-styles-accordion-children-wrapper {
      background: white;
      border-radius: 0 0 4px 4px;
      border: 1px solid $darkPink;

      .inspect-generic-row.default-color-control {
        display: grid;
        gap: 8px;

        .color-dropdown__wrapper {
          margin-top: 16px;

          label {
            margin-left: 0;
          }
        }
      }

      .color-dropdown__wrapper label {
        margin-left: 0;
      }

      .color-dropdown {
        margin-top: 0;
      }

      .inspect-generic-row {
        &.border-control .inspect-generic-row-body,
        &.default-shadow-control .inspect-generic-row-body {
          .color-dropdown-inner-wrapper {
            width: 121px;

            & .color-dropdown-title {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 80px;
            }
          }
        }

        .inspect-generic-row-body .color-dropdown__wrapper label {
          margin-left: 16px;
        }

        &.border-control .inspect-generic-row-body .color-dropdown {
          height: 44px;
          margin: 0;

          & .color-dropdown-inner-wrapper {
            padding: 10px 12px 10px 16px;
          }
        }

        &.background-image-control .image-control {
          margin: 0 -16px;
        }

        &.background-image-control .image-control .image-input-wrapper,
        &.background-image-control .image-control .image-input {
          width: 100%;
        }

        &.background-color-control,
        &.background-control {
          .color-dropdown__wrapper {
            margin-top: 16px;

            .color-dropdown {
              margin-top: 0;
            }

            label {
              margin-left: 0;
              margin-bottom: 6px;
            }
          }
        }

        &.border-control
          .inspect-generic-row-body
          .border-control-grid
          .library-inspect-menu-control
          p {
          margin: 0 0 5px 16px;
        }

        .border-control-grid .inspect-generic-row-body .text-control label {
          margin-bottom: 5px;
        }
      }
    }
  }
}
