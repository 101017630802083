@import 'styles/_colors.scss';

.panel {
  display: flex;
  flex-direction: column;
  background: #fff;
  /* NOTE: this is the old shadow from original designs:
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
              0px 4px 5px 0px rgba(0, 0, 0, 0.14),
              0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 2px 0 3px rgba(0, 0, 0, 0.1);
}

.panel-body {
  flex: 1;
  // display: flex;
  // flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

.panel-section {
  position: relative;
  padding: 10px 0;
  margin: 0 -32px;
  border-top: 1px solid #f3f3f3;
}

.panel-section-header,
.panel-menu {
  padding: 6px 32px;
  line-height: 20px;
  background: #fff;
}

.panel-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.panel-section-header h4 {
  line-height: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-size: 1em;
  font-weight: normal;
  color: #8a8a8a;
  flex: 1;
}

.panel-section-body {
  padding: 12px 32px;
}

.panel-section-body .empty-state {
  padding-bottom: 10px;
}

.panel-section-body.panel-section-body-nopad {
  padding: 0;
}

.panel-header-content {
  box-shadow: 0 0.5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1000;
}

.panel-menu {
  padding: 0;
  box-shadow: 0 0.5px rgba(0, 0, 0, 0.25), 0 -0.5px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: background-color 0.2s;
}

.panel-menu-option,
.panel-menu-placeholder {
  cursor: pointer;
  padding: 6px 20px;
}

.panel-menu-selection .panel-menu-option,
.panel-menu-placeholder {
  padding: 12px 20px;
}

.panel-menu-placeholder {
  opacity: 0.5;
}

.panel-menu-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  box-shadow: 0 1px rgba(0, 0, 0, 0.1);
}

.panel-menu-expand-arrow {
  position: absolute;
  right: 6px;
  top: 50%;
  bottom: 0;
  margin-top: -12px;
  transform: rotate(90deg);
  transition: transform 0.2s;
}

.multi-menu-trigger.expanded .panel-menu {
  background: #fff;
}

.multi-menu-trigger.expanded .panel-menu .panel-menu-expand-arrow {
  transform: rotate(270deg);
}

.magic-start-tooltip {
  margin-left: 4rem;
}

.panel-footer {
  display: flex;
  justify-content: center;
}
