.select-control {
  text-align: left;
}

.select-control.with-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.select-control .multi-select-menu-selection {
  padding-left: 8px;
  padding-right: 8px;
  background: #f3f3f3;
}

.select-control .multi-menu {
  background: #f3f3f3;
}

.select-control__title {
  color: #333;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  margin-left: 16px;
}