@import 'styles/_colors.scss';

.settings-form-toggle {
  margin: 35px 0;
}

.simple-toggle-content {
  display: flex;
  margin-bottom: 15px;

  input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
    color: $body;
    background: transparent;
    margin-left: 15px;
  }

  .toggle-button {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
  }

  &.toggle-field-disabled {
    input {
      font-style: italic;

      color: $subtitle;

      &:disabled {
        color: $subtitle;
        -webkit-text-fill-color: $subtitle;
      }
    }
  }
}
