.permissions-select {
  .multi-select-menu {
    height: 44px;

    .multi-select-menu-value {
      height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 4px;
    }

    .multi-select-menu-selection,
    .multi-select-menu-selection:hover {
      border: none;
      box-shadow: none;
    }

    &:not(.expanded) {
      .multi-select-menu-selection {
        background-color: #f1f1f1;
      }
    }

    .multi-select-menu-expand-icon:before {
      right: 16px;
    }
  }

  &.disabled {
    .multi-select-menu {
      opacity: 0.6;
      pointer-events: none;

      .multi-select-menu-expand-icon:before {
        display: none;
      }
    }
  }
}
