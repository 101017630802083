@keyframes entity-detail-slide-down {
  from {
    transform: translateY(-4px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes entity-detail-slide-up {
  from {
    transform: translateY(4px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.entity-detail-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
}

.entity-detail-wrapper-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99998;
}

.entity-detail-backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.entity-detail-popout {
  position: absolute;
  color: #000;
  width: 320px;
  font-size: 12px;
  animation: entity-detail-slide-down 0.2s 1;
  display: flex;
  flex-direction: column;
  top: 70px;
  bottom: 0px;
  cursor: unset;
  pointer-events: none;
}

.entity-detail-popout-sub {
  background: #fff;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1),
    0 10px 30px -2px rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 4px;
  max-height: 100%;
  overflow-y: auto;
  pointer-events: all;
}

.entity-detail-popout-section > .wrapped-select,
.entity-detail-popout-input {
  font-size: 14px;
}

.entity-detail-popout-section
  > .wrapped-select
  .multi-select-menu-selection.expanded {
  box-shadow: none;
}

.entity-detail-popout-above {
  animation-name: entity-detail-slide-up;
  justify-content: flex-end;
}

.entity-detail-popout-section {
  margin-top: 12px;
}

h4 + .entity-detail-popout-section {
  margin-top: 24px;
}

.entity-detail-popout-section > .entity-detail-popout-label:first-of-type {
  margin-top: 0;
}

.entity-detail-popout-section > .entity-detail-popout-label {
  margin-top: 12px;
}

.entity-detail-popout-label {
  margin-bottom: 3px;
  color: #8a8a8a;
}

.entity-detail-popout-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.entity-detail-popout-columns > .entity-detail-popout-section {
  width: 50%;
  padding: 0 6px;
}

.entity-detail-popout-input {
  display: block;
  width: 100%;
  height: 36px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 11px;
  outline: none;
}

.entity-detail-popout-header {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

.entity-detail-popout .library-inspect-filter-item {
  margin-left: 0;
  margin-right: 0;
}

.entity-detail-popout-delete {
  float: right;
  margin-top: -6px;
  margin-bottom: -6px;
}
