@import 'styles/_colors.scss';

$transition: all 0.2s;

.magic-add {
  .magic-add-setup {
    &-title {
      margin-top: 20px;
      color: $labelDark;
      display: flex;
      flex-direction: column;
      gap: 18px;

      &.feature-description {
        color: $black;

        > h2 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding-left: 12px;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .schema-error-message {
        padding: 0 16px;
        color: $data;
        margin-top: -8px;
        font-weight: bolder;
      }

      &__feature-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        &__item {
          font-size: 0.875rem;
          font-weight: 500;
          color: $labelDark;

          padding: 1px;
          border-radius: 6px;

          &:hover {
            padding: 2px;
            background: linear-gradient(180deg, #ef4c30 0%, #a82058 100%);

            > div {
              border: none;
              border-radius: 4px;

              .icon {
                background: linear-gradient(180deg, #ef4c30 0%, #a82058 100%);
              }
            }
          }

          &__description {
            flex: 1;
            display: flex;
            align-items: center;
            cursor: pointer;
            border: 1px solid #ccc;
            border-radius: 6px;
            padding: 12px 20px;
            background: $white;
            justify-content: space-between;

            &.--alternative {
              border-color: #e8e8e8;
              color: $body2;
            }
          }
        }
      }
    }

    > button.btn {
      background-color: transparent;
      border-radius: 0;
      color: $data;
      font-size: 14px;
      margin: 0 -32px;
      padding: 16px 32px;
      justify-content: flex-start;

      height: auto;
      width: calc(100% + 64px);

      &:hover {
        background-color: $lightData;
      }

      .icon {
        background-color: $data;
        margin-right: 8px;
      }
    }

    &__feature-prompting {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__back-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        button {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 10px;
          font-weight: 500;
          cursor: pointer;

          .icon {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}
