@import 'styles/_colors.scss';

.collection-access-selector {
  .multi-select-menu {
    height: 44px;

    .multi-select-menu-value {
      height: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 4px;
    }

    .multi-select-menu-selection,
    .multi-select-menu-selection:hover {
      border: none;
      box-shadow: none;

      padding: 16px;
    }

    &:not(.expanded) {
      .multi-select-menu-selection {
        background-color: #f1f1f1;
      }
    }

    .multi-select-menu-expand-icon:before {
      right: 16px;
    }
  }
}

.some-logged-in-users {
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 16px;
  padding-bottom: 16px;

  border-radius: 6px;
  background-color: #f1f1f1;

  .some-logged-in-users__top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 12px;

    .some-logged-in-users__label {
      flex: 1;
      color: #424242;
    }

    .some-logged-in-users__close-button {
      height: 12px;
      width: 12px;
      background-color: #919191;

      cursor: pointer;
    }
  }

  .some-logged-in-users__path-rows {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .some-logged-in-users__path-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding: 11px;

      border-radius: 4px;
      background-color: $white;

      .some-logged-in-users__path-row-icon {
        height: 22px;

        background-color: #5c5c5c;
      }

      .some-logged-in-users__path-row-label {
        flex: 1;

        margin-left: 8px;
        overflow-x: hidden;

        .tooltip-trigger {
          display: block;
        }

        .some-logged-in-users__path-row-text {
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
