.image-input-wrapper {
  text-align: center;
  width: 100px;
}

.image-input-wrapper p {
  text-align: left;
}

.image-input {
  border-radius: 8px;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
}
.image-input-sub {
  height: 100%;
}
.image-input-select {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(220, 220, 220, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  color: #aaa;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s;
}

.image-input-select:before {
  content: '';
  pointer-events: none;
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  right: 5px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  transition: border 0.2s;
}

.image-input-empty .image-input-select {
  background: #e8e8e8;
}

.image-input:hover .image-input-select,
.image-input-empty .image-input-select {
  opacity: 1;
}

.image-input:hover .image-input-select {
  color: #8a8a8a;
  background: rgba(220, 220, 220, 0.9);
}

.image-input:hover .image-input-select:before {
  border-color: #bbb;
}

.image-input-preview {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: contain;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.image-input .loading {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
}

.image-input-links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 6px;
}

.image-input-links a + a {
  margin-left: 20px;
  position: relative;
  display: block;
}

.image-input-links a + a:before {
  content: '';
  position: absolute;
  left: -12px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #9a9a9a;
  top: 5px;
  text-align: center;
}

.extra-margin-bottom {
  margin-bottom: 25px;
}