@import 'styles/_colors.scss';

.new-relationship-key {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  .group {
    margin-right: 16px;
    color: $body2;

    &:last-child {
      margin-right: 0;
    }
  }
}

.new-relation-key-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  width: 29px;
  font-size: 14px;

  &.small {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  &.expanded-collection {
    background-color: $data;
    color: white;
    border-radius: 50%;
  }

  &.selected-collection {
    color: $body;
    box-shadow: inset 0 0 0 1px $body;
  }
}

.new-relationship-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.relationship-graphic {
  display: flex;
  flex-direction: column;
  align-items: center;
  .relationship-graphic-target > * + * {
    margin-left: 6px;
  }
}

.relationship-graphic-connections {
  display: block;
  width: 61px;
  height: 21px;
  margin: 3px 0;
  background: no-repeat center;

  &.belongsTo {
    background-image: url('./images/belongs-to.svg');
  }

  &.hasMany {
    background-image: url('./images/has-many.svg');
  }

  &.manyToMany {
    background-image: url('./images/many-to-many.svg');
  }
}

.relationship-text {
  flex: 1;
  font-size: 16px;
  margin-left: 40px;

  p + p {
    margin-top: 10px;
  }

  strong {
    &.expanded {
      color: $data;
    }
  }
}
