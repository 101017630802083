@import 'styles/_colors.scss';

.free-trial-end {
  text-align: justify;
  &-title {
    font-weight: bold !important;
    text-align: center;
  }
  &-body {
    color: #757575;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding-left: 50px;
    padding-right: 50px;
  }

  &-gif {
    padding: 30px 0;
    width: 100%;
  }
}
