@import 'styles/_colors.scss';

.apps-settings {
  display: flex;
  flex-direction: column;
  gap: 18px;

  h3 {
    margin-bottom: 0;
  }

  input {
    padding: 8px 16px;
    border: 1px solid #e0e0e0;
    color: #000;
    background-color: #fff;
    outline: none;
    line-height: 20px;
    border-radius: 5px;
    height: 44px;
    margin-bottom: 7px;
    font-size: 14px;

    &::placeholder {
      color: #bbb;
    }
  }

  .settings-content-box {
    display: flex;
    flex-direction: column;

    padding: 0;

    .settings-content-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      margin: 0;
      padding: 16px 25px;
      gap: 16px;

      border-bottom: 1px solid #e9e9e9;

      &:last-child {
        border-bottom: unset;
      }

      .btn {
        margin-left: auto;
      }
    }
  }
}
