@import 'styles/_colors.scss';

.beta-label {
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 5px 0;
  height: fit-content;
  width: fit-content;

  &-orange {
    background: $mediumData;
    color: $data;
  }
}
