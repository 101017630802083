@import 'styles/_colors.scss';

$transition: all 0.2s;

.magic-add .suggested-collections-section {
  margin-top: 2rem;
}

.magic-start .suggested-collections-section {
  margin-top: 1rem;
}

.magic-add,
.magic-start {
  .suggested-collections-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1rem;

    > * {
      width: 100%;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      color: $labelDark;
    }

    .suggested-collection {
      &-wrapper {
        > .accordion {
          display: flex;
          flex-direction: column;
          padding: 13px;
          background-color: #f9f9f9;
          border-radius: 4px;
          transition: border-color 0.3s;
          border: 1px solid transparent;

          &-expanded {
            background-color: white;
            border: 1px solid #ebebeb;
          }
        }

        .accordion-children {
          padding: 24px 0px 8px 24px;

          .accordion-title {
            transition: all 0.2s;
          }

          .data-accordion-item {
            .accordion-children {
              padding: 16px;
            }

            .tooltip-trigger {
              width: 100%;
            }
          }

          .back-button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;

            button {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 10px;
              font-weight: 500;
              cursor: pointer;

              .icon {
                width: 10px;
                height: 10px;
              }
            }
          }
        }

        .accordion-title-icon {
          margin-left: auto;
        }

        &.last {
          margin-bottom: 1rem;
        }
      }

      &-name {
        font-size: 14px;
        font-weight: 500;
      }

      &-description {
        margin-top: 12px;
        padding: 0 24px;
        color: $label;
      }

      &-fields {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-weight: 500;
        color: $labelDark;
      }

      &-field {
        display: flex;
        align-items: center;
        padding: 4px 10px;
        gap: 12px;
        background-color: $white;
        border: 1px solid #e3e3e3;
        border-radius: 2px;
      }
    }

    .magic-start-generate-again {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: $placeholder;
        cursor: pointer;
        transition: $transition;

        span {
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
        }

        .icon {
          background-color: $placeholder;
        }
      }

      &-text {
        font-size: 11px;
        color: #a4a4a4;
      }
    }

    .btn {
      width: fit-content;
    }
  }
}
