@import 'styles/_colors.scss';

.data-binding {
  padding: 12px 20px;
  margin: 0 -20px 10px;
  background: #f1f1f1;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2);
}

.data-binding .data-binding-remove {
  position: absolute;
  font-size: 11px;
  font-weight: 400;
  top: 12px;
  right: 14px;
  line-height: 20px;
  display: block;
  margin: -6px;
  padding: 6px;
  border-radius: 18px;
  background: transparent;
  transition: background 0.2s;
}

.data-binding .data-binding-remove .icon {
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  transition: background 0.2s;
}

.data-binding .data-binding-remove:hover {
  background: rgba(0, 0, 0, 0.06);
}

.data-binding .data-binding-remove:hover .icon {
  background: rgba(0, 0, 0, 0.7);
}

.data-binding-sort-order {
  color: #8a8a8a;
  margin-top: 5px;
}

.multi-menu {
  font-size: 14px;
}

.data-binding .binding-type-select {
  width: 70%;
}

.advanced-text-extension-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.advanced-text-extension-header .icon {
  background: #8a8a8a;
}

.advanced-text-extension .action-item-form-row > label {
  font-size: 10px;
}

.advanced-text-extension .action-item-form-row > div {
  flex: 2;
}

.advanced-text-extension .action-item-form-row input {
  border-color: transparent;
}

.data-binding .accordion-title > .icon {
  background: #8a8a8a;
}

.list-form-extension-realtime input {
  margin-right: 6px;
}

.list-form-extension-realtime + .list-form-extension-realtime {
  margin-top: 6px;
}
