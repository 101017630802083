@import 'styles/_colors.scss';

.integration-trial-end {
  text-align: center;
  &-title {
    font-weight: bold !important;
  }
  &-body {
    font-size: 14px;
    color: #bababa;
    padding-left: 50px;
    padding-right: 50px;
  }
}
