.multi-menu {
  background: #fff;
}

.wrapped-select.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.wrapped-select label {
  display: block;
  margin: 0 12px 5px;
}

.wrapped-select .multi-select-menu-selection {
  background: #fff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
}

.wrapped-select .multi-select-menu-selection:hover,
.wrapped-select .multi-select-menu-selection:focus {
  box-shadow: inset 0 0 0 1px #ccc;
}

.wrapped-select.small {
  height: calc(1.2em + 12px);
}

.wrapped-select.small .multi-select-menu-selection {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
}

.wrapped-select.naked .multi-select-menu-selection {
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  color: inherit;
  border: none;
  box-shadow: none;
}

.wrapped-select.naked .multi-select-menu-placeholder {
  color: inherit;
  opacity: 0.5;
}

.multi-select-menu .multi-menu-item.has-children:after,
.multi-select-menu .multi-select-menu-expand-icon:before,
.multi-select-menu .multi-menu-overflow-icon {
  mask-image: url('../Icon/icons/expand-arrow.svg');
  right: 10px;
}

.multi-menu-item.has-children:after {
  right: 6px;
}

.multi-menu-item.open,
.multi-menu-item:hover {
  background: #eee;
  color: #444;
}

.multi-menu-item.open > .multi-menu-item-label > .multi-menu-item-subtitle,
.multi-menu-item:hover > .multi-menu-item-label > .multi-menu-item-subtitle {
  color: #666;
}

.multi-menu-item.has-children.open:after,
.multi-menu-item.has-children:hover:before {
  background: #8a8a8a;
}

.wrapped-select.naked .multi-select-menu-expand-icon:before {
  background-color: rgba(0, 0, 0, 0.5);
  right: 5px;
}

.wrapped-select.naked.expanded {
  box-shadow: none;
}

.multi-menu-wrapper.naked > .multi-menu-inner-wrapper > .multi-menu {
  border-radius: 4px;
}

.multi-menu-wrapper.naked > .multi-menu-inner-wrapper:before {
  display: none;
}

.wrapped-select.hug-text .multi-select-menu-value {
  display: inline-block;
}

.wrapped-select.hug-text .multi-select-menu-expand-icon:before {
  display: inline-block;
  position: static;
  margin-left: 8px;
}

.wrapped-select.expanded .multi-select-menu-selection,
.multi-menu-wrapper-attached > .multi-menu-inner-wrapper:before {
  background: #fff;
}

.wrapped-select.white .multi-menu {
  background: #fff;
}

.wrapped-select.white:not(.expanded) .multi-select-menu-selection {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.wrapped-select.white.expanded .multi-select-menu-selection {
  box-shadow: none;
}

.multi-select-menu .icon {
  margin: -8px 4px -8px -2px;
  background-color: #9a9a9a;
}

.multi-menu-item.disabled .icon {
  opacity: 0.5;
}

.multi-select-menu .multi-menu-item.open .icon,
.multi-select-menu .multi-menu-item:hover .icon {
  background-color: #fff;
}

.multi-menu-trigger.expanded:before,
.multi-menu-trigger.expanded:after {
  display: none;
}

.multi-menu-overflow {
  background: #fff;
}

.multi-menu-item.has-children:after,
.multi-select-menu-expand-icon:before,
.multi-menu-overflow-icon {
  mask-image: url('../Icon/icons/expand-arrow.svg');
  mask-size: contain;
}

.wrapped-select.boxed {
  height: 44px;

  .multi-menu-trigger-element {
    height: inherit;
    .multi-select-menu-selection {
      height: inherit;
      box-shadow: unset;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .multi-select-menu-expand-icon {
        &:before {
          right: 26px;
        }
      }
    }
  }
}

.wrapped-select.wrapped-select-error .multi-select-menu-selection {
  box-shadow: inset 0 0 0 1px #f00;
}

.wrapped-select-error-message {
  color: #f44;
  font-weight: 500;
  margin: 5px 16px 0;
}

.select-field__label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;

  .icon {
    background-color: #757575;
  }
}

.select-field__tooltip {
  position: absolute;
  right: 0;
  top: -8px;
}
