@import 'styles/_colors.scss';

.design-version-form {
  min-width: 145px;
  font-size: 14px;
  height: 45px;
  margin-top: -5px;

  .modal-header {
    padding-top: 42px;

    h1 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 20px;
      color: $labelDark;
    }

    .icon {
      flex: 1;
      background-color: $labelDark;
    }

    .design-version-form-title-left {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      gap: 12px;

      h1 {
        margin: 0;
        white-space: initial;
        line-height: 40px;
        // Ellipsis overflow
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  label {
    color: $labelDark;
    display: block;
    font-size: 14px;
  }

  .modal-actions {
    font-size: 12px;

    .btn.btn-outlined.btn-teal {
      &:hover {
        border-color: $teal;
        background-color: $lightTeal;
        color: $teal;

        .icon {
          background-color: $teal;
        }
      }
    }
  }

  .wrapped-input {
    margin: 0 0 16px;
    width: 100%;

    label {
      margin-bottom: 10px;
    }

    input {
      line-height: 3;
      font-size: 14px;
    }

    .wrapped-input-prefix {
      display: flex;
      margin-right: 16px;
      padding: 0;
    }
  }

  .icon-trash-small {
    mask-position: center;
    background-color: #999;
    cursor: pointer;
  }

  .design-version-creator {
    label {
      font-size: 12px;
    }

    .version-creator-info {
      display: flex;
      flex-direction: column;
      color: $placeholder;
      background: $lightGray;
      padding: 10px 16px;
      border-radius: 9px;
      justify-content: space-between;
    }

    .version-creator-info p {
      font-size: 14px;
    }

    .text-description {
      font-size: 12px;
    }
  }
}

.design-version-form .form-row .icon-button-wrapper {
  margin: 0 0 0 8px;
}

.design-version-form .modal-content label {
  margin: 0 0 10px 16px;
}
