@import 'styles/_colors.scss';

.csv-import-modal h1 {
  margin-bottom: 40px;
}

.csv-import-matching-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.csv-import-matching-row > * {
  flex: 1;
}

.csv-import-matching-row > p {
  margin: 0 0 10px;
  font-weight: 600;
}

.csv-import-modal-error p {
  margin: 0 0 20px;
}

.csv-import-modal-error > *:last-child {
  margin-bottom: 0;
}

.csv-import {
  > h2 {
    color: $body;
    font-size: 16px;
    margin-bottom: 8px;
  }

  > p {
    color: $body2;
    font-size: 14px;
    margin-bottom: 24px;
  }

  > span {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    gap: 8px;

    .icon {
      height: 18px;
      width: 18px;
    }
  }
}

.csv-import {
  .help-link {
    font-size: 12px;
    color: #424242;
    background-color: #eee;
    padding: 5px;
  }
}

.csv-import-format-link {
  color: #ef4c30;
  text-decoration: none;
  padding: 1px 3px;
  margin-right: -3px;
  margin-left: -3px;

  &:hover {
    background-color: rgba(239, 76, 48, 0.1);
    padding: 1px 3px;
    border-radius: 3px;
  }
}

.csv-import-modal-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: auto;
  padding: 32px;

  border: 2px dashed #ddd;
  border-radius: 6px;

  > * {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    color: $body2;
  }
}

.csv-import-modal-done {
  text-align: center;

  h2 {
    font-size: 24px;
    margin-bottom: 0;
  }
}
