@import 'styles/_colors.scss';

.right-panel,
.right-panel-backdrop {
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 0;
  width: 330px;
  z-index: 1001;
}

.right-panel-backdrop {
  z-index: 0;
  width: 332px;
}

.right-panel-header {
  min-height: min-content;
  position: relative;
}

.right-panel-selected-object-name {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  overflow: hidden;
}

.right-panel-selected-object-name span {
  display: block;
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 8px;
}

.right-panel-multi-selected-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;

  span {
    display: block;
    line-height: 32px;
    font-size: 18px;
    font-weight: 500;
    margin-right: 8px;
  }

  .right-panel-overflow-menu-trigger {
    position: unset;
    right: unset;
    top: unset;
  }
}

.right-panel-type-menu .icon {
  background: #444;
  opacity: 0.5;
  margin-right: 5px;
}

.right-panel-type-menu .multi-menu-item-label > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.right-panel-selected-object-name + .object-type-menu .multi-select-menu-value {
  overflow: visible !important;
}

.right-panel-back {
  margin: -24px -32px 0;
  height: 40px;
  min-height: 40px;

  border-bottom: 1px solid $border;
  background-color: transparent;
  color: $screens;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 32px;
  cursor: pointer;

  text-transform: uppercase;
  font-weight: 500;

  transition: background 0.2s;

  &:hover {
    background-color: $lightScreens;
  }

  .icon {
    background-color: $screens;
    margin-right: 8px;
  }
}

.object-type-header {
  color: #9e9e9e;
}

.right-panel-overflow-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-panel-overflow-option .icon,
.right-panel-overflow-option .material-icons {
  margin-right: 8px;
}

.right-panel-overflow-menu-trigger {
  position: absolute;
  right: -12px;
  top: 0;
}

.right-panel-collapsible {
  margin: 0 -32px 16px;
  padding: 16px 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.right-panel-collapsible-done {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 -16px;
  margin-top: 20px;
}

.right-panel-collapsible-collapsed {
  background: #f1f1f1;
  padding: 8px 16px;
  margin: 16px -16px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s;
}

.right-panel-collapsible-collapsed .icon {
  margin-right: 8px;
  margin-top: -6px;
}

.right-panel-collapsible-collapsed:hover {
  background: #e5e5e5;
}

.right-panel-collapsible-options .data-binding-remove,
.right-panel-collapsible-options .binding-type-select,
.right-panel-collapsible-options .visibility-form-extensions-accordion {
  display: none;
}

.right-panel-collapsible-options .data-binding {
  background: transparent;
  box-shadow: none;
  padding: 0;
  margin: 0 -16px;
}

.right-panel-collapsible-options .data-binding label {
  display: block;
  margin: 0 16px 6px;
}

.right-panel-collapsible-options .wrapped-select {
  height: 44px;
  font-size: 14px;
}

.right-panel-collapsible-options
  .wrapped-select.white:not(.expanded)
  .multi-select-menu-selection,
.right-panel-collapsible-options .selectable-input {
  font-size: 14px;
  background: #f1f1f1;
  box-shadow: none;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 40px;
}

.right-panel-collapsible-options .multi-select-menu-expand-icon:before {
  right: 26px;
}

.right-panel-collapsible-options .selectable-input-input {
  padding-left: 16px;
}

.right-panel-collapsible-options .selectable-input-selected-value {
  background: #ef4c30;
  border-radius: 20px;
  height: 28px;
  padding-left: 12px;
  margin-left: 16px;
}

.right-panel-collapsible-options .selectable-input-menu-trigger {
  right: 20px;
}

.right-panel-collapsible-options .selectable-input-value {
  right: 65px;
}

.right-panel-collapsible .filter-options-items .icon-trash-small {
  background: #8a8a8a;
}

.library-condition-form .filter-options-items .selectable-input-wrapper-boxed,
.right-panel-collapsible .filter-options-items .selectable-input-wrapper-boxed {
  background: #f1f1f1;
  padding-left: 16px;
}

.library-condition-form
  .filter-options-items
  .selectable-input-wrapper-boxed
  .selectable-input,
.right-panel-collapsible
  .filter-options-items
  .selectable-input-wrapper-boxed
  .selectable-input {
  padding: 0;
  height: 100%;
  background: transparent;
}

.library-condition-form
  .filter-options-items
  .selectable-input-wrapper-boxed
  .selectable-input-menu-trigger,
.right-panel-collapsible
  .filter-options-items
  .selectable-input-wrapper-boxed
  .selectable-input-menu-trigger {
  right: 4px;
}

.library-condition-form .filter-options-add,
.right-panel-collapsible .filter-options-add {
  margin-top: -6px;
}

.library-condition-form {
  margin-bottom: 8px;
}

.right-panel-visibility-collapsed {
  color: #ef4c30;
}

.right-panel-visibility-collapsed .icon {
  background-color: #ef4c30;
}

.multiple-objects-header {
  margin: 20px 0;
}

.magic-layout-options .device-visibility {
  position: relative;
  margin: 0 -16px;

  .device-visibility-label {
    padding: 8px 16px 2px;
  }

  .device-element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    min-height: 50px;

    background-color: $lightGrey;
    border-radius: 6px;
    padding: 10px 16px;
    margin: 12px 0px;

    &:active,
    &:hover {
      cursor: pointer;
      background-color: $grey;

      .icon-button-wrapper {
        opacity: 1;
      }
    }

    &.hidden {
      opacity: 0.5;

      .icon-button-wrapper {
        opacity: 1;
      }
    }

    .device-label {
      flex: 1;

      display: flex;
      flex-direction: column;

      .device-label-title {
        display: block;
        flex: 1;
        padding-left: 12px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.magic-layout-options .library-inspect-menu-control {
  margin-top: 30px;
}

.magic-layout-options .text-control input {
  height: 44px;
  font-size: 14px;
  padding: 20px;
  border-radius: 6px;
  width: 100%;
}

.magic-layout-row {
  padding-bottom: 20px;
}

.magic-layout-column {
  width: 48%;
}

.magic-layout-separator {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: -32px;
  margin-right: -32px;
}

.panel-item-header .magic-layout-panel-item.icon-button-wrapper {
  &:hover {
    background-color: $lightTeal;

    .icon {
      background-color: $teal;
    }
  }

  &.expanded .icon {
    background-color: $teal;
  }
}
