.new-app-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 684px;
  min-height: 472px;
  padding: 35px 0 0;
}

.has-responsive-option {
  width: 764px;
}

.new-app-form {
  flex: 1;
}

.new-app-datasource-help,
.new-app-datasource-option {
  margin-bottom: 10px;
}

.new-app-responsive-help {
  margin-top: 50px;
}

.new-app-responsive-help,
.new-app-responsive-option {
  margin-bottom: 10px;
}

.new-app-datasource-app-select {
  /* 20px from .wrapped-radio-styled-bullet plus 4px from label padding-left */
  margin-left: 24px;
  max-width: 200px;
}

.new-app-advanced-button {
  position: absolute;
  right: 0;
  top: -8px;
}

.new-app-form-organization {
  margin-bottom: 20px;
}

.new-app-platform-label {
  color: #333;
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.new-app-platform-version {
  color: #05a999;
  background-color: #cbeeea;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 12px;
  position: absolute;
  margin-top: 10px;
  margin-left: -125px;
  border-radius: 3px;
}

.new-app-form-footer {
  border-top: solid 1px #eee;
  padding: 15px 40px;
  display: flex;
}

.new-app-form-footer .btn.advanced {
  margin-right: 10px;
}

.new-app-form-footer .btn.previous,
.new-app-form-footer .btn.advanced {
  background-color: transparent;
  color: #9e9e9e;
}

.new-app-form-footer .btn.previous:hover,
.new-app-form-footer .btn.previous:focus,
.new-app-form-footer .btn.previous:active,
.new-app-form-footer .btn.advanced:hover,
.new-app-form-footer .btn.advanced:focus,
.new-app-form-footer .btn.advanced:active {
  background-color: rgb(238, 238, 238);
  /* border-color: #666; */
}

.new-app-form-footer .btn.align-right {
  margin-left: auto;
}

.new-app-advanced-form-title {
  color: #333;
  font-size: 18px;
  margin-left: 100px;
}

.new-app-advanced-settings {
  margin: 40px 40px 20px 120px;
  color: rgb(97, 97, 97);
  font-size: 14px;
  max-width: 480px;
}

.new-app-advanced-settings a.new-app-upgrade-plan {
  float: right;
  margin: -31px 70px 0 0;
  z-index: 1;
  position: relative;
  color: #00a898;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  padding: 4px 6px;
  border-radius: 4px;
}

.new-app-advanced-settings a.new-app-upgrade-plan:hover {
  background: rgba(0, 168, 152, 0.1);
}

.new-app-form .adalo-alert {
  margin: 0 40px;
}

#new-app-platform-form {
  margin-top: 25px;
}

#new-app-branding-form {
  display: flex;
  flex-direction: column;
}

#new-app-branding-form > * {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 330px;
}

#new-app-branding-form .wrapped-input label {
  color: rgb(51, 51, 51);
}

#new-app-branding-form .wrapped-input input {
  font-size: 15px;
  line-height: 49px;
}

#new-app-branding-form .color-dropdown {
  margin: 0;
}

#new-app-template-form {
  margin: 20px 0 0;
  padding: 0 52px;
  max-height: 318px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.has-responsive-option #new-app-template-form {
  padding: 0 114px;
}

#new-app-template-form .template-select {
  margin-bottom: 25px;
}

.new-app-template-loading {
  height: 100%;
  width: 100%;
  position: relative;
}

.new-app-template-loading .loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}
