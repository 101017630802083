.library-inspect .library-inspect-help-text {
  font-size: 11px;
  color: #777;
  margin-top: 6px;
}

.library-inspect-help-text-header {
  font-size: 11px;
  color: #777;
  margin-bottom: 16px;
  margin-top: -6px;
}
