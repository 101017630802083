.team-settings-billing h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0;
}

.team-settings-sub-free {
  > .free-plan-container {
    margin-bottom: 2em;
  }
}

.planselect-monthly-annual {
  display: flex;
  font-size: 14px;
  margin: 5px 0;

  > * {
    margin-right: 10px;
  }
}

.planselect-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  column-gap: 8px;
}

.team-settings-plan-select {
  margin: 6px -20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 4px;
}

.team-settings-plan-select.error {
  outline: 1px solid #f00;
}

.team-settings-plan-item {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  flex: 1;
  cursor: pointer;
  position: relative;
  transition: border 0.2s;
}

.team-settings-plan-item + .team-settings-plan-item {
  margin-top: 16px;
}

.team-settings-plan-header,
.team-settings-plan-body {
  padding: 15px 19px;
}

.team-settings-plan-header {
  padding-top: 12px;
  background: #fafafa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.team-settings-plan-title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.team-settings-plan-item h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 2px;
}

.team-settings-plan-checkmark {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.2s;
}

.team-settings-plan-price {
  color: #00a898;
  transition: color 0.2s;
  font-size: 16px;
  font-weight: 500;
  margin-left: 0.4em;
}

.team-settings-plan-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #666;
  margin-bottom: 8px;
}

.team-settings-plan-row p {
  flex: 1;
  margin: 0 0 0 16px;
}

.team-settings-plan-row .icon {
  background: #aaa;
}

.team-settings-plan-item:hover {
  border-color: #d2d2d2;
}

.team-settings-plan-item:hover .team-settings-plan-checkmark {
  opacity: 1;
}

.team-settings-plan-item.team-settings-plan-selected {
  border-color: #ffc00e;
}

.team-settings-plan-item.team-settings-plan-selected
  .team-settings-plan-checkmark {
  opacity: 1;
  background-color: #ffc00e;
}

.team-settings-plan-selected.team-settings-plan-emphasized
  .team-settings-plan-footnote {
  background: #ffc00e;
}

.settings-form-input.settings-stripe-input {
  min-height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 44px;
  border: 1px solid #e0e0e0;
  border-radius: 9px;
  padding: 0 8px 0 16px;
}

.coupon-code-link {
  display: block;
  margin-top: 10px;
}

.team-settings-billing-form {
  position: relative;
}

.team-settings-billing-form-overlay {
  position: absolute;
  left: -16px;
  top: 0;
  right: -16px;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  cursor: wait;
}

.team-settings-billing-buttons {
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.team-settings-billing-buttons .btn + .btn {
  margin-left: 8px;
}

.team-settings-billing-graphic {
  height: 150px;
  background: url('./paying-us.png') no-repeat center;
  background-size: contain;
  margin: 10px 0 -10px;
}

.plan-select-additional-info {
  margin-top: 20px;
  color: #777;
}

.settings-billing-form-payment {
  margin-top: 20px;
}

.order-summary {
  position: relative;
  background: white;
  border-radius: 12px;
  border: 1px solid #eee;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  float: right;
  width: 40%;
  margin-top: -37px;
}

.order-summary-header {
  align-items: center;
  padding-left: 15px;
  border-bottom: 1px solid;
  border-bottom-color: #eee;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

.order-summary-content {
  padding: 20px;
  font-size: 14px;
  color: dimgray;
  line-height: 2.2;
}

.order-summary-footer {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  background-color: #e5f6f4;
  border-top: 1px solid;
  border-top-color: #eee;
  border-bottom: 1px solid;
  border-bottom-color: #eee;
  width: 100%;
  float: left;
}

.order-summary-plan-renewal {
  padding: 15px;
  color: dimgray;
  float: left;
}

.payment-form {
  width: 50%;
  max-width: 640px;
  padding-left: 16px;
}

.payment-form-subheader {
  padding-left: 16px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 2em;
}

.payment-form .multi-select-menu-selection {
  margin-left: -15px;
  margin-top: 5px;
  border-radius: 9px;
  line-height: 2.5em;
  margin-right: -15px;
}

.payment-form-country-input {
  margin-left: -15px;
  margin-right: -15px;
}

.payment-form-country-input .wrapped-select-error-message {
  font-weight: 200;
}

.organization-details {
  display: flex;
  align-items: center;
  margin-top: -10px !important;
  margin-bottom: 22px;
  font-size: 16px;
}

.organization-details-name {
  font-weight: bold;
  margin: 0 5px;
}

.organization-details-username {
  font-weight: bold;
  margin-left: 5px;
}

.wrapped-native-select {
  margin-top: 10px;
}

.wrapped-native-select-error {
  box-shadow: 0 0 0 1px #f00;
  border-radius: 6px;
}

.label-styling {
  padding-left: 15px;
  color: #666;
}

.payment-summary {
  height: 350px;
}

.left {
  float: left;
  clear-after: left;
}
.right {
  float: right;
  clear-after: right;
}

.clear-left {
  clear: left;
}

.input-padding {
  margin-left: 14px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.input-padding input {
  width: 205px;
}

.input-padding label {
  margin-left: -14px;
}

.clear-right {
  clear: right;
}
.order-summary-plan-renewal p {
  padding-top: 10px;
}

.team-settings-annual-plan-promo {
  background-color: #eef7f5;
  padding: 5px 20px 20px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
}

.team-settings-annual-plan-promo button {
  margin-left: -4px;
}

.new-plan-pricing {
  display: flex;
  flex-direction: row;
}

.money-mouth-image {
  width: 25px;
  height: 25px;
  margin-top: 17px;
  margin-right: 5px;
}

.paymentSuccessLogo {
  width: 200px;
  height: 200px;
}

.team-settings-annual-plan-promo p {
  margin-bottom: 20px;
}

.pay-monthly-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  color: #8a8a8a;
  text-decoration: none;
  position: relative;
  outline: none;
  border-radius: 50px;
  transition: all 0.2s;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 12px;
  cursor: pointer;
  margin: 0 15px;
}

.pay-monthly-btn:hover {
  background-color: #f5f5f5;
}

.switch-to-yearly-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  color: #05a898;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  outline: none;
  border-radius: 50px;
  transition: all 0.2s;
  justify-content: center;
  border: 1px solid #05a898;
  font-size: 14px;
  letter-spacing: 0.5px;
  cursor: pointer;
  margin: 0 15px;
}

.switch-to-yearly-btn:hover {
  background: #05a898;
  color: white;
}

.update-card-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-right: 30px;
}

.update-card-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  border-radius: 20px;
  height: 40px;
  text-transform: uppercase;
  outline: none;
  margin-top: 10px;
  padding: 15px 25px;
  transition: all 0.2s;
  justify-content: center;
  border: 1px solid #8a8a8a;
  color: black;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.team-settings-warning {
  border-radius: 6px;
  flex: 1 1;
  cursor: pointer;
  position: relative;
  transition: border 0.2s;
  width: inherit;
  margin: 0px -12px;
}

.team-settings-warning-header {
  background-color: rgba(239, 76, 48, 0.2);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 6px 18px;
}

.team-settings-warning-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px -6px;
  font-weight: 500;
}

.team-settings-warning-title p {
  flex: 1;
  margin: 0 0 0 16px;
}

.team-settings-warning-body {
  background-color: rgba(239, 76, 48, 0.075);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px 49px;
  margin-bottom: 8px;
}

.team-settings-warning-list {
  padding: 0px 14px;
}

.team-settings-warning-list a {
  color: #333;
  text-decoration: none;
  display: flex;
  font-weight: 500;
}

.team-settings-warning-list-item a > p {
  margin: 0 4px 0 0;
}

.team-settings-warning-list-count {
  margin: 0 0 0 12px;
}

.team-settings-warning-list-more {
  font-weight: 500;
}
.team-settings-warning-list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 12px 0px;
}

.team-settings-warning-list-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.billing-payment-form {
  width: 50% !important;
}

.team-settings-form-button {
  margin: 0px -8px 3px 0px;
}

.coupon-code-error {
  color: #ef4c30;
  font-size: 12px;
}

.coupon-code-success {
  color: #00a898;
  font-size: 12px;
}
