.xano-upsell-card {
  display: flex;
  flex-direction: column;
  min-height: 60px;
  align-items: left;
  justify-content: left;
  text-align: left;
  padding: 12px 15px 15px;
  margin: 0 -16px 8px;
  background-color: #fceeeb;
  border-radius: 8px;

  h2.upsell-title {
    text-align: left;
    width: 100%;
    height: 20px;
    padding: 2px 0 0 26px;
    margin: 0 0 8px;
    background: url('./assets/xano-icon.svg') no-repeat left;
  }

  a.upsell-link {
    display: block;
    width: 136px;
    height: 32px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    padding: 8px 16px;
    margin: 12px 0 0;
    color: #e26b54;
    border: 1px solid #e26b54;
    border-radius: 16px;
  }
}
