@import 'styles/_colors.scss';

.trial-signup-modal {
  width: 560px;
  padding-left: 60px;
  padding-right: 60px;
}

.trial-warning {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trial-warning-button-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  z-index: 10000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 44px;
  padding: 0 80px 0 20px;
  color: #777;

  .integrations-trial-wrapper {
    background: #f5f5f5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    font-weight: 400;

    span[role='img'] {
      margin-right: 8px;
    }

    > i {
      color: #ef4c30;
      margin-right: 5px;
    }

    > b {
      margin-left: 5px;
    }
  }

  &--button {
    display: flex;
    gap: 13px;
    position: relative;
    padding: 4px 5px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #ebf6f5;
      border: 1px solid $teal;
    }

    border-radius: 100px;

    .btn.trial-warning-button {
      margin: 0;
    }

    .free-trial-label,
    .integrations-trial-wrapper {
      transition: all 0.2s ease;
    }

    @media (max-width: 1440px) {
      .free-trial-label {
        display: none;
      }
    }

    @media (max-width: 1390px) {
      gap: 0;
      padding-left: 7px;

      .free-data-storage__wrapper {
        padding: 0 10px;
      }

      .free-data-storage__label {
        display: none;
      }
    }

    @media (max-width: 1240px) {
      .integrations-trial-wrapper {
        display: none;
      }
    }

    @media (max-width: 1120px) {
      .free-data-storage__wrapper {
        display: none;
      }
    }

    .integrations-trial-wrapper {
      background-color: transparent;
    }

    span {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .trial-warning-button.btn-secondary.btn-teal {
      border: 1px solid $lightTeal;
    }
  }

  .integrations-trial-tooltip-wrapper {
    height: 45px;
    display: flex;
    align-items: center;
  }
}

.btn.trial-warning-button {
  margin: 0 10px;
}

.trial-warning-banner {
  background: rgba(239, 76, 48, 0.1);
  color: #ef4c30;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px 0 32px;
}

.trial-signup-modal-header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(239, 76, 48, 0.2);
}

.trial-signup-modal-header .icon-close-big {
  background-color: rgba(0, 0, 0, 0.5);
}

.trial-warning-icon {
  background-color: #ef4c30;
  margin-right: 8px;
}

.trial-warning-banner-text {
  flex: 1;
}

.trial-signup-success {
  padding: 100px;
}

.modal h1 {
  &.trial-signup-success-message,
  &.trial-signup-unsubscribe-message {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
  }
}

.trial-signup-success-message + p,
.trial-signup-unsubscribe-message + p {
  color: #8a8a8a;
  margin-bottom: 30px !important;
  font-size: 16px;
}

.modal-plan-select {
  .team-settings-plan-select {
    margin: 0;
    padding: 0;
    flex-direction: row;
  }

  .team-settings-plan-item {
    & + .team-settings-plan-item {
      margin-top: 0;
      margin-left: 16px;
    }
  }
}

.modal-plan-select-billing {
  .team-settings-plan-select {
    margin: 0;
    padding: 0;
    flex-direction: column;
  }

  .team-settings-plan-item {
    & + .team-settings-plan-item {
      margin-top: 16px;
    }
  }
}

.trial-signup-modal-storage {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 36px;
  padding: 15px 20px;

  h2 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  p {
    color: #666;
  }
}

.monthly-annual-planselect {
  display: flex;
  font-size: 14px;
  margin: -5px 10px 10px;
  p {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.toggle-offer {
  display: flex;
  color: #00a898;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  span {
    background: #00a898;
    margin-top: -3px;
  }
}
