@import 'styles/_colors.scss';

.tooltip-trigger {
  position: relative;
  display: inline-block;

  &.icon-styles {
    .icon {
      background: $label;
      transition: background 0.2s ease-in-out;

      &:hover {
        background: $labelDark;
      }
    }
  }
}

.tooltip-container {
  display: flex;
  flex-direction: column;
  background-color: $black;
  border-radius: 6px;
  color: $white;
  font-size: 12px;
  font-family: inherit;
  line-height: 20px;
  padding: 14px 16px;
  white-space: pre-wrap;
  word-wrap: break-word;

  max-width: 300px;
  z-index: 999999;

  &-small {
    padding: 8px 12px;
  }

  a,
  code {
    color: $white;
  }
}

.tooltip-link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;

  .icon {
    margin-right: 4px;
  }

  a {
    color: #ffc00e;
  }
}

.trial-tooltip-container {
  @extend .tooltip-container;
  max-width: 460px;
  padding: 40px;

  h1,
  .trial-tooltip-header {
    font-size: 19px;
    margin-bottom: 3px;
    font-weight: 500;
  }

  h2,
  .trial-tooltip-subheader {
    font-size: 15px;
    margin-bottom: 3px;
  }

  .tooltip-features-lists {
    display: flex;
    gap: 8px;

    li {
      font-size: 10px;
    }
  }

  p {
    margin: 0px;
    line-height: 16px;
  }

  ul {
    list-style-type: none;
  }

  .icon-check-circle {
    width: 14px;
    height: 14px;
    mask-size: contain;
    margin-right: 5px;
    position: relative;
    top: 3px;
  }

  .btn.btn-text {
    color: $yellow;
    padding: 0px;
    height: 0px;
    margin: 20px 0px 0px auto;
    font-size: 14px;
  }

  .btn.btn-text:hover,
  .btn.btn-text:focus,
  .btn.btn-text.btn-focussed {
    background-color: transparent;
    color: darken($yellow, 5%);
  }
}
