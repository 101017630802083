.editor-add-panel-tabs {
  display: flex;
  align-items: center;
  justify-content: stretch;
  border-bottom: #eee solid 1px;
  border-top: #eee solid 1px;
  margin: 0 -32px 16px;
  min-height: min-content;
}

.responsive-add-menu .editor-add-panel-tabs {
  margin: 0 -32px 0;
  border-top: none;
}

.responsive-add-menu .editor-add-panel-search {
  border-top: 1px solid #f5f5f5;
}

.editor-add-panel-tab {
  flex: 1;
  text-align: center;
  margin-left: 10px;
  font-size: 13px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -1px;
}

.editor-add-panel-tab:hover {
  color: #757575;
}

.editor-add-panel-tab:after {
  content: ' ';
  opacity: 0;
  margin-top: 10px;
  border: 2px solid #00a898;
  border-radius: 3px;
  width: 85%;
  transition: opacity 0.4s;
}

.editor-add-panel-tab.editor-add-panel-tab-active {
  color: #00a898;
  font-weight: 500;
}

.editor-add-panel-tab-active:after {
  opacity: 1;
}

.editor-add-panel-tab:last-child {
  margin-right: 10px;
}
