.autocomplete-input {
  &__input-container {
    padding: 16px;
    border-radius: 6px;
    width: 100%;
    border: 1px solid #e0e0e0;
    height: 60px;
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-bottom: 3px;

    &--elevated {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--disabled {
      background: #f2f2f2;

      .autocomplete-input__input {
        background: #f2f2f2;
      }
    }

    &--invalid {
      border-color: #f44;
    }

    &--panel {
      width: auto;
      margin: 5px -16px 5px -16px;
    }
  }

  &__input {
    outline: none;
    border: none;
    resize: none;
    font: inherit;
    flex-grow: 1;
    height: 100%;
  }
}
