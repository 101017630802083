@import 'styles/_colors.scss';

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  mask-type: alpha;
  mask-repeat: no-repeat;
  mask-position: center;
  background: #333;

  // colors
  &-transparent {
    background-color: transparent;
  }

  &-teal {
    background-color: $teal;
  }

  &-orange {
    background-color: $orange;
  }

  &-purple {
    background-color: $purple;
  }

  &-yellow {
    background-color: $yellow;
  }

  &-green {
    background-color: $green;
  }

  &-darkPink {
    background-color: $darkPink;
  }

  &-white {
    background-color: $white;
  }

  &-darkerGray {
    background-color: $darkerGray;
  }

  &-filterLabel {
    background-color: $filterLabel;
  }

  &-midGray {
    background-color: $midGray;
  }

  &-medium {
    width: 21px;
    height: 21px;
    mask-size: contain;
  }

  // sizing
  &-small {
    width: 14px;
    height: 14px;
    mask-size: contain;
  }

  &-big {
    width: 28px;
    height: 28px;
    mask-size: contain;
  }

  // styles
  &-style {
    &-circle {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      > .icon {
        background-color: white;
      }

      &.icon-yellow {
        > .icon {
          background-color: black;
        }
      }

      &.icon-small {
        > .icon {
          height: 10px;
          width: 10px;
          mask-size: contain;
        }
      }

      &.icon-medium {
        > .icon {
          height: 17px;
          width: 17px;
          mask-size: contain;
        }
      }

      &.icon-big {
        > .icon {
          height: 24px;
          width: 24px;
          mask-size: contain;
        }
      }
    }
  }

  // icons
  &-color-lens {
    mask-image: url('./icons/color-lens.svg');
  }

  &-dollar {
    mask-image: url('./icons/dollar.svg');
  }

  &.flipped {
    transform: rotateX(180deg);
  }
}

.icon-placeholder {
  background: transparent;
  mask: none;
}

.icon-label {
  mask-image: url('./icons/text.svg');
}

.icon-section {
  mask-image: url('./icons/section.svg');
}

.icon-ellipse {
  mask-image: url('./icons/ellipse.svg');
}

.icon-circle {
  mask-image: url('./icons/circle.svg');
}

.icon-pen {
  mask-image: url('./icons/pen.svg');
}

.icon-text {
  mask-image: url('./icons/text.svg');
}

.icon-list {
  mask-image: url('./icons/list.svg');
}

.icon-image {
  mask-image: url('./icons/image.svg');
}

.icon-button {
  mask-image: url('./icons/button.svg');
}

.icon-forms {
  mask-image: url('./icons/forms.svg');
}

.icon-input {
  mask-image: url('./icons/input.svg');
}

.icon-image-upload,
.icon-image-upload-small {
  mask-image: url('./icons/image-selector.svg');
}

.icon-file-upload {
  mask-image: url('./icons/file-upload.svg');
}

.icon-file-upload-small {
  mask-image: url('./icons/file-upload-small.svg');
}

.icon-select {
  mask-image: url('./icons/select.svg');
}

.icon-select-small {
  mask-image: url('./icons/select-small.svg');
}

.icon-fit-window {
  mask-image: url('./icons/fit-window.svg');
}

.icon-inspect {
  mask-image: url('./icons/inspect.svg');
}

.icon-connect {
  mask-image: url('./icons/connect.svg');
}

.icon-close {
  mask-image: url('./icons/close.svg');
}

.icon-close-big {
  mask-image: url('./icons/close-big.svg');
}

.icon-clear {
  mask-image: url('./icons/clear.svg');
}

.icon-layers {
  mask-image: url('./icons/layers.svg');
}

.icon-section-small {
  mask-image: url('./icons/section-small.svg');
}

.icon-ellipse-small {
  mask-image: url('./icons/ellipse-small.svg');
}

.icon-shape-small {
  mask-image: url('./icons/shape.svg');
}

.icon-image-small {
  mask-image: url('./icons/image.svg');
}

.icon-label-small {
  mask-image: url('./icons/text-small.svg');
}

.icon-list-small {
  mask-image: url('./icons/list-small.svg');
}

.icon-input-small {
  mask-image: url('./icons/input-small.svg');
}

.icon-eye {
  mask-image: url('./icons/eye.svg');
}

.icon-eye-closed {
  mask-image: url('./icons/eye-closed.svg');
}

.icon-hidden {
  mask-image: url('./icons/hidden.svg');
}

.icon-group {
  mask-image: url('./icons/folder.svg');
}

.icon-group-small {
  mask-image: url('./icons/folder.svg');
}

.icon-lightning {
  mask-image: url('./icons/lightning.svg');
}

.icon-expand {
  mask-image: url('./icons/expand-arrow.svg');
}

.icon-expand-vertical {
  mask-image: url('./icons/expand-arrow.svg');
  transform: rotate(90deg);
}

.icon-down-arrow-small {
  mask-image: url('./icons/down-arrow-small.svg');
}

.icon-up-arrow-small {
  mask-image: url('./icons/up-arrow-small.svg');
}

.icon-trash {
  mask-image: url('./icons/trash.svg');
}

.icon-trash-small {
  mask-image: url('./icons/trash-small.svg');
}

.icon-pencil-small {
  mask-image: url('./icons/pencil-small.svg');
}

.icon-text-align-left {
  mask-image: url('./icons/text-align-left.svg');
}

.icon-text-align-center {
  mask-image: url('./icons/text-align-center.svg');
}

.icon-text-align-right {
  mask-image: url('./icons/text-align-right.svg');
}

.icon-database {
  mask-image: url('./icons/database.svg');
}

.icon-table {
  mask-image: url('./icons/table.svg');
}

.icon-users {
  mask-image: url('./icons/users.svg');
}

.icon-user {
  mask-image: url('./icons/user.svg');
}

.icon-contact-email {
  mask-image: url('./icons/contact-email.svg');
}

.icon-fat-arrow {
  mask-image: url('./icons/fat-arrow.svg');
}

.icon-plus {
  mask-image: url('./icons/plus.svg');
}

.icon-plus-small {
  mask-image: url('./icons/plus-small.svg');
}

.icon-play {
  mask-image: url('./icons/play.svg');
}

.icon-play-demo {
  mask-image: url('./icons/play-demo.svg');
}

.icon-security-badge {
  mask-image: url('./icons/security-badge.svg');
}

.icon-info {
  mask-image: url('./icons/info.svg');
}

.icon-info-outline {
  mask-image: url('./icons/info-outline.svg');
}

.icon-screen {
  mask-image: url('./icons/screen.svg');
}

.icon-screen-lock {
  mask-image: url('./icons/screen-lock.svg');
}

.icon-lock {
  mask-image: url('./icons/lock.svg');
}

.icon-lock-small {
  mask-image: url('./icons/lock-small.svg');
}

.icon-align-left {
  mask-image: url('./icons/align-left.svg');
}

.icon-align-right {
  mask-image: url('./icons/align-right.svg');
}

.icon-align-horizontal-center {
  mask-image: url('./icons/align-horizontal-center.svg');
}

.icon-align-top {
  mask-image: url('./icons/align-top.svg');
}

.icon-align-bottom {
  mask-image: url('./icons/align-bottom.svg');
}

.icon-align-vertical-center {
  mask-image: url('./icons/align-vertical-center.svg');
}

.icon-align-parent-left {
  mask-image: url('./icons/align-parent-left.svg');
}

.icon-align-parent-right {
  mask-image: url('./icons/align-parent-right.svg');
}

.icon-align-parent-horizontal-center {
  mask-image: url('./icons/align-parent-horizontal-center.svg');
}

.icon-align-parent-top {
  mask-image: url('./icons/align-parent-top.svg');
}

.icon-align-parent-bottom {
  mask-image: url('./icons/align-parent-bottom.svg');
}

.icon-align-parent-vertical-center {
  mask-image: url('./icons/align-parent-vertical-center.svg');
}

.icon-date-picker {
  mask-image: url('./icons/date-picker.svg');
}

.icon-date-picker-small {
  mask-image: url('./icons/date-picker-small.svg');
}

.icon-web-view,
.icon-web-view-small {
  mask-image: url('./icons/webview.svg');
}

.icon-user-login {
  mask-image: url('./icons/user-login.svg');
}

.icon-user-login-small {
  mask-image: url('./icons/user-login-small.svg');
}

.icon-home {
  mask-image: url('./icons/home.svg');
}

.icon-home-small {
  mask-image: url('./icons/home-small.svg');
}

.icon-component {
  mask-image: url('./icons/component.svg');
}

.icon-component-small,
.icon-libraryComponent-small {
  mask-image: url('./icons/component-small.svg');
}

.icon-component-instance {
  mask-image: url('./icons/component-instance.svg');
}

.icon-component-instance-small {
  mask-image: url('./icons/component-instance-small.svg');
}

.icon-distribute-vertical-spacing {
  mask-image: url('./icons/distribute-vertical-spacing.svg');
}

.icon-distribute-horizontal-spacing {
  mask-image: url('./icons/distribute-horizontal-spacing.svg');
}

.icon-json {
  mask-image: url('./icons/json.svg');
}

.icon-rows {
  mask-image: url('./icons/rows.svg');
}

.icon-columns {
  mask-image: url('./icons/columns.svg');
}

.icon-grid {
  mask-image: url('./icons/grid.svg');
}

.icon-carousel,
.icon-carousel-small {
  mask-image: url('./icons/carousel.svg');
}

.icon-account-circle {
  mask-image: url('./icons/account-circle.svg');
}

.icon-settings {
  mask-image: url('./icons/settings.svg');
}

.icon-done-all {
  mask-image: url('./icons/done-all.svg');
}

.icon-done {
  mask-image: url('./icons/done.svg');
}

.icon-apps {
  mask-image: url('./icons/apps.svg');
}

.icon-arrow-back {
  mask-image: url('./icons/arrow-back.svg');
}

.icon-arrow-next {
  mask-image: url('./icons/arrow-next.svg');
}

.icon-slim-arrow-next {
  mask-image: url('./icons/slim-arrow-next.svg');
}

.icon-chevron-left {
  mask-image: url('./icons/chevron-left.svg');
}

.icon-people {
  mask-image: url('./icons/people.svg');
}

.icon-magic-text {
  mask-image: url('./icons/magic-text.svg');
}

.icon-magic-number {
  mask-image: url('./icons/magic-number.svg');
}

.icon-magic-text-small {
  mask-image: url('./icons/magic-text-small.svg');
}

.icon-magic-visibility {
  mask-image: url('./icons/magic-visibility.svg');
}

.icon-checkbox,
.icon-check-box {
  mask-image: url('./icons/checkbox.svg');
}

.icon-checkbox-empty,
.icon-check-box-empty {
  mask-image: url('./icons/checkbox-empty.svg');
}

.icon-check {
  mask-image: url('./icons/check.svg');
}

.icon-check-small {
  mask-image: url('./icons/check.svg');
  mask-size: 18px;
}

.icon-check-circle {
  mask-image: url('./icons/check-circle.svg');
}

.icon-branding {
  mask-image: url('./icons/branding.svg');
}

.icon-report-problem {
  mask-image: url('./icons/report-problem.svg');
}

.icon-report-problem-small {
  mask-image: url('./icons/report-problem-small.svg');
}

.icon-people-outline {
  mask-image: url('./icons/people-outline.svg');
}

.icon-phonelink {
  mask-image: url('./icons/phonelink.svg');
}

.icon-more-vert {
  mask-image: url('./icons/more-vert.svg');
}

.icon-more-horiz {
  mask-image: url('./icons/more-horiz.svg');
}

.icon-uploaded-image {
  mask-image: url('./icons/uploaded-image.svg');
}

.icon-dynamic-image {
  mask-image: url('./icons/dynamic-image.svg');
}

.icon-warning {
  mask-image: url('./icons/warning.svg');
}

.icon-send {
  mask-image: url('./icons/send.svg');
}

.icon-copy {
  mask-image: url('./icons/copy.svg');
}

.icon-text-format {
  mask-image: url('./icons/text-format.svg');
}

.icon-filter-7 {
  mask-image: url('./icons/filter-7.svg');
}

.icon-date-range {
  mask-image: url('./icons/date-range.svg');
}

.icon-date-and-time-range {
  mask-image: url('./icons/date-and-time-range.svg');
}

.icon-date-none {
  mask-image: url('./icons/date-none.svg');
}

.icon-attach-file {
  mask-image: url('./icons/attach-file.svg');
}

.icon-dynamic-file {
  mask-image: url('./icons/file-icon-dynamic.svg');
}

.icon-autorenew {
  mask-image: url('./icons/autorenew.svg');
}

.icon-view-list {
  mask-image: url('./icons/view-list.svg');
}

.icon-photo {
  mask-image: url('./icons/photo.svg');
}

.icon-data {
  mask-image: url('./icons/data.svg');
}

.icon-screens {
  mask-image: url('./icons/screens.svg');
}

.icon-normal-screen {
  mask-image: url('./icons/normal_screen.svg');
}

.icon-welcome-screen {
  mask-image: url('./icons/welcome_screen.svg');
}

.icon-collections {
  mask-image: url('./icons/collections.svg');
}

.icon-search {
  mask-image: url('./icons/search.svg');
}

.icon-import-csv {
  mask-image: url('./icons/import-csv.svg');
}

.icon-export-csv {
  mask-image: url('./icons/export-csv.svg');
}

.icon-relationship {
  mask-image: url('./icons/relationship.svg');
}

.icon-relationship-single {
  mask-image: url('./icons/relationship-single.svg');
}

.icon-relationship-many {
  mask-image: url('./icons/relationship-many.svg');
}

.icon-computer {
  mask-image: url('./icons/icon-computer.svg');
}

.icon-smartphone {
  mask-image: url('./icons/icon-smartphone.svg');
}

.icon-link {
  mask-image: url('./icons/link.svg');
}

.icon-keyboard-back {
  mask-image: url('./icons/keyboard-back.svg');
}

.icon-money-mouth.face {
  mask-image: url('./icons/money-mouth-face.png');
}

.icon-arrow-drop-down {
  mask-image: url('./icons/arrow-drop-down.svg');
}

.icon-help {
  mask-image: url('./icons/help.svg');
}

.icon-help-small {
  mask-image: url('./icons/help-small.svg');
}

.icon-action {
  mask-image: url('./icons/action.svg');
}

.icon-conditional-action {
  mask-image: url('./icons/conditional-action.svg');
}

.icon-thumb-up {
  mask-image: url('./icons/thumb-up.svg');
}

.icon-thumb-down {
  mask-image: url('./icons/thumb-down.svg');
}

.icon-analytics {
  mask-image: url('./icons/analytics.svg');
}

.icon-versions {
  mask-image: url('./icons/versions.svg');
}

.icon-versions-full {
  mask-image: url('./icons/versions-full.svg');
}

.icon-restore {
  mask-image: url('./icons/restore.svg');
}

.icon-design-backup {
  mask-image: url('./icons/design-backup.svg');
}

.icon-refresh {
  mask-image: url('./icons/refresh.svg');
}

.icon-marketplace {
  mask-image: url('./icons/marketplace.svg');
}

.icon-code {
  mask-image: url('./icons/code.svg');
}

.icon-launch {
  mask-image: url('./icons/launch.svg');
}

.icon-download {
  mask-image: url('./icons/get_app.svg');
}

.icon-warning-emoji {
  background-image: url('./icons/warning-emoji.png');
  background-size: contain;
  background-color: unset;
}

.icon-menu-book {
  mask-image: url('./icons/menu_book.svg');
}

.icon-ondemand-video {
  mask-image: url('./icons/ondemand_video.svg');
}

.icon-lock-open {
  mask-image: url('./icons/lock-open.svg');
}

.icon-power {
  mask-image: url('./icons/power-settings.svg');
}

.icon-key {
  mask-image: url('./icons/key.svg');
}

.icon-person-add {
  mask-image: url('./icons/person-add.svg');
}

.icon-share {
  mask-image: url('./icons/share.svg');
}

.icon-change-input {
  mask-image: url('./icons/change-input.svg');
}

.icon-email {
  mask-image: url('./icons/email.svg');
}

.icon-new-window {
  mask-image: url('./icons/new-window.svg');
}

.icon-square-root {
  mask-image: url('./icons/square-root.svg');
}

.icon-round {
  mask-image: url('./icons/round.svg');
}

.icon-random {
  mask-image: url('./icons/random.svg');
}

.icon-integer {
  mask-image: url('./icons/integer.svg');
}

.icon-exponent {
  mask-image: url('./icons/exponent.svg');
}

.icon-absolute-value {
  mask-image: url('./icons/absolute-value.svg');
}

.icon-logarithm {
  mask-image: url('./icons/logarithm.svg');
}

.icon-sort-text-asc {
  mask-image: url('./icons/sorts/text-asc.svg');
}

.icon-sort-text-desc {
  mask-image: url('./icons/sorts/text-desc.svg');
}

.icon-sort-number-asc {
  mask-image: url('./icons/sorts/number-asc.svg');
}

.icon-sort-number-desc {
  mask-image: url('./icons/sorts/number-desc.svg');
}

.icon-sort-boolean-asc {
  mask-image: url('./icons/sorts/false-first.svg');
}

.icon-sort-boolean-desc {
  mask-image: url('./icons/sorts/true-first.svg');
}

.icon-sort-dateOnly-desc,
.icon-sort-date-desc {
  mask-image: url('./icons/sorts/date-desc.svg');
}

.icon-sort-dateOnly-asc,
.icon-sort-date-asc {
  mask-image: url('./icons/sorts/date-asc.svg');
}

.icon-sort-location-asc {
  mask-image: url('./icons/sorts/location-asc.svg');
}

.icon-sort-location-desc {
  mask-image: url('./icons/sorts/location-desc.svg');
}

.icon-sort {
  mask-image: url('./icons/sort.svg');
}

.icon-send-backward {
  mask-image: url('./icons/send-backward.svg');
}

.icon-send-to-back {
  mask-image: url('./icons/send-to-back.svg');
}

.icon-bring-forward {
  mask-image: url('./icons/bring-forward.svg');
}

.icon-bring-to-front {
  mask-image: url('./icons/bring-to-front.svg');
}

.icon-float-down {
  mask-image: url('./icons/transitions/float-down.svg');
}

.icon-float-left {
  mask-image: url('./icons/transitions/float-left.svg');
}

.icon-float-right {
  mask-image: url('./icons/transitions/float-right.svg');
}

.icon-float-up {
  mask-image: url('./icons/transitions/float-up.svg');
}

.icon-modal {
  mask-image: url('./icons/transitions/modal.svg');
}

.icon-none {
  mask-image: url('./icons/transitions/none.svg');
}

.icon-push {
  mask-image: url('./icons/transitions/push.svg');
}

.icon-slide-down {
  mask-image: url('./icons/transitions/slide-down.svg');
}

.icon-slide-left {
  mask-image: url('./icons/transitions/slide-left.svg');
}

.icon-slide-right {
  mask-image: url('./icons/transitions/slide-right.svg');
}

.icon-slide-up {
  mask-image: url('./icons/transitions/slide-up.svg');
}

.icon-after {
  mask-image: url('./icons/comparators/after.svg');
}

.icon-before {
  mask-image: url('./icons/comparators/before.svg');
}

.icon-between {
  mask-image: url('./icons/comparators/between.svg');
}

.icon-contains {
  mask-image: url('./icons/comparators/contains.svg');
}

.icon-equal-to {
  mask-image: url('./icons/comparators/equal-to.svg');
}

.icon-greater-or-equal-to {
  mask-image: url('./icons/comparators/greater-or-equal-to.svg');
}

.icon-greater-than {
  mask-image: url('./icons/comparators/greater-than.svg');
}

.icon-less-than-or-equal-to {
  mask-image: url('./icons/comparators/less-than-or-equal-to.svg');
}

.icon-less-than {
  mask-image: url('./icons/comparators/less-than.svg');
}

.icon-not-equal-to {
  mask-image: url('./icons/comparators/not-equal-to.svg');
}

.icon-not-contains {
  mask-image: url('./icons/comparators/not-contains.svg');
}

.icon-notification-permission {
  mask-image: url('./icons/notification-permission.svg');
}

.icon-location {
  mask-image: url('./icons/location.svg');
}

.icon-location-disabled {
  mask-image: url('./icons/location-disabled.svg');
}

.icon-tune {
  mask-image: url('./icons/tune.svg');
}

.icon-credit-card {
  mask-image: url('./icons/credit-card.svg');
}

.icon-free {
  mask-image: url('./icons/free.svg');
}

.icon-paid {
  mask-image: url('./icons/paid.svg');
}

.icon-shop {
  mask-image: url('./icons/shop.svg');
}

.icon-open-in-new {
  mask-image: url('./icons/open-in-new.svg');
}

.icon-installs {
  mask-image: url('./icons/installs.svg');
}

.icon-help-tear {
  mask-image: url('./icons/help-tear.svg');
}

.icon-help-text {
  mask-image: url('./icons/help-text.svg');
}

.icon-edit-calendar {
  mask-image: url('./icons/edit-calendar.svg');
}

.icon-support-star {
  mask-image: url('./icons/support-star.svg');
}

.icon-my-location {
  mask-image: url('./icons/my-location.svg');
}

.icon-location-permission {
  mask-image: url('./icons/location-permission.svg');
}

.icon-within {
  mask-image: url('./icons/comparators/within.svg');
}

.icon-not-within {
  mask-image: url('./icons/comparators/not-within.svg');
}

.icon-kilometers {
  mask-image: url('./icons/kilometers.svg');
}

.icon-miles {
  mask-image: url('./icons/miles.svg');
}

.icon-distance {
  mask-image: url('./icons/distance.svg');
}

.icon-calendar-blank {
  mask-image: url('./icons/calendar-blank.svg');
}

.icon-list-check {
  mask-image: url('./icons/list-check.svg');
}

.icon-magic-layout {
  mask-image: url('./icons/magic-layout-enabled.svg');
}

.icon-mobile {
  mask-image: url('./icons/New_mobile_icon.svg');
  mask-size: 10px;
}

.icon-mobile-small {
  mask-image: url('./icons/New_mobile_icon.svg');
}

.icon-mobile-medium {
  mask-image: url('./icons/New_mobile_icon.svg');
}

.icon-mobile-off {
  mask-image: url('./icons/mobile-off.svg');
}

.icon-tablet {
  mask-image: url('./icons/New_tablet_icon.svg');
  mask-size: 15px;
}

.icon-tablet-small {
  mask-image: url('./icons/New_tablet_icon.svg');
}

.icon-tablet-medium {
  mask-image: url('./icons/New_tablet_icon.svg');
}

.icon-tablet-off {
  mask-image: url('./icons/tablet-off.svg');
}

.icon-desktop {
  mask-image: url('./icons/computer_black_24dp.svg');
}

.icon-desktop-small {
  mask-image: url('./icons/computer_black_24dp.svg');
  mask-size: 15px;
}

.icon-desktop-medium {
  mask-image: url('./icons/computer_black_24dp.svg');
  mask-size: 17px;
}

.icon-desktop-large {
  mask-image: url('./icons/computer_black_24dp.svg');
  mask-size: 20px;
}

.icon-desktop-off {
  mask-image: url('./icons/Laptop-off.svg');
  mask-size: 21px;
}

.icon-add-circle {
  mask-image: url('./icons/add_circle.svg');
}

.icon-magic-layout-enabled {
  mask-image: url('./icons/magic-layout-enabled.svg');
  mask-size: 20px;
}

.icon-adalo-sparkles {
  mask-image: url('./icons/adalo-sparkles.svg');
}

.icon-experts {
  mask-image: url('./icons/experts.svg');
}

.icon-database-collections {
  mask-image: url('./icons/database-collections.svg');
}

.icon-add-collection {
  mask-image: url('./icons/add-collection.svg');
}

.icon-add-to-photos {
  mask-image: url('./icons/add-to-photos.svg');
}

.icon-monitor-arrow-down {
  mask-image: url('./icons/monitor-arrow-down.svg');
}

.icon-play-box-multiple-outline {
  mask-image: url('./icons/play-box-multiple-outline.svg');
}

.icon-ai-star {
  mask-image: url('./icons/ai-star.gif');
}

.icon-article {
  mask-image: url('./icons/categories/article.svg');
}

.icon-account-group {
  mask-image: url('./icons/categories/account-group.svg');
}

.icon-calendar-check {
  mask-image: url('./icons/categories/calendar-check.svg');
}

.icon-credit-card {
  mask-image: url('./icons/categories/credit-card.svg');
}

.icon-ticket {
  mask-image: url('./icons/categories/ticket.svg');
}

.icon-text-search {
  mask-image: url('./icons/categories/text-search.svg');
}

.icon-plus-box-multiple {
  mask-image: url('./icons/plus-box-multiple.svg');
}

.icon-close-double-arrows {
  mask-image: url('./icons/close-double-arrows.svg');
  mask-size: 20px;
}

.icon-mailbox {
  mask-image: url('./icons/mailbox.svg');
}

.icon-button-shortcut {
  mask-image: url('./icons/shortcuts/button.svg');
}

.icon-image-shortcut {
  mask-image: url('./icons/shortcuts/image.svg');
}

.icon-list-shortcut {
  mask-image: url('./icons/shortcuts/list.svg');
}

.icon-rectangle-shortcut {
  mask-image: url('./icons/shortcuts/rectangle.svg');
}

.icon-screen-shortcut {
  mask-image: url('./icons/shortcuts/screen.svg');
}

.icon-section-shortcut {
  mask-image: url('./icons/shortcuts/section.svg');
}

.icon-bottom-left-corner {
  mask-image: url('./icons/corner/bottom-left.svg');
}

.icon-bottom-right-corner {
  mask-image: url('./icons/corner/bottom-right.svg');
}

.icon-top-left-corner {
  mask-image: url('./icons/corner/top-left.svg');
}

.icon-top-right-corner {
  mask-image: url('./icons/corner/top-right.svg');
}

.icon-palette-outline {
  mask-image: url('./icons/palette-outline.svg');
}

.icon-gmail {
  background: url('./icons/integrations/gmail-logo.svg') no-repeat center;
}

.icon-google-drive {
  background: url('./icons/integrations/google-drive-logo.svg') no-repeat center;
}

.icon-google-sheets {
  background: url('./icons/integrations/google-sheets-logo.svg') no-repeat
    center;
}

.icon-chatgpt {
  background: url('./icons/integrations/chatgpt-logo.svg') no-repeat center;
}

.icon-mailchimp {
  background: url('./icons/integrations/mailchimp-logo.svg') no-repeat center;
}

.icon-sendgrid {
  background: url('./icons/integrations/sendgrid-logo.svg') no-repeat center;
}

.icon-slack {
  background: url('./icons/integrations/slack-logo.svg') no-repeat center;
}

.icon-twilio {
  background: url('./icons/integrations/twilio-logo.svg') no-repeat center;
}

.icon-docs {
  background: url('./icons/docs.svg') no-repeat center;
}

.icon-gray-sparkles {
  background: url('./icons/gray-sparkles.svg') no-repeat center;
}

.icon-hand-shaking {
  background: url('./icons/hand-shaking.svg') no-repeat center;
}

.icon-clap {
  background: url('./icons/clap.svg') no-repeat center;
}

.icon-party-guy {
  background: url('./icons/party-guy.svg') no-repeat center;
}

.icon-setup-sparkles {
  background: url('./icons/setup-sparkles.svg') no-repeat center;
}

.icon-components-empty {
  mask-image: url('./icons/components-empty.svg');
}

.icon-marketplace-empty {
  mask-image: url('./icons/marketplace-empty.svg');
}

.icon-sections-empty {
  mask-image: url('./icons/sections-empty.svg');
}

.icon-features-empty {
  mask-image: url('./icons/features-empty.svg');
}

.icon-features-gallery {
  mask-image: url('./icons/features-gallery.svg');
}
