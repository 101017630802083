.wrapped-textarea {
  margin: 25px 0;
  position: relative;
  padding-bottom: 1px;
  flex: 1;

  .wrapped-textarea-input {
    flex: 1;

    ::-webkit-scrollbar {
      width: 0;
    }

    label,
    textarea {
      display: block;
      width: 100%;
      text-align: left;
      color: #8a8a8a;
    }

    textarea {
      border: none;
      background: transparent;
      font: inherit;
      color: inherit;
      padding: 0;
      outline: none;
      transition: all 0.2s;
      height: auto;
      min-height: 50px;
      font-size: 14px;
      resize: none;
      overflow: auto;
      flex: 1;
    }
  }
}

.wrapped-textarea label {
  font-size: 12px;
  margin: 0 16px 5px;
}

.wrapped-textarea.wrapped-textarea-lg textarea {
  line-height: 54px;
  font-size: 16px;
}

.wrapped-textarea textarea::placeholder {
  color: #b1b8cb;
}

.wrapped-textarea-error-message {
  color: #f44;
  font-weight: 500;
  margin: 5px 16px 0;
}

.wrapped-textarea-inner-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 9px;
}

.wrapped-textarea-focussed .wrapped-textarea-inner-wrapper,
.wrapped-textarea-error .wrapped-textarea-inner-wrapper {
  border-color: #ccc;
}

.wrapped-textarea.wrapped-textarea-error .wrapped-textarea-inner-wrapper {
  border-color: #f44 !important;
}

.wrapped-textarea-textarea {
  flex: 1;
}

.wrapped-textarea-prefix,
.wrapped-textarea-suffix {
  color: #8a8a8a;
  line-height: 44px;
}

.wrapped-textarea-prefix {
  padding-right: 2px;
}

.wrapped-textarea-suffix {
  padding-left: 2px;
}
