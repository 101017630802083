.new-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  min-width: 300px;
}

@media (min-width: 764px) {
  .new-modal {
    width: 600px;
  }
}

.new-modal-footer {
  border-top: solid 1px #eee;
  padding: 15px 40px;
  display: flex;
}

.new-modal-footer > * {
  margin-left: 10px;
}

.new-modal-footer .btn.modal-footer-secondary {
  background-color: transparent;
  color: #9e9e9e;
}

.new-modal-footer .btn.modal-footer-secondary:hover,
.new-modal-footer .btn.modal-footer-secondary:focus,
.new-modal-footer .btn.modal-footer-secondary:active {
  background-color: rgb(238, 238, 238);
}

.new-modal-footer .btn.modal-footer-align-right {
  margin-left: auto;
}
