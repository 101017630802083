.permissions-dropdown-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;

  .permissions-dropdown-label__title {
    line-height: 14px;
    margin-bottom: 8px;
  }

  .permissions-dropdown-label__tooltip {
    height: 14px;
    width: 14px;
  }
}
