@import 'styles/_colors.scss';

.plan-services-section {
  display: grid;
  gap: 27px;
  padding-bottom: 25px;

  .legacy-plan-warning {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 12px;
    background-color: rgba(239, 76, 48, 0.1);
    border-radius: 6px;
    font-weight: 500;

    p {
      color: $labelDark;
    }

    a {
      color: $orange;
      text-transform: uppercase;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.plan-services-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-service-stats {
  display: grid;
  grid-template-areas: 'plan limit cost date';

  #plan {
    grid-area: plan;
  }

  #limit {
    grid-area: limit;
  }

  #cost {
    grid-area: cost;
  }

  #date {
    grid-area: date;
  }
}

.plan-services {
  list-style: none;
}

.plan-services > li.service {
  font-size: 12px;
  padding: 5px 0px 0px 2px;
  margin-bottom: 6px;
  color: #424242;
  position: relative;

  > .service-green-check {
    margin-right: 10px;
    height: 12px;
    width: 12px;
    top: 2px;
    position: relative;
  }
}

.switch-yearly-plan {
  display: flex;

  .money-mouth-image2 {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
