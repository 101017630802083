.form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-row + .form-row {
  margin-top: 15px;
}

.form-row > label {
  flex: 1;
}

.form-row > .form-row-input {
  flex: 3;
}


