@import 'styles/_colors.scss';

.empty-state {
  &.data-upgrade-prompt {
    border-style: solid;
    margin: 0 -16px 8px;
    text-align: left;
    align-items: flex-start;
    gap: 4px;

    p {
      margin-bottom: 7px;
    }

    span {
      color: initial;
    }

    .prompt-header {
      display: flex;
      align-items: center;
      gap: 8px;

      h2 {
        font-size: 14px;
        margin-bottom: 4px;
      }

      span {
        font-size: 18px;
      }
    }

    &.new-trial {
      &.before {
        p {
          color: $placeholder;
        }
      }

      &.during,
      &.after {
        p {
          color: $filterLabel;
        }
      }

      &.customFonts-during-free-trial-prompt {
        border-style: none;
        background: rgba(168, 32, 88, 0.1);
      }

      &.customFonts-after-free-trial-prompt {
        margin-top: 14px;
        border: none;
        background: rgba(168, 32, 88, 0.1);
      }

      &.externalCollection-during-free-trial-prompt {
        border-style: none;
        background: rgba(239, 76, 48, 0.1);
      }

      &.externalCollection-after-free-trial-prompt {
        margin-top: 14px;
        border: none;
        background: rgba(239, 76, 48, 0.1);
      }

      &.externalCollection,
      &.externalDatabase {
        &-upgrade-free-trial-prompt {
          margin-top: 14px;
        }
      }

      &.api-before-free-trial-prompt {
        margin-top: -3px;
      }

      &.api-during-free-trial-prompt {
        border: none;
        background: rgba(67, 67, 122, 0.1);
      }

      &.api-after-free-trial-prompt {
        margin-top: -3px;
        border: none;
        background: rgba(67, 67, 122, 0.1);
      }

      &.api-during-free-trial-prompt.free-trial-with-api-key {
        margin-top: 0px;
      }

      &.geolocation-during-free-trial-prompt {
        border: none;
        background: #fceeeb;
      }

      &.geolocation-after-free-trial-prompt {
        margin-top: 3px;
        border: none;
        background: #fceeeb;
      }

      &.customActions-before-free-trial-prompt,
      &.customActions-during-free-trial-prompt,
      &.customActions-after-free-trial-prompt {
        border: none;
        background: white;

        > h2 {
          font-size: 14px;
        }

        > p {
          font-size: 12px;
          color: #555;
        }

        > button {
          font-size: 12px;
        }
      }

      &.customActions-before-free-trial-prompt {
        border: 1px solid $gray;

        > p {
          color: $placeholder;
        }
      }

      &.designVersions-during-free-trial-prompt {
        margin-top: 14px;
        border: none;
        background: rgba(0, 168, 152, 0.1);
      }

      &.designVersions-after-free-trial-prompt {
        margin-top: 14px;
        border: none;
        background: rgba(0, 168, 152, 0.1);
      }
    }
  }
}

.prompt-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .btn.btn-small:not(:only-child) {
    padding: 0 20px;
  }
}
