@import 'styles/_colors.scss';

.new-screen-form {
  &__templates-list {
    display: flex;
    flex-direction: column;

    &__title {
      margin: 0 16px 5px;
      color: $label;
    }

    &__content {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      gap: 16px;
    }
  }
}
