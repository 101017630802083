@import 'styles/_colors.scss';

.xano-external-users {
  .external-users {
    &-title {
      font-weight: bold !important;
      font-size: 22px !important;
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 24px;

      h1 {
        font-weight: 500;
        margin-bottom: 6px;
        font-size: 24px;
      }

      p {
        margin: 0 auto;

        a.xano-setup-complete-link {
          display: inline;

          color: #00a898;
          font-size: 14px;
          font-weight: 600;
          text-decoration: none;
          padding: 2px 4px;
          border-radius: 4px;
        }
      }

      a.create-xano-account-link {
        display: flex;
        align-items: center;
        gap: 8px;

        color: #00a898;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        padding: 4px 8px;
        background: rgba(0, 168, 152, 0.1);
        border-radius: 4px;
      }

      .xano-tutorial-videos {
        iframe {
          margin: 10px;
        }
      }
    }

    &-subtitle {
      font-size: 14px;
      color: $labelDark;
      width: 85%;

      &-setup-complete {
        wdith: 100%;
      }
    }

    &-text-body {
      font-size: 14px;
      color: #bababa;
    }

    &-btn-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 22px;

      button,
      a {
        span {
          font-weight: bold;
        }
      }
    }
  }
}
