.saved-card-box {
  border: 1px solid #00a898;
  background: rgba(0, 168, 152, 0.1);
  padding: 15px 18px;
  display: block;
  outline: none;
  border-radius: 5px;
  align-items: center;
  margin: 25px 0;

  display: flex;
  justify-content: space-between;

  .card-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .card-icon {
      margin-right: 18px;
    }
  }

  p {
    font-size: 10px;
    font-weight: 500;
    color: #666;
  }
}

.no-saved-card-box {
  border: 1px solid #e9e9e9;
  background: rgba(233, 233, 233, 0.2);
  padding: 15px 18px;
  display: block;
  outline: none;
  border-radius: 5px;
  align-items: center;
  margin: 25px 0;

  display: flex;
  justify-content: space-between;

  .card-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .card-icon {
      margin-right: 18px;
    }
  }

  p {
    font-weight: 500;
    font-size: 13px;
    color: #8a8a8a;
  }
}

// TODO @danicunhac: Verify and remove the following styles if not needed. The comment below worries me.
// MIGHT NOT NEED

.trial-signup-modal {
  width: 560px;
  padding-left: 60px;
  padding-right: 60px;
}

.trial-warning {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trial-warning-button-wrapper:not(&--button) {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 44px;
  padding: 0 80px 0 20px;
  color: #777;

  .integrations-trial-wrapper {
    background: #f5f5f5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    font-weight: 400;

    span {
      margin-right: 8px;
    }

    > i {
      color: #ef4c30;
      margin-right: 5px;
    }

    > b {
      margin-left: 5px;
    }
  }

  .integrations-trial-tooltip-wrapper {
    height: 45px;
    display: flex;
    align-items: center;
  }
}

.btn.trial-warning-button {
  margin: 0 10px;
}

.trial-warning-banner {
  background: rgba(239, 76, 48, 0.1);
  color: #ef4c30;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px 0 32px;
}

.trial-signup-modal-header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(239, 76, 48, 0.2);
}

.trial-signup-modal-header .icon-close-big {
  background-color: rgba(0, 0, 0, 0.5);
}

.trial-warning-icon {
  background-color: #ef4c30;
  margin-right: 8px;
}

.trial-warning-banner-text {
  flex: 1;
}

.trial-signup-success {
  padding: 100px;
}

.modal h1.trial-signup-success-message {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
}

.trial-signup-success-message + p {
  color: #8a8a8a;
  margin-bottom: 30px !important;
  font-size: 16px;
}

.modal-plan-select {
  .team-settings-plan-select {
    margin: 0;
    padding: 0;
    flex-direction: row;
  }

  .team-settings-plan-item {
    & + .team-settings-plan-item {
      margin-top: 0;
      margin-left: 16px;
    }
  }
}

.modal-plan-select-billing {
  .team-settings-plan-select {
    margin: 0;
    padding: 0;
    flex-direction: column;
  }

  .team-settings-plan-item {
    & + .team-settings-plan-item {
      margin-top: 16px;
    }
  }
}

.trial-signup-modal-storage {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 36px;
  padding: 15px 20px;

  h2 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  p {
    color: #666;
  }
}

.monthly-annual-planselect {
  display: flex;
  font-size: 14px;
  margin: -5px 10px 10px;
  p {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.toggle-offer {
  display: flex;
  color: #00a898;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  span {
    background: #00a898;
    margin-top: -3px;
  }
}
