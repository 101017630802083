.new-app-platform {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin: 20px 0 30px;
}

.new-app-platform-item {
  flex: 1;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  text-align: center;
  padding: 0 0 16px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  position: relative;
  max-width: 200px;
}

.new-app-platform-item:hover {
  border-color: #d6d6d6;
}

.new-app-platform-item.new-app-platform-selected {
  border-color: #ffc00e;
}

.new-app-platform-item + .new-app-platform-item {
  margin-left: 16px;
}

.new-app-platform-checkmark {
  background: #e0e0e0;
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  opacity: 0;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.new-app-platform-item:hover .new-app-platform-checkmark {
  opacity: 1;
}

.new-app-platform-item.new-app-platform-selected .new-app-platform-checkmark {
  opacity: 1;
  background: #ffc00e;
}

.new-app-platform-title {
  font-weight: 500;
  color: #8a8a8a;
}

.new-app-platform-selected .new-app-platform-title {
  color: #000;
}

.new-app-platform-icon {
  height: 167px;
  width: 200px;
  background: no-repeat center;
  background-size: contain;
}

.new-app-platform-responsive .new-app-platform-icon {
  background-image: url('./responsive.svg');
  height: 160px;
  margin-top: 7px;
}

.new-app-platform-web .new-app-platform-icon {
  background-image: url('./web.png');
}

.new-app-platform-mobile .new-app-platform-icon {
  background-image: url('./mobile.png');
}
