@import 'styles/_colors.scss';

.api-keys {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .customActions-during-free-trial-prompt {
      margin-top: -30px;
    }
  }

  &__form {
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-flat {
      margin: 20px 0 0 -13px;
    }
  }
}

.openai-button-keys {
  &__control {
    margin-bottom: 30px;
  }
}

.openai-keys {
  &__org-message {
    margin-top: 10px;
  }
}
