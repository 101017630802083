.help-link {
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &--error {
    color: #f44;
  }

  &__link {
    color: #ef4c30;
    padding: 1px 3px;
    margin-right: -3px;
    margin-left: -3px;

    &:hover {
      background-color: rgba(239, 76, 48, 0.1);
      padding: 1px 3px;
      border-radius: 3px;
    }
  }
}

.tooltip-link {
  text-decoration: underline;
  cursor: pointer;
}
