.SelectedObjectSection {
  .ObjectDetails {
    padding-left: 8px;

    .summary {
      border: none;

      margin-left: -8px;
    }
  }

  .object-json {
    width: 100%;
    overflow: auto;
  }
}
