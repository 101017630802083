@import 'styles/_colors.scss';

@mixin link($color) {
  &:hover {
    background-color: $color;
  }
}

@mixin color($color, $background) {
  background-color: $background;
  color: $color;
  font-weight: 600;
  padding: 2px 8px;
}

.chip {
  display: grid;
  grid-auto-flow: column;
  gap: 4px;

  width: fit-content;
  min-width: 0;
  max-width: 100%;

  padding: 4px 8px;

  color: $black;
  background-color: $grey;
  transition: background 0.2s;

  border-radius: 6px;

  font-size: 12px;
  text-decoration: none;

  &-lg {
    font-size: 15px;
  }

  &-teal {
    @include color($darkTeal, $lightTeal);
  }

  &-orange {
    @include color($orange, $lightOrange);
  }

  &-yellow {
    @include color($body, $yellow);
  }

  &-green {
    @include color($green, $lightGreen);
  }

  &-purple {
    @include color($purple, $lightPurple);
  }

  &-grey {
    @include color($label, $darkGrey);
  }

  .chip-content {
    align-self: center;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  > .icon {
    mask-size: contain;
    height: 16px;
    width: 16px;
  }

  // styles for link chips
  @at-root #{selector-append(a, &)} {
    @include link($yellow);

    &-teal {
      @include link($teal);
    }
    &-orange {
      @include link($orange);
    }
    &-yellow {
      @include link($yellow);
    }
    &-purple {
      @include link($purple);
    }
    &-darkPink {
      @include link($darkPink);
    }
  }

  &-no-background {
    background: none;
  }

  &-disabled {
    color: $placeholder;
  }
}
