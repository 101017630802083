@import 'styles/_colors.scss';

.empty-state.greedy.editor-empty-canvas {
  position: relative;
  z-index: 1;
  margin-top: 64px;
  margin-left: 431px;
  width: auto;
  transition: margin 0.2s;
}

.offline-banner-visible .empty-state.greedy.editor-empty-canvas {
  margin-top: 96px;
}

.editor-empty-canvas {
  > * {
    &:last-child {
      margin-bottom: 50px;
    }
  }

  h1 {
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }

  .add-screen-link {
    color: $teal !important;
  }
}
