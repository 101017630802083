.app-settings-users-list {
  margin: 0;
}
.app-settings-user-item {
  margin: 10px -16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  border-radius: 9px;
  border-color: #eaeaea;
  border-style: solid;
  border-width: 1px;
  gap: 14px;
}
.app-settings-user-item:hover {
  background: #fafafa;
}
.app-settings-user-details {
  flex: 1;
  overflow: hidden;
}
.app-settings-user-name-select {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.app-settings-user-email-select {
  font-size: 14px;
  font-weight: normal;
  color: #8a8a8a;
  margin-top: 2px;
}
.app-settings-user-item-select {
  margin: 0 -10px;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
}
.app-settings-user-item-select-invite {
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-settings-user-details-select {
  flex: 1;
}
.app-settings-user-name {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-settings-user-email {
  font-size: 14px;
  font-weight: normal;
  color: #8a8a8a;
  margin-top: 2px;
}
.app-settings-user-owner .icon {
  background-color: #757575;
}

.app-settings-user-email, .app-settings-user-email > * {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-settings-add-user-input {
  width: 300px;
  height: 54px;
  flex: 1;
}
.app-settings-add-user-input .multi-select-menu-selection {
  margin: 10px;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding-left: 16px;
  font-weight: normal;
}
.app-settings-add-user-input .multi-select-menu {
  margin: 10px -16px;
  height: 54px;
}
.app-settings-add-user-input .multi-select-menu-selection {
  margin: 0;
  line-height: 54px;
  box-shadow: inset 0 0 0 1px #eaeaea;
  padding-right: 40px;
}

.app-settings-add-user-input .multi-select-menu-value {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.app-settings-add-user-input .multi-select-menu-expand-icon:before {
  visibility: hidden;
}
.app-settings-user-action {
  margin-right: 10px;
}
.app-settings-user-action-select {
}

.app-settings-api-key {
  position: relative;
  margin: 3px -14px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px 0px;
}

.app-settings-api-key .header {
  width: 100%;
  margin: 0px 0px 0px 0px;
  flex-direction: row;
  padding: 10px 0px 0px 0px;
}

.app-settings-api-key .header .title {
  text-align: left;
  padding: 0px;
  width: 50%;
}

.app-settings-api-key .header .help {
  display: block;
  text-align: right;
  padding: 0px;
}

.app-settings-api-buttons {
  display: flex;
  width: 100%;
  margin: 0px 0px 20px 0px;
}

.app-settings-trial {
  margin: 0px;
}

.app-settings-trial > div {
  margin: 0px;
}

.btn.api-settings-generate-key {
  border: 1px solid #00a898;
  background: white;
  color: #00a898;
}

.btn.api-settings-generate-key.during-trial {
  margin-bottom: 40px;
}

.btn.api-settings-generate-key:hover {
  background: #00a898;
  color: white;
}

.app-settings-api-buttons .btn:focus {
  background: transparent;
}

.app-settings-api-buttons .delete {
  text-align: left;
  justify-content: start;
  padding: 0px;
  color: #d1cec5;
  font-weight: 500;
}

.app-settings-api-buttons .delete a {
  color: #d1cec5;
  text-decoration: none;
}

.app-settings-api-buttons .delete:hover {
  filter: brightness(0.9);
  background-color: transparent;
}

.app-settings-api-buttons .regenerate {
  text-align: right;
  display: block;
  padding: 0px;
  color: #00a898;
  font-weight: 500;
}

.app-settings-api-buttons .regenerate a {
  color: #00a898;
  text-decoration: none;
}

.app-settings-api-buttons .regenerate:hover {
  filter: brightness(0.9);
  background-color: transparent;
}

.app-settings-api-token {
  display: flex;
  margin: 3px -14px;
  align-items: center;
  padding: 12px 16px;
  border-radius: 9px;
  border-color: #eaeaea;
  border-style: solid;
  border-width: 1px;
}

.app-settings-api-token-number {
  width: 92%;
  font-size: 14px;
  border: none;
  outline: none;
}

.app-settings-api-token-capital-text {
  text-transform: uppercase;
}

.app-settings-clone-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-right: 30px;
}

.app-settings-clone-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #43437a;
  height: 40px;
  font: inherit;
  font-weight: 500;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  appearance: none;
  position: relative;
  outline: none;
  padding: 0 25px;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.2s;
  justify-content: center;
  border: 1px solid transparent;
  letter-spacing: 0.05em;
}

.app-settings-clone-button:hover {
  background: #36366d;
}

.clone-url {
  user-select: all;
  padding-top: 5px;
  padding-bottom: 16px;
  cursor: pointer;
}

.clone-url a {
  color: black;
}
.app-settings-clone-menu-control {
  padding-top: 10px;
}

.multi-menu-item-label-text {
  width: 100%;
}
