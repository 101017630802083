@import 'styles/_colors.scss';

.developer-libraries {
  border: 1px solid $border;
  border-radius: 16px;

  &-item {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;

    padding: 16px;
    border-bottom: 1px solid $border;

    &:last-child {
      margin-left: auto;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &-content {
      display: flex;
      flex-direction: column;

      gap: 8px;

      a {
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        &:hover,
        &:focus-visible {
          color: $teal;

          &:after {
            content: '';
            display: inline-block;
            height: 14px;
            width: 14px;
            mask-type: alpha;
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
            mask-image: url('../Shared/Icon/icons/launch.svg');
            background-color: $teal;
          }
        }
      }

      h2,
      h3 {
        margin: 0;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        > .chip {
          margin: -4px 0;
        }
      }

      &-status {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
      }
    }

    &-status {
      margin-left: auto;
      font-weight: 500;
    }

    img {
      height: 48px;
      width: 48px;
      border-radius: 50%;
    }
  }
}

.developer-libraries-wrapper {
  > div {
    margin-bottom: 16px;
  }
  > h2 {
    margin-bottom: 8px;
  }

  .select-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .select-label {
      line-height: 14px;
      margin-bottom: 4px;
    }

    .select-tooltip {
      height: 14px;
      width: 14px;
      margin-right: 8px;
    }
  }
}

.developers-modal {
  padding-top: 0;
  width: 580px;
}

.developers-page-close-button {
  position: absolute;
  top: 16px;
  right: 22px;
}

.developers-page-tabs {
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 0 40px;
}

.modal .developers-page-tabs {
  margin: 0 -56px 40px;
  padding: 0 40px;
}

.developers-page-tab {
  color: #8a8a8a;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  padding: 16px;
  display: block;
  position: relative;
  margin-bottom: -2px;
}

* + .developers-page-tab {
  margin-left: 20px;
}

.developers-page-tab.active {
  color: #000;
}

.developers-page-tab.active:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  border-radius: 2px;
  background-color: #000;
}

// Access Tokens
.developer-libraries-access-token {
  position: relative;
  margin: 3px -14px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px 0px;

  .header {
    width: 100%;
    margin: 0px 0px 0px 0px;
    flex-direction: row;
    padding: 10px 0px 0px 0px;

    .title {
      text-align: left;
      padding: 0px;
      width: 50%;
    }

    .help {
      display: block;
      text-align: right;
      padding: 0px;
    }
  }

  &-buttons {
    display: flex;
    width: 100%;
    margin: 0px 0px 20px 0px;

    .delete {
      text-align: left;
      justify-content: start;
      padding: 0px;
      color: #d1cec5;
      font-weight: 500;

      a {
        color: #d1cec5;
        text-decoration: none;
      }

      &:hover {
        filter: brightness(0.9);
        background-color: transparent;
      }
    }

    .regenerate {
      text-align: right;
      display: block;
      padding: 0px;
      color: #00a898;
      font-weight: 500;

      a {
        color: #00a898;
        text-decoration: none;
      }

      &:hover {
        filter: brightness(0.9);
        background-color: transparent;
      }
    }
  }

  &-text {
    display: flex;
    margin: 3px -14px;
    align-items: center;
    padding: 12px 16px;
    border-radius: 9px;
    border-color: #eaeaea;
    border-style: solid;
    border-width: 1px;

    &-number {
      width: 92%;
      font-size: 14px;
      border: none;
      outline: none;
    }

    &-capital-text {
      text-transform: uppercase;
    }
  }
}

.btn.developer-libraries-access-token-generate-key {
  border: 1px solid #00a898;
  background: white;
  color: #00a898;
}

.btn.developer-libraries-access-token-generate-key:hover {
  background: #00a898;
  color: white;
}

.developer-libraries-access-token-buttons .btn:focus {
  background: transparent;
}
