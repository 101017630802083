@import 'styles/_colors.scss';

.marketplace-library-details {
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: -35px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 0;

    > .loading {
      margin: 32px auto 0;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 160px);

    padding: 40px 80px 16px;
    margin: 0 -80px;

    background-color: white;
    z-index: 1;

    position: sticky;
    top: 0;

    &-info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h2 {
        display: inline-flex;
        align-items: center;
        gap: 8px;

        font-size: 24px;
        font-weight: 600;
        margin: 0;

        > img {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          border: 1px solid $lightGrey;
        }
      }

      span {
        font-weight: 500;
        font-size: 12px;
        color: $filterLabel;
      }
    }

    &-price {
      display: flex;
      align-items: center;
      gap: 20px;

      span {
        font-weight: 600;
        font-size: 18px;
        color: $darkTeal;

        &.installed {
          color: $placeholder;
        }
      }
    }
  }

  &-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $lightTeal;
    background-image: url('./tealsquare-01.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 48px 0;
    border-radius: 6px;
    margin-bottom: 24px;

    > img {
      width: 210px;
      height: 178px;
      border-radius: 6px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      background-color: white;
    }

    > .btn.btn-outlined {
      position: absolute;
      bottom: 16px;
      right: 16px;
      background-color: $white;

      display: flex;
      flex-direction: row-reverse;
      gap: 6px;

      .icon {
        margin: 0;
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: auto 240px;
    gap: 32px;
    font-size: 14px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &-details {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    &-meta {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-item,
      &-item > a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        font-size: 14px;
        font-weight: 500;
        color: $filterLabel;

        .icon {
          height: 16px;
          width: 16px;
          background: $filterLabel;
        }
      }

      &-item > a {
        color: $teal;
        text-decoration: none;
        font-weight: 600;

        .icon {
          background: $teal;
        }
      }
    }

    &-emoji {
      margin-right: 12px;
    }
  }
}
