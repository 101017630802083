.inspect-query-param-filters {
  margin: 0 -16px;

  .data-accordion-item:last-of-type {
    margin-bottom: 0;
  }

  .inspect-query-param-filters-title {
    margin: 0 16px 5px;
  }
}
