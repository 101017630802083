.inspect-generic-row {
  margin-top: 16px;
}

.editor-add-panel-tabs + .library-inspect > .inspect-generic-row:first-child {
  margin-top: 32px;
}

.library-inspect > .inspect-generic-row:first-child > .inspect-generic-row-body:first-child {
  margin-top: 0;
}

.inspect-generic-row-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.toggle-button-block {
  margin-top: 8px;
}

.toggle-button-block-box {
  display:flex;
  justify-content:space-between;
  padding: 16px;
  margin: 0 -16px;
  border-radius: 4px;
  background-color: #efefef;
}

.border-control-split > .inspect-generic-row > .inspect-generic-row-title {
  margin-left: 16px;
}

.border-control-split
  > .inspect-generic-row:last-child
  > .inspect-generic-row-title {
  margin-right: 16px;
}

.inspect-generic-row-body {
  margin-top: 6px;
}

.inspect-generic-row-body.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.inspect-generic-row-title .toggle-button {
  margin: -4px 0;
}

/* Inputs, etc. */
.inspect-generic-row .text-control {
  height: 44px;
}

.inspect-generic-row .text-control input {
  height: 44px;
  font-size: 14px;
  padding-left: 16px;
  border-radius: 6px;
}

.inspect-generic-row .text-control .incrementer {
  width: 18px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
